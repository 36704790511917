import React from 'react'

import * as S from './styled'

type Props = {
  size?: 'default' | 'full' | 'small'
  sx?: $FixMe
  children: React.ReactNode
}

const FullWidthLayoutWrapper = ({
  children,
  size = 'default',
  ...props
}: Props): JSX.Element => {
  return (
    <S.StyledWrapper size={size} {...props}>
      {children}
    </S.StyledWrapper>
  )
}

export default FullWidthLayoutWrapper
