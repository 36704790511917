import { styled } from '@mui/material/styles'
import { themeSpacing } from 'eezy-components'

export const AnimationContainer = styled('div')`
  margin-bottom: ${themeSpacing(4)};

  svg {
    contain: none;
    height: auto;
    width: ${themeSpacing(75)};
  }
`
