import { styled } from '@mui/material/styles'
import { themeColor, themeSpacing, VecteezyLogo } from 'eezy-components'

type StyleProps = {
  centered?: boolean
}

export const LogoWrapper = styled('div')<StyleProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ centered }: StyleProps) =>
    centered ? 'center' : 'flex-start'};
`

export const Logo = styled(VecteezyLogo)`
  color: ${themeColor('primary', 'main')};
  fill: ${themeColor('primary', 'main')};
  flex-shrink: 0;
  height: ${themeSpacing(7)};
  width: ${themeSpacing(24)};
`
