export default class MessageCollection {
  private collection: Map<string, string>

  constructor() {
    this.collection = new Map()
  }

  add(key: string, value: string): void {
    this.collection.set(key, value)
  }

  get(key: string): string | undefined {
    return this.collection.get(key)
  }

  getAll(): Map<string, string> {
    return this.collection
  }
}
