import { styled } from '@mui/system'
import { css } from 'styled-components'
import {
  themeBorderRadius,
  themeSpacing,
  themeColor,
  themeTypography,
} from '../../../../helpers'
import _CloseIcon from 'ez-styles/assets/icons/x/close-heavy.svg'
import { Theme, Typography } from '@mui/material'

export const StyledTag = styled('div')<{
  $hasError?: boolean
  $isHovered?: boolean
  $index: number
  theme: Theme
}>`
  align-items: center;
  align-self: flex-start;
  background-color: ${props =>
    props.$hasError
      ? themeColor('error', 'main')
      : themeColor('secondary', 'veryLight')};
  border-radius: ${themeBorderRadius('tiny')};
  cursor: pointer;
  display: flex;
  padding: ${themeSpacing(0.5)} ${themeSpacing(1)};

  & .MuiTypography-root {
    color: ${props =>
      props.$hasError ? '#fff' : themeColor('secondary', 'main')};
  }

  & > svg {
    fill: ${props => (props.$hasError ? '#fff' : null)};
  }

  /* stylelint-disable-next-line */
  ${props =>
    props.$isHovered
      ? css`
          &:hover {
            background-color: ${themeColor('error', 'main')};

            & .MuiTypography-root {
              color: #fff;
            }

            & > svg {
              fill: #fff;
            }
          }
        `
      : css`
          &:hover {
            background-color: ${themeColor('secondary', 'lighter')};
          }
        `}

  /* stylelint-disable-next-line */
  ${({ $index, $hasError, $isHovered }) => {
    switch ($index) {
      case 0:
        return css`
          padding: ${themeSpacing(1)} ${themeSpacing(1)};
          background-color: ${$hasError
            ? themeColor('error', 'main')
            : themeColor('grey', '600')};
          & .MuiTypography-root {
            color: #fff;
          }

          & > svg {
            fill: #fff;
          }

          &:hover {
            background-color: ${$hasError || $isHovered
              ? themeColor('error', 'main')
              : themeColor('grey', '700')};
          }
        `

      case 1:
        return css`
          padding: ${themeSpacing(1)} ${themeSpacing(1)};
          background-color: ${$hasError
            ? themeColor('error', 'main')
            : themeColor('grey', '500')};

          & .MuiTypography-root {
            color: #fff;
          }

          & > svg {
            fill: #fff;
          }

          &:hover {
            background-color: ${$hasError || $isHovered
              ? themeColor('error', 'main')
              : themeColor('grey', '600')};
          }
        `

      case 2:
        return css`
          padding: ${themeSpacing(1)} ${themeSpacing(1)};
          background-color: ${$hasError
            ? themeColor('error', 'main')
            : themeColor('grey', '400')};

          & .MuiTypography-root {
            color: #fff;
          }

          & > svg {
            fill: #fff;
          }

          &:hover {
            background-color: ${$hasError || $isHovered
              ? themeColor('error', 'main')
              : themeColor('grey', '500')};
          }
        `

      case 3:
        return css`
          padding: ${themeSpacing(1)} ${themeSpacing(1)};
          background-color: ${$hasError
            ? themeColor('error', 'main')
            : themeColor('grey', '300')};

          &:hover {
            background-color: ${$hasError || $isHovered
              ? themeColor('error', 'main')
              : themeColor('grey', '400')};
          }
        `

      case 4:
        return css`
          padding: ${themeSpacing(1)} ${themeSpacing(1)};
          background-color: ${$hasError
            ? themeColor('error', 'main')
            : themeColor('grey', '200')};

          &:hover {
            background-color: ${$hasError || $isHovered
              ? themeColor('error', 'main')
              : themeColor('grey', '300')};
          }
        `
    }
  }}
`

export const TagIndex = styled('span')`
  width: ${themeSpacing(2)};
  height: ${themeSpacing(2)};
  line-height: ${themeSpacing(2)};
  color: ${themeColor('secondary', 'light')};
  font-size: ${themeTypography('smallText', 'fontSize')};
  font-weight: ${themeTypography('smallText', 'fontWeight')};
  text-align: center;
  margin-right: ${themeSpacing(1)};
  border-radius: 50%;
  background-color: #fff;
`

export const TagText = styled(Typography)`
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const CloseIcon = styled(_CloseIcon)`
  fill: ${themeColor('secondary', 'light')};
  flex-shrink: 0;
  height: ${themeSpacing(1)};
  margin-left: ${themeSpacing(1)};
  width: ${themeSpacing(1)};
`
