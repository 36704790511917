import React from 'react'
import { CircularProgress } from '@mui/material'
import * as S from './styled'

type Props = {
  className?: string
  color?: 'inherit' | 'primary' | 'secondary'
  padding?: number
  size?: number
}

const LoadingSpinner = ({
  className,
  color,
  padding = 0,
  size,
}: Props): JSX.Element => (
  <S.StyledLoadingSpinner
    className={className}
    $padding={padding}
    data-testid="loading-spinner"
  >
    <CircularProgress color={color} size={size} />
  </S.StyledLoadingSpinner>
)

export default LoadingSpinner
