import { styled } from '@mui/system'
import {
  themeBorderRadius,
  themeColor,
  themeSpacing,
} from '../../helpers/themeHelpers'
import CheckboxInput from '../input/CheckboxInput'
import { Theme } from '@mui/material'

export const List = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    margin-bottom: ${themeSpacing(1)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const StyledCheckboxInput = styled(CheckboxInput)<{
  theme: Theme
  $greyOnChecked: boolean
}>`
  margin-left: -${themeSpacing(0.5)};
  margin-right: ${themeSpacing(2)};
  /* stylelint-disable value-keyword-case */
  ${props =>
    props.$greyOnChecked
      ? `
    &.Mui-checked {
        & ~ * {
          color: ${themeColor('secondary', 'lighter')(props)};
        }
  
        & ~ svg {
          fill: ${themeColor('secondary', 'lighter')(props)};
        }
      }
    `
      : ''}
`

export const ListItem = styled('li')`
  align-items: center;
  background-color: ${themeColor('secondary', 'pale')};
  border-radius: ${themeBorderRadius('small')};
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  display: flex;
  padding: ${themeSpacing(1)} ${themeSpacing(2)};

  &:hover {
    background-color: ${themeColor('secondary', 'veryLight')};

    ${StyledCheckboxInput} {
      & .checkbox-unchecked {
        background-color: ${themeColor('secondary', 'lighter')};
      }
    }
  }
`
