export enum IdVerificationStatus {
  NotStarted = 'not started',
  Started = 'started',
  Expired = 'expired',
  Failed = 'failed',
  Declined = 'declined',
  Finished = 'finished',
  NeedsReview = 'needs review',
  Overridden = 'verified',
  Approved = 'approved',
}
