import React from 'react'
import * as S from './styled'

type Props = {
  buttonStyle?: boolean
  value: string
  checked: boolean
  onChange?: () => void
  label: string
  className?: string
  disabled?: boolean
}

const RadioInput = ({
  buttonStyle,
  value,
  checked,
  className,
  onChange,
  label,
  disabled,
}: Props): JSX.Element => (
  <S.StyledLabel
    data-testid="radio-input"
    $buttonStyle={buttonStyle}
    $checked={checked}
    className={className}
    value={value}
    control={
      <S.StyledRadio
        color="primary"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    }
    label={label}
  />
)

export default RadioInput
