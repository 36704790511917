import React, {
  ReactNode,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
} from 'react'
import InputWrapper from '../InputWrapper'
import _EditIcon from 'ez-styles/assets/icons/edit/edit-light.svg'
import * as S from './styled'

export type InputProps = {
  className?: string
  fullWidth?: boolean
  label?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  labelCaption?: string
  tooltipContent?: ReactNode
  helperText?: string
  filePath?: string
  loading?: boolean
  placeholder?: string
  openCustomUploader?: () => void
  icon?: JSX.Element
}

const FileInput = ({
  className,
  fullWidth,
  label,
  labelCaption,
  onChange,
  required,
  helperText,
  tooltipContent,
  filePath,
  loading,
  openCustomUploader,
  placeholder,
  icon,
  ...inputProps
}: InputProps): JSX.Element => {
  const [value, setValue] = useState(null)
  const [filename, setFilename] = useState(null)

  const inputWrapperProps = {
    fullWidth,
    label,
    labelCaption,
    helperText,
    tooltipContent,
    required,
  }

  const fileInput = useRef<HTMLInputElement>(null)

  const triggerFileUploader = () => {
    if (openCustomUploader) {
      openCustomUploader()
    } else {
      fileInput.current.click()
    }
  }

  const updateFileInput = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.files[0].name)

    if (onChange) onChange(event)
  }

  useEffect(() => {
    const path = filePath || value || ''

    setFilename(path.replace(/^.*(\\|\/|:)/, ''))
  }, [value, filePath])

  const renderIcon = () => {
    if (icon) {
      return <S.CustomIcon>{icon}</S.CustomIcon>
    }

    return <S.EditIcon />
  }

  return (
    <InputWrapper className={className} {...inputWrapperProps}>
      <S.UploaderButton
        onClick={triggerFileUploader}
        data-testid="file-upload-button"
      >
        {filename || placeholder ? (
          <S.UploaderValue data-testid="file-input-filename">
            {filename || placeholder || ''}
          </S.UploaderValue>
        ) : null}

        {loading && <S.StyledLoadingSpinner color="secondary" size={16} />}

        {!loading && (filename || placeholder) ? renderIcon() : null}
      </S.UploaderButton>

      <input
        id={`${label}-input-label`}
        type="file"
        hidden
        onChange={updateFileInput}
        required={required}
        ref={fileInput}
        data-testid="text-input"
        {...inputProps}
      />
    </InputWrapper>
  )
}

FileInput.displayName = 'FileInput'

export default FileInput
