export const competitors: string[] = [
  'shutterstock',
  'istockphoto',
  'canva',
  '123rf',
  'freepik',
  'adobe',
  'depositphotos',
  'vectorstock',
  'pngtree',
  'dreamstime',
  'envato',
  'alamy',
]
