import React from 'react'
import Typography from '@mui/material/Typography'
import { Palette, Color } from '../../helpers/themeHelpers'
import * as S from './styled'

type Item = string | JSX.Element
type Props = {
  className?: string
  listItems: (Item | [Item, Item[]])[]
  depth?: number
  markerColor?: [keyof Palette, keyof Color]
}

const BulletedList = ({
  className,
  listItems,
  depth = 0,
  markerColor = ['primary', 'main'],
}: Props): JSX.Element => (
  <S.List className={className} $depth={depth}>
    {listItems.map((listItem, index) => {
      if (Array.isArray(listItem)) {
        const [item, children] = listItem
        return (
          <React.Fragment key={index}>
            <S.ListItem key={index} $markerColor={markerColor}>
              <Typography variant="body1">{item}</Typography>
            </S.ListItem>
            <BulletedList
              listItems={children}
              depth={depth + 1}
              markerColor={markerColor}
            />
          </React.Fragment>
        )
      }

      return (
        <S.ListItem key={index} $markerColor={markerColor}>
          <Typography variant="body1">{listItem}</Typography>
        </S.ListItem>
      )
    })}
  </S.List>
)

export default BulletedList
