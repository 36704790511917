import { RefObject, useLayoutEffect, useState } from 'react'
import { widthOfLongestTextInArray } from '../helpers'

export default function useMinWidth(
  ref: RefObject<HTMLButtonElement>,
  labels: string[]
): number {
  const [minWidth, setMinWidth] = useState<number>(0)

  useLayoutEffect(() => {
    const { paddingLeft, paddingRight } = window.getComputedStyle(ref.current)
    const width = widthOfLongestTextInArray(labels, ref.current)
    setMinWidth(
      width + (parseInt(paddingLeft, 10) + parseInt(paddingRight, 10))
    )
  }, [])

  return minWidth
}
