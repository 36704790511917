import React from 'react'
import { styled } from '@mui/system'
import { themeSpacing } from '../../../helpers'
import { Theme } from '@mui/material'

type Props = {
  children: React.ReactNode
  align?: string
  justify?: string
  space?: number
}

const Cluster = ({
  align = 'center',
  justify = 'flex-start',
  space = 1.5,
  children,
}: Props): JSX.Element => {
  return (
    <Wrapper
      align={align}
      justify={justify}
      space={space}
      data-testid="eezy-layout-cluster"
    >
      {children}
    </Wrapper>
  )
}

export default Cluster

const Wrapper = styled('div')<{
  theme: Theme
  align: string
  justify: string
  space: number
}>`
  > * {
    align-items: ${({ align }) => align};
    display: flex;
    justify-content: ${({ justify }) => justify};
    margin: calc(${({ space }) => themeSpacing(space)} / 2 * -1);
    flex-wrap: wrap;
  }

  > * > * {
    margin: calc(${({ space }) => themeSpacing(space)} / 2);
  }
`
