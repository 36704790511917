import { UsersResourceCounts } from 'api'
import { IdVerificationStatus } from 'constants/id-verification'
import cookie from 'js-cookie'
import { Context } from '.'
import Popup from './Popup'

export default class PersonaToast extends Popup {
  constructor(context?: Context) {
    super(context)
    this.addCondition(this.checkVisibility)
    this.addCondition(this.checkCookie)
    this.addCondition(this.checkStatus)
  }

  checkCookie = (): boolean => {
    const { status } = this.context
    let cookieName = 'persona-toast-failed'

    if (
      !status ||
      [
        IdVerificationStatus.NotStarted,
        IdVerificationStatus.Expired,
        IdVerificationStatus.Started,
      ].includes(status)
    ) {
      cookieName = 'persona-toast'
    }

    return !cookie.get(cookieName)
  }

  checkStatus = (): boolean => {
    const { status } = this.context
    return (
      !status ||
      [
        IdVerificationStatus.Failed,
        IdVerificationStatus.Declined,
        IdVerificationStatus.NotStarted,
        IdVerificationStatus.Expired,
        IdVerificationStatus.Started,
      ].includes(status)
    )
  }

  checkVisibility = (): boolean => {
    const { session, resourceCounts } = this.context
    let hasSubmitted = false as boolean

    if (!resourceCounts) {
      return false
    }

    const keys = Object.keys(resourceCounts) as (keyof UsersResourceCounts)[]

    hasSubmitted = keys.reduce((acc, key): boolean => {
      const count = resourceCounts[key]

      if (acc || (key !== 'started' && count && count > 0)) {
        return true
      }

      return false
    }, hasSubmitted)

    return Boolean(
      session?.enabledFeatures?.includes('id_verification') &&
        !session?.isAdmin &&
        hasSubmitted
    )
  }
}
