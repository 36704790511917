/**
 * Checks whether object is empty
 */
export const isEmpty = (obj: unknown): boolean => {
  if (!isObject(obj)) {
    throw new Error('Value is not an object')
  }

  for (const prop in obj) {
    if (prop in obj) {
      return false
    }
  }

  return true
}

/**
 * Checks whether value is an object
 */
export const isObject = (obj: unknown): obj is { [key: string]: unknown } =>
  obj !== null && typeof obj === 'object' && !Array.isArray(obj)

/**
 * Checks whether object has any valid values
 */
export const isDry = (obj: Record<string, unknown>): boolean => {
  if (!isObject(obj)) {
    throw new Error('Value is not an object')
  }

  for (const prop in obj) {
    const value = obj[prop]

    if (value !== null && value !== undefined) {
      return false
    }
  }

  return true
}

/**
 * Removes empty fields from object
 */
export const removeEmpty = <T = Record<string, unknown>>(obj: T): T =>
  Object.keys(obj as Record<string, unknown>).reduce(
    (acc, key) => {
      // @ts-ignore
      const value = acc[key]

      if (
        !value ||
        (isObject(value) && isEmpty(value as Record<string, unknown>)) ||
        (Array.isArray(value) && !value.length)
      ) {
        // @ts-ignore
        delete acc[key]
      }

      return acc
    },
    { ...obj } as T
  )

/**
 * Retrieve deeply nested object value based on path
 */
export const dotInto = (obj: unknown, path: string): unknown | undefined => {
  if (!isObject(obj)) {
    return undefined
  }

  // @ts-ignore
  return path.split('.').reduce((acc, key) => (acc ? acc[key] : undefined), obj)
}
