import * as S from './styled'

type Props = {
  centered?: boolean
}

const LogoHeader = ({ centered = false }: Props) => {
  return (
    <S.LogoWrapper data-testid={'logo-header'} centered={centered}>
      <S.Logo />
    </S.LogoWrapper>
  )
}

export default LogoHeader
