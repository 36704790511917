import React, { forwardRef, ReactNode, ChangeEvent } from 'react'
import _CheckmarkIcon from 'ez-styles/assets/icons/checkmarks/checkmark-small.svg'
import * as S from './styled'

type Props = {
  value: string | number
  checked: boolean
  onChange?: (ev: ChangeEvent<HTMLInputElement>) => void
  label?: ReactNode
  className?: string
  disabled?: boolean
  shouldPreventClick?: boolean
}
const CheckboxInput = forwardRef<HTMLButtonElement, Props>(
  (
    {
      value,
      className,
      checked,
      onChange,
      label,
      disabled = false,
      shouldPreventClick = false,
    }: Props,
    ref
  ): JSX.Element => (
    <>
      {label ? (
        <S.StyledFormControlLabel
          className={className}
          data-testid="checkbox-with-label"
          value={value}
          ref={ref}
          control={
            <S.StyledCheckbox
              checked={checked}
              onChange={onChange}
              checkedIcon={
                <S.CheckedIcon className="checkbox-checked">
                  <S.CheckmarkIcon />
                </S.CheckedIcon>
              }
              icon={<S.Icon className="checkbox-unchecked" />}
              $shouldPreventClick={shouldPreventClick}
              disabled={disabled}
            />
          }
          label={label}
          disabled={disabled}
        />
      ) : (
        <S.StyledCheckbox
          ref={ref}
          className={className}
          checked={checked}
          data-testid="checkbox-no-label"
          onChange={onChange}
          value={value}
          checkedIcon={
            <S.CheckedIcon className="checkbox-checked">
              <S.CheckmarkIcon />
            </S.CheckedIcon>
          }
          icon={<S.Icon className="checkbox-unchecked" />}
          disabled={disabled}
          $shouldPreventClick={shouldPreventClick}
        />
      )}
    </>
  )
)

CheckboxInput.displayName = 'CheckboxInput'

export default CheckboxInput
