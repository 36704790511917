import {
  addMinutes,
  differenceInYears,
  endOfDay,
  format,
  isAfter,
  isBefore,
  isValid,
  isWithinInterval,
  parse,
  parseISO,
  set,
  startOfDay,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export type DateRange = {
  from?: Date | string
  to?: Date | string
}

export const Formats = {
  Default: 'dd MMMM, yyyy',
  Compact: 'MMM dd, yyyy',
  Numeric: 'yyyy-MM-dd',
  NumericWithTime: 'yyyy-MM-dd HH:mm',
  Month: 'MMMM yyyy',
}

export const isValidDate = (date: unknown): date is Date => {
  return date instanceof Date && !isNaN(date.getTime())
}

export const isValidDOB = (date: Date): boolean => {
  const validAge: number = calculateAge(format(date, Formats.Numeric))

  return (
    isAfter(date, new Date('1900-01-01')) &&
    isBefore(date, new Date()) &&
    validAge > 17
  )
}

export const calculateAge = (dob: string) => {
  const date = parse(dob, 'yyyy-MM-dd', new Date())
  const age = differenceInYears(new Date(), date)
  return age
}

export const newUTC = (datestring?: string): Date => {
  const date = new Date(datestring || Date.now())
  return addMinutes(date, date.getTimezoneOffset())
}

export const formatDate = (
  date: unknown,
  fmt: string = Formats.Default
): string => {
  let dateToFormat

  if (typeof date === 'object' && date instanceof Date) {
    dateToFormat = date
  } else if (typeof date === 'string') {
    const utcDate = parseISO(date)
    if (!isValid(utcDate)) {
      return ''
    }
    dateToFormat = utcToZonedTime(utcDate, 'UTC')
  } else {
    return ''
  }

  return format(dateToFormat, fmt)
}

export const daysInYear = (date: Date): number => {
  return (date.getFullYear() % 4 === 0 && date.getFullYear() % 100 > 0) ||
    date.getFullYear() % 400 === 0
    ? 366
    : 365
}

export const daysInMonth = (date: Date): number => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
}

export const convertUTCDateToLocalDate = (date: Date) => {
  if (date && typeof date.getTime === 'function') {
    const newDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    )
    return newDate
  } else {
    return date
  }
}

export const isTodayInRange = (startDay: number, endDay: number) => {
  const today = new Date()

  const startDate = set(startOfDay(today), { date: startDay })
  const endDate = set(endOfDay(today), { date: endDay })

  return isWithinInterval(today, { start: startDate, end: endDate })
}
