import cookie from 'js-cookie'
import { Challenge } from 'types'
import { Context } from '.'
import Popup from './Popup'

export default class ChallengeModal extends Popup {
  constructor(context?: Context) {
    super(context)
    this.addCondition(this.checkVisibility)
    this.addCondition(this.checkCookies)
  }

  isAvailable = (challenge: Challenge): boolean => {
    return !cookie.get(`challenge-modal-${challenge.id}`)
  }

  checkCookies = (): boolean => {
    const { challenges } = this.context
    return Boolean(challenges?.some(c => this.isAvailable(c)))
  }

  checkVisibility = (): boolean => {
    const { session } = this.context

    return Boolean(!session?.isAdmin)
  }

  getNextAvailable = (): Challenge | undefined => {
    const { challenges } = this.context
    return challenges?.find(c => this.isAvailable(c))
  }
}
