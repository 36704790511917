export const VALID_CHALLENGES = [
  {
    type: 'wedding',
    name: 'Wedding Challenge',
    expiry_date: '2022-08-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/5556970129428',
    published_state: 'active',
  },
  {
    type: 'pastel',
    name: 'Pastel Challenge',
    expiry_date: '2022-08-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/5556970129428',
    published_state: 'active',
  },
  {
    type: 'geometric',
    name: 'Geometric Challenge',
    expiry_date: '2022-08-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/5556970129428',
    published_state: 'active',
  },
  {
    type: 'ai',
    name: 'Artificial Intelligence Challenge',
    start_date: '2022-08-01T00:00:00Z',
    expiry_date: '2022-10-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/7963256627092',
    published_state: 'active',
  },
  {
    type: 'golden',
    name: 'Golden Mockups Challenge',
    start_date: '2022-08-01T00:00:00Z',
    expiry_date: '2022-10-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/7963256627092',
    published_state: 'active',
  },
  {
    type: 'barkitecture',
    name: 'Barkitecture Challenge',
    start_date: '2022-08-01T00:00:00Z',
    expiry_date: '2022-10-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/7963256627092',
    published_state: 'active',
  },
  {
    type: 'hygge',
    name: 'Hygge Challenge',
    start_date: '2022-10-01T00:00:00Z',
    expiry_date: '2022-12-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/9631923143316',
    published_state: 'active',
  },
  {
    type: 'vertical',
    name: 'Vertical Challenge',
    start_date: '2022-10-01T00:00:00Z',
    expiry_date: '2022-12-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/9631923143316',
    published_state: 'active',
  },
  {
    type: 'ultramodern',
    name: 'Ultramodern Challenge',
    start_date: '2022-10-01T00:00:00Z',
    expiry_date: '2022-12-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/9631923143316',
    published_state: 'active',
  },
  {
    type: 'minimalism',
    name: 'Minimalism Challenge',
    start_date: '2022-12-01T00:00:00Z',
    expiry_date: '2023-02-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/9631923143316', // TODO
    published_state: 'active',
  },
  {
    type: 'portraits',
    name: 'Portraits Challenge',
    start_date: '2022-12-01T00:00:00Z',
    expiry_date: '2023-02-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/9631923143316', // TODO
    published_state: 'active',
  },
  {
    type: 'vivid',
    name: 'Vivid Challenge',
    start_date: '2022-12-01T00:00:00Z',
    expiry_date: '2023-02-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/9631923143316', // TODO
    published_state: 'active',
  },
  {
    type: 'macro',
    name: 'Macro Challenge',
    start_date: '2023-02-01T00:00:00Z',
    expiry_date: '2023-04-03T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/12672787940372',
    published_state: 'active',
  },
  {
    type: 'nostalgia',
    name: 'Nostalgia Challenge',
    start_date: '2023-02-01T00:00:00Z',
    expiry_date: '2023-04-03T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/12672787940372',
    published_state: 'active',
  },
  {
    type: 'monochrome',
    name: 'Monochrome Challenge',
    start_date: '2023-02-01T00:00:00Z',
    expiry_date: '2023-04-03T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/12672787940372',
    published_state: 'active',
  },
  {
    type: 'stilllife',
    name: 'Still Life Challenge',
    start_date: '2023-04-03T00:00:00Z',
    expiry_date: '2023-06-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/14170800715668',
    published_state: 'active',
  },
  {
    type: 'highcontrast',
    name: 'High Contrast Challenge',
    start_date: '2023-04-03T00:00:00Z',
    expiry_date: '2023-06-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/14170800715668',
    published_state: 'active',
  },
  {
    type: 'sciencefiction',
    name: 'Science Fiction Challenge',
    start_date: '2023-04-03T00:00:00Z',
    expiry_date: '2023-06-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/14170800715668',
    published_state: 'active',
  },
  {
    type: 'lofi',
    name: 'Lofi Challenge',
    start_date: '2023-07-01T00:00:00Z',
    expiry_date: '2023-09-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/16536960812692',
    published_state: 'active',
  },
  {
    type: 'rustic',
    name: 'Rustic Challenge',
    start_date: '2023-07-01T00:00:00Z',
    expiry_date: '2023-09-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/16536960812692',
    published_state: 'active',
  },
  {
    type: 'inmotion',
    name: 'In Motion Challenge',
    start_date: '2023-07-01T00:00:00Z',
    expiry_date: '2023-09-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/16536960812692',
    published_state: 'active',
  },
  {
    type: 'riso',
    name: 'Riso Challenge',
    start_date: '2023-10-01T00:00:00Z',
    expiry_date: '2023-12-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/19499254086804',
    published_state: 'active',
  },
  {
    type: 'moody',
    name: 'Moody Challenge',
    start_date: '2023-10-01T00:00:00Z',
    expiry_date: '2023-12-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/19499254086804',
    published_state: 'active',
  },
  {
    type: 'duotone',
    name: 'Duotone Challenge',
    start_date: '2023-10-01T00:00:00Z',
    expiry_date: '2023-12-01T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/19499254086804',
    published_state: 'active',
  },
  {
    type: 'futuristic',
    name: 'Futuristic Challenge',
    start_date: '2024-01-01T00:00:00Z',
    expiry_date: '2024-02-29T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/22499059902740',
    published_state: 'active',
  },
  {
    type: 'diversity',
    name: 'Diversity Challenge',
    start_date: '2024-01-01T00:00:00Z',
    expiry_date: '2024-02-29T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/22499059902740',
    published_state: 'active',
  },
  {
    type: 'grunge',
    name: 'Grunge Challenge',
    start_date: '2024-01-01T00:00:00Z',
    expiry_date: '2024-02-29T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/22499059902740',
    published_state: 'active',
  },
  {
    type: 'handmade',
    name: 'Handmade Challenge',
    start_date: '2024-03-01T00:00:00Z',
    expiry_date: '2024-04-30T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/23738146984212',
    published_state: 'active',
  },
  {
    type: 'contrast',
    name: 'Contrast Challenge',
    start_date: '2024-03-01T00:00:00Z',
    expiry_date: '2024-04-30T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/23738146984212',
    published_state: 'active',
  },
  {
    type: 'pixelation',
    name: 'Pixelation Challenge',
    start_date: '2024-03-01T00:00:00Z',
    expiry_date: '2024-04-30T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/23738146984212',
    published_state: 'active',
  },
  {
    type: 'metallic',
    name: 'Metallic Challenge',
    start_date: '2024-05-01T00:00:00Z',
    expiry_date: '2024-06-30T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/25651239297300',
    published_state: 'active',
  },
  {
    type: 'asymmetrical',
    name: 'Asymmetrical Challenge',
    start_date: '2024-05-01T00:00:00Z',
    expiry_date: '2024-06-30T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/25651239297300',
    published_state: 'active',
  },
  {
    type: 'muted',
    name: 'Muted Challenge',
    start_date: '2024-05-01T00:00:00Z',
    expiry_date: '2024-06-30T00:00:00Z',
    details_url:
      'https://eezycontributors.zendesk.com/hc/en-us/articles/25651239297300',
    published_state: 'active',
  },
]
