import React, { ReactNode, useEffect, useState } from 'react'
import { ClickAwayListener, Paper, PopperProps } from '@mui/material'
import { genRandom } from '../../../helpers'
import * as S from './styled'

export type SelectOption = {
  label: string
  value: string
}

type Props = PopperProps & {
  handleClose: () => void
  anchorEl?: Element
  className?: string
  open: boolean
  wrapButton?: boolean
  children: ReactNode
}

const MenuPopper: React.FC<Props> = ({
  anchorEl,
  children,
  className,
  handleClose,
  open,
  wrapButton = false,
}) => {
  const [buttonHeight, setButtonHeight] = useState(null)
  const [menuWidth, setMenuWidth] = useState(null)
  const childrenArray = React.Children.map(children, child => {
    // checking isValidElement is the safe way and avoids a typescript error too

    return child
  })

  useEffect(() => {
    if (wrapButton) {
      anchorEl && setButtonHeight(window.getComputedStyle(anchorEl).height)
      anchorEl && setMenuWidth(window.getComputedStyle(anchorEl).width)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl])

  if (wrapButton) {
    childrenArray.unshift(
      <S.StyledButtonCopy
        key={`sbc-${genRandom(7)}`}
        dangerouslySetInnerHTML={{ __html: anchorEl?.innerHTML }}
      />
    )
  }

  return (
    <S.StyledMenu
      anchorEl={anchorEl}
      className={className}
      open={open}
      placement={'bottom-start'}
      data-testid={'menu-popper'}
      // DOM props only for dynamic styling
      $buttonheight={buttonHeight}
      $menuwidth={menuWidth}
      $wrapbutton={wrapButton.toString()}
    >
      <Paper>
        <ClickAwayListener onClickAway={handleClose}>
          <S.StyledMenuList>{childrenArray}</S.StyledMenuList>
        </ClickAwayListener>
      </Paper>
    </S.StyledMenu>
  )
}

export default MenuPopper
