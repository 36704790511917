import { styled } from '@mui/material/styles'
import { themeColor, themeSpacing } from 'eezy-components'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Session } from '../../../types'

import ContributorLinks from '../links/contributor'

import VecteezyIcon from 'ez-styles/assets/img/logos/rebrand/orange-horizontal-lockup.svg'

type Props = {
  isOpen: boolean
  onClose: () => void
  session: Session
}

type StyleProps = {
  isOpen?: boolean
  showOverlay?: boolean
}

const Menu = ({ isOpen, onClose, session }: Props) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShowOverlay(true)
      }, 350)
    }
  }, [isOpen])

  const onCloseSidebar = () => {
    setTimeout(() => setShowOverlay(false), 250)
    onClose()
  }

  const navigateTo = (route: string) => {
    if (route.includes('http') || route.includes('www')) {
      window.open(route, '_blank')
    } else {
      navigate(route)
    }

    onCloseSidebar()
  }

  return (
    <Container isOpen={isOpen} data-testid="mobile-menu">
      <Wrapper isOpen={isOpen} showOverlay={showOverlay}>
        {showOverlay ? (
          <>
            <LogoWrapper
              onClick={() => navigateTo('/')}
              data-testid="mobile-menu-logo"
            >
              <VecteezyIcon />
            </LogoWrapper>
            <ContributorLinks
              navigate={navigateTo}
              pathname={pathname}
              session={session}
            />
          </>
        ) : null}
      </Wrapper>
      <Overlay isOpen={showOverlay} onClick={onCloseSidebar} />
    </Container>
  )
}

export default Menu

const Container = styled('div')<StyleProps>`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: ${(props: StyleProps) => (props.isOpen ? '100vw' : '0')};
  z-index: 9999;
  webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
`

const Wrapper = styled('div')<StyleProps>`
  background-color: #fff;
  height: 100vh;
  padding: ${(props: StyleProps) =>
    props.showOverlay ? themeSpacing(3) : '0'};
  position: fixed;
  top: 0;
  left: 0;
  width: ${(props: StyleProps) => (props.isOpen ? '90vw' : '0')};
  z-index: 9999;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;

  a {
    opacity: ${(props: StyleProps) => (props.isOpen ? '1' : '0')};
    -webkit-transition: opacity 0.6s ease-in-out;
    -moz-transition: opacity 0.6s ease-in-out;
    -o-transition: opacity 0.6s ease-in-out;
    transition: opacity 0.6s ease-in-out;
  }
`

const Overlay = styled('div')<StyleProps>`
  background-color: ${themeColor('secondary', 'main')};
  height: 100vh;
  z-index: 999;
  opacity: ${(props: StyleProps) => (props.isOpen ? '.8' : '0')};
  -webkit-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
`

const LogoWrapper = styled('div')`
  margin-bottom: ${themeSpacing(2)};

  svg {
    width: ${themeSpacing(20)};
    height: ${themeSpacing(6)};
  }

  &:hover {
    cursor: pointer;
  }
`
