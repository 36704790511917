import { styled } from '@mui/system'
import { themeColor, themeSpacing } from '../../helpers/themeHelpers'
import { Box, Theme } from '@mui/material'
import _ArrowLeft from 'ez-styles/assets/icons/arrows/arrow-small-left.svg'
import _ArrowRight from 'ez-styles/assets/icons/arrows/arrow-small-right.svg'

export const CarouselContainer = styled('div')`
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .slick-track::before,
  .slick-track::after {
    display: table;
    content: '';
  }

  .slick-track::after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: ${themeSpacing(3)};
  }

  .slick-dots {
    position: absolute;
    bottom: -${themeSpacing(3)};
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  .slick-dots li {
    position: relative;
    display: inline-block;
    width: ${themeSpacing(2.5)};
    height: ${themeSpacing(2.5)};
    margin: 0 ${themeSpacing(1)};
    padding: 0;
    cursor: pointer;
  }

  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: ${themeSpacing(2.5)};
    height: ${themeSpacing(2.5)};
    padding: ${themeSpacing(1)};
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }

  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }

  .slick-dots li button:hover::before,
  .slick-dots li button:focus::before {
    opacity: 1;
  }

  .slick-dots li button::before {
    line-height: ${themeSpacing(2.5)};
    position: absolute;
    top: 0;
    left: 0;
    width: ${themeSpacing(2.5)};
    height: ${themeSpacing(2.5)};
    content: '•';
    text-align: center;
    opacity: 0.25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-dots li.slick-active button::before {
    opacity: 0.75;
    color: black;
  }
`

export const ArrowContainer = styled(Box)<{
  $direction: string
  $disabled: boolean
  theme: Theme
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: ${props => (props.$direction === 'next' ? 'auto' : 0)};
  right: ${props => (props.$direction === 'next' ? 0 : 'auto')};
  transform: translate(
    ${props => (props.$direction === 'next' ? '110%' : '-110%')},
    -50%
  );
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  width: ${themeSpacing(3)};
  height: ${themeSpacing(3)};
  border-radius: 50%;
  opacity: ${props => (props.$disabled ? 0.5 : 1)};
  z-index: 99;
`

export const ChevronIcon = styled(_ArrowRight)`
  fill: ${themeColor('grey', '400')};
  width: ${themeSpacing(1)};
  height: ${themeSpacing(1)};
`
