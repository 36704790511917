import { styled } from '@mui/material/styles'
import { themeBorderRadius, themeSpacing } from 'eezy-components'

export const Container = styled('div')`
  flex: 1;
  width: 100%;
  margin: 0 auto;
  padding: ${themeSpacing(4)};
  background-color: #fff;
  border-radius: ${themeBorderRadius('large')};
`
