import React from 'react'
import { styled } from '@mui/system'
import { themeSpacing } from '../../../helpers'
import { Theme } from '@mui/material'

type Props = {
  children: React.ReactNode
  borderWidth?: string
  invert?: boolean
  padding?: number
}

const Box = ({
  borderWidth = '0',
  invert = false,
  padding = 1.5,
  children,
}: Props): JSX.Element => {
  return (
    <Wrapper
      borderWidth={borderWidth}
      invert={invert}
      padding={padding}
      data-testid="eezy-layout-box"
    >
      {children}
    </Wrapper>
  )
}

export default Box

const Wrapper = styled('div')<{
  theme: Theme
  invert: boolean
  borderWidth?: string
  padding?: number
}>`
  ${({ invert }) =>
    invert
      ? `
background-color: #fff;
filter: invert(100%);`
      : ''}
  background-color: inherit;
  border-width: ${({ borderWidth }) => borderWidth};
  border: ${({ borderWidth }) => borderWidth} solid;
  display: block;
  padding: ${({ padding }) => themeSpacing(padding)};

  /* ↓ For high contrast mode */
  outline: 1px solid transparent;
  outline-offset: calc(1px * -1);
`
