import { CSSProperties } from 'react'
import { createTheme, ThemeOptions } from '@mui/material/styles'
import token from 'ez-styles/tokens/json-token.json'

type PaletteOverlay = {
  dark: string
}

declare module '@mui/material/styles' {
  interface Palette {
    overlay?: PaletteOverlay
  }

  interface PaletteOptions {
    disabled?: PaletteOptions['primary']
  }

  interface PaletteColor {
    lighter: string
    veryLight: string
    pale: string
  }

  interface SimplePaletteColorOptions {
    lighter?: string
    veryLight?: string
    pale?: string
  }

  interface TypographyVariants {
    title: CSSProperties
    subtitle: CSSProperties
    navLink: CSSProperties
    menuItem: CSSProperties
    smallBoldText: CSSProperties
    smallText: CSSProperties
    mediumText: CSSProperties
    mediumBoldText: CSSProperties
    largeText: CSSProperties
    largeBoldText: CSSProperties
    vlargeText: CSSProperties
  }

  interface TypographyVariantsOptions {
    title?: CSSProperties
    subtitle?: CSSProperties
    navLink?: CSSProperties
    menuItem?: CSSProperties
    smallBoldText?: CSSProperties
    smallText?: CSSProperties
    mediumText?: CSSProperties
    mediumBoldText?: CSSProperties
    largeText?: CSSProperties
    largeBoldText?: CSSProperties
    vlargeText?: CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true
    subtitle: true
    navLink: true
    menuItem: true
    smallBoldText: true
    smallText: true
    mediumText: true
    mediumBoldText: true
    largeText: true
    largeBoldText: true
    vlargeText: true
  }
}

// override for the button font family (so we don't use "Inter")
// TODO: after a firm decision about "Inter" is made and
// design token is updated, this should be able to be removed
token['type--button-family'] =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

export const spacingFunc = factor => `${8 * factor}px`

const hexToRGBA = (hex: string, alpha: string | number) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  } else {
    return `rgb(${r}, ${g}, ${b})`
  }
}

const palette = {
  grey: {
    '30': token['color--navy-3'],
    '50': token['color--navy-5'],
    '100': token['color--navy-10'],
    '200': token['color--navy-20'],
    '300': token['color--navy-30'],
    '400': token['color--navy-40'],
    '500': token['color--navy-50'],
    '600': token['color--navy-60'],
    '700': token['color--navy-70'],
    '800': token['color--navy-80'],
    '900': token['color--navy-90'],
  },
  primary: {
    main: token['color--orange-100'],
    light: token['color--orange-90'],
    lighter: token['color--orange-70'],
    pale: token['color--orange-10'],
    contrastText: '#ffffff',
  },
  disabled: {
    main: token['color--navy-10'],
    contrastText: token['color--navy-20'],
  },
  secondary: {
    main: token['color--navy-100'],
    light: token['color--navy-60'],
    lighter: token['color--navy-20'],
    veryLight: token['color--navy-10'],
    pale: token['color--navy-5'],
  },
  success: {
    main: token['color--green-100'],
    light: token['color--green-60'],
    lighter: token['color--green-30'],
    veryLight: token['color--green-20'],
    pale: token['color--green-10'],
    contrastText: '#fff',
  },
  error: {
    main: token['color--red-100'],
    light: token['color--red-60'],
    lighter: token['color--red-30'],
    veryLight: token['color--red-20'],
    pale: token['color--red-10'],
    contrastText: '#fff',
  },
  warning: {
    main: token['color--alert-yellow'],
    light: token['color--yellow-50'],
    pale: token['color--yellow-10'],
    contrastText: '#D59900',
  },
  overlay: {
    dark: hexToRGBA(token['color--navy-100'], 0.8),
  },
}

export const themeOptions: ThemeOptions = {
  palette,
  typography: {
    // set a universal bold parameter according to our style token
    fontWeightBold: token['type--h1-weight'],
    h1: {
      color: palette.secondary.main,
      fontSize: token['type--h1-size'],
      fontWeight: token['type--h1-weight'],
      lineHeight: token['type--h1-lineheight'],

      '&.MuiTypography-gutterBottom': {
        marginBottom: spacingFunc(3),
      },
    },
    h2: {
      color: palette.secondary.main,
      fontSize: token['type--h2-size'],
      fontWeight: token['type--h2-weight'],
      lineHeight: token['type--h2-lineheight'],

      '&.MuiTypography-gutterBottom': {
        marginBottom: spacingFunc(3),
      },
    },
    h3: {
      color: palette.secondary.main,
      fontSize: token['type--h3-size'],
      fontWeight: token['type--h3-weight'],
      lineHeight: token['type--h3-lineheight'],

      '&.MuiTypography-gutterBottom': {
        marginBottom: spacingFunc(3),
      },
    },
    h4: {
      color: palette.secondary.main,
      fontSize: token['type--h4-size'],
      fontWeight: token['type--h4-weight'],
      lineHeight: token['type--h4-lineheight'],

      '&.MuiTypography-gutterBottom': {
        marginBottom: spacingFunc(3),
      },
    },
    h5: {
      color: palette.secondary.main,
      fontSize: token['type--h5-size'],
      fontWeight: token['type--h5-weight'],
      lineHeight: token['type--h5-lineheight'],

      '&.MuiTypography-gutterBottom': {
        marginBottom: spacingFunc(3),
      },
    },
    h6: {
      color: palette.secondary.main,
      fontSize: token['type--h7-size'],
      fontWeight: token['type--h6-weight'],
      lineHeight: token['type--h6-lineheight'],

      '&.MuiTypography-gutterBottom': {
        marginBottom: spacingFunc(3),
      },
    },
    navLink: {
      color: palette.secondary.main,
      fontSize: token['type--body-medium-semibold-size'],
      fontWeight: token['type--body-medium-semibold-weight'],
    },
    menuItem: {
      color: palette.secondary.main,
      fontSize: token['type--body-medium-size'],
      fontWeight: token['type--body-medium-weight'],
    },
    button: {
      color: palette.secondary.main,
      fontFamily: token['type--button-family'],
      fontSize: token['type--button-size'],
      fontWeight: token['type--button-weight'],
      letterSpacing: token['type--button-letterspacing'],
      lineHeight: token['type--button-lineheight'],
      textTransform: 'initial',
    },
    body1: {
      color: palette.secondary.light,
      fontSize: token['type--body-medium-size'],
      fontWeight: token['type--body-medium-weight'],
      letterSpacing: token['type--body-medium-letterspacing'],
      lineHeight: token['type--body-medium-lineheight'],

      '&.MuiTypography-gutterBottom': {
        marginBottom: spacingFunc(3),

        '&:last-child': {
          marginBottom: '0',
        },
      },

      '& strong, & b': {
        color: palette.secondary.light,
      },
    },
    body2: {
      color: palette.secondary.main,
      fontSize: token['type--body-small-size'],
      fontWeight: token['type--body-small-weight'],
      letterSpacing: token['type--body-small-letterspacing'],
      lineHeight: token['type--body-small-lineheight'],
    },
    title: {
      color: palette.secondary.main,
      fontSize: token['type--h6-size'],
      fontWeight: token['type--h6-weight'],
      letterSpacing: token['type--h6-letterspacing'],
      lineHeight: token['type--h6-lineheight'],
    },
    subtitle: {
      color: palette.secondary.main,
      fontSize: token['type--subtitle-size'],
      fontWeight: token['type--subtitle-weight'],
      letterSpacing: token['type--subtitle-letterspacing'],
      lineHeight: token['type--subtitle-lineheight'],
    },
    subtitle2: {
      color: palette.secondary.main,
      fontSize: token['type--h9-size'],
      fontWeight: token['type--h9-weight'],
      letterSpacing: token['type--h9-letterspacing'],
      lineHeight: token['type--h9-lineheight'],
    },
    smallText: {
      color: palette.secondary.main,
      fontSize: token['type--body-small-size'],
      fontWeight: token['type--body-small-weight'],
    },
    smallBoldText: {
      color: palette.secondary.main,
      fontSize: token['type--body-small-semibold-size'],
      fontWeight: token['type--body-small-semibold-weight'],
    },
    mediumText: {
      color: palette.secondary.main,
      fontSize: token['type--body-medium-size'],
      fontWeight: token['type--body-medium-weight'],
    },
    mediumBoldText: {
      color: palette.secondary.main,
      fontSize: token['type--body-medium-semibold-size'],
      fontWeight: token['type--body-medium-semibold-weight'],
    },
    largeText: {
      color: palette.secondary.main,
      fontSize: token['type--body-large-size'],
      fontWeight: token['type--body-large-weight'],
    },
    largeBoldText: {
      color: palette.secondary.main,
      fontSize: token['type--body-large-semibold-size'],
      fontWeight: token['type--body-large-semibold-weight'],
      lineHeight: token['type--body-large-semibold-lineheight'],
    },
    vlargeText: {
      color: palette.secondary.main,
      fontSize: token['type--body-vlarge-size'],
      fontWeight: token['type--body-vlarge-weight'],
    },
  },

  spacing: spacingFunc,
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: token['radius--small'],
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: palette.secondary.pale,
          color: palette.secondary.main,
          fontWeight: token['type--body-medium-semibold-weight'],
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: token['type--body-medium-size'],
          fontWeight: token['type--body-medium-weight'],
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: token['radius--small'],
        },
      },
    },
  },
}

export default createTheme(themeOptions)
