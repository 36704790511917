import { styled } from '@mui/system'
import { themeColor, themeSpacing } from '../../helpers'
import { Theme } from '@mui/material'

const getValue = ({ disabled, variant, theme }, key) => {
  const vars = {
    fill:
      variant === 'danger'
        ? themeColor('error', 'main')({ theme })
        : variant === 'primary'
        ? themeColor('primary', 'main')({ theme })
        : themeColor('secondary', 'main')({ theme }),
    fillHover:
      variant === 'danger'
        ? themeColor('error', 'contrastText')({ theme })
        : variant === 'primary'
        ? themeColor('primary', 'main')({ theme })
        : themeColor('secondary', 'main')({ theme }),
    bgColor:
      variant === 'danger'
        ? themeColor('error', 'pale')({ theme })
        : variant === 'primary'
        ? themeColor('primary', 'contrastText')({ theme })
        : themeColor('secondary', 'veryLight')({ theme }),
    bgColorHover:
      variant === 'danger'
        ? themeColor('error', 'main')({ theme })
        : variant === 'primary'
        ? themeColor('primary', 'contrastText')({ theme })
        : themeColor('secondary', 'lighter')({ theme }),
    borderColor:
      variant === 'danger'
        ? themeColor('error', 'main')({ theme })
        : variant === 'primary'
        ? themeColor('primary', 'main')({ theme })
        : themeColor('secondary', 'main')({ theme }),
  }

  if (disabled) {
    vars['fill'] = themeColor('secondary', 'veryLight')({ theme })
    vars['fillHover'] = themeColor('secondary', 'veryLight')({ theme })
    vars['bgColor'] = themeColor('secondary', 'pale')({ theme })
    vars['bgColorHover'] = themeColor('secondary', 'pale')({ theme })
  }
  return vars[key]
}

export const Container = styled('div')<{
  transparentBg: boolean
  disabled: boolean
  size: number
  outlined: boolean
  borderColor?: string
  bgColor?: string
  bgColorHover?: string
  variant: string
  sizeVariant: string
  theme: Theme
}>`
  align-items: center;
  border-radius: 50%;
  background-color: ${props =>
    props.transparentBg ? 'transparent' : getValue(props, 'bgColor')};
  cursor: ${props =>
    props.onClick && !props.disabled ? 'pointer' : 'default'};
  display: flex;
  justify-content: center;
  height: ${props => themeSpacing(props.size)(props)};
  width: ${props => themeSpacing(props.size)(props)};
  border: ${props =>
    props.outlined ? `3px solid ${getValue(props, 'borderColor')}` : 0};

  & > svg {
    fill: ${props => getValue(props, 'fill')};
    stroke: ${props =>
      props.sizeVariant !== 'large' ? 'inherit' : getValue(props, 'bgColor')};
    stroke-width: ${props =>
      props.sizeVariant !== 'large' ? 'inherit' : '1.3px'};
  }

  &:hover {
    background-color: ${props =>
      props.transparentBg ? 'transparent' : getValue(props, 'bgColorHover')};

    & > svg {
      fill: ${props => getValue(props, 'fillHover')};
      stroke: ${props =>
        props.sizeVariant !== 'large' ? 'inherit' : getValue(props, 'bgColor')};
      stroke-width: ${props =>
        props.sizeVariant !== 'large' ? 'inherit' : '1.3px'};
    }
  }
`
