import { ArrowDropDown } from '@mui/icons-material'
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'
import {
  Resource,
  ResponsesSingleItemCount,
  usePatchApiV1AdminResourcesAssignContributorReviewBatch,
  usePatchApiV1AdminResourcesAssignReviewBatch,
} from 'api'
import DuplicateResourceModal from 'components/ResourceList/DuplicateResourceModal'
import { Routes } from 'constants/index'
import { Button } from 'eezy-components'
import { captureError } from 'helpers/error'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  AuthStore,
  FlaggedReviewStore,
  NavStore,
  ResourceStore,
  SubmittedReviewStore,
} from 'stores'
import { ReviewedResource } from 'types'
import { useInstance } from 'util/di'
import * as S from './styled'

type Props = {
  canSubmit?: boolean
}

const AutoAssignButton = ({ canSubmit = true }: Props): JSX.Element => {
  const { t } = useTranslation()
  const location = useLocation()
  const anchorRef = useRef(null)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitOptionOpen, setSubmitOptionOpen] = useState(false)
  const [duplicateModalVisibility, setDuplicateModalVisibility] =
    useState<boolean>(false)
  const [submitPath, setSubmitPath] = useState<string>('')
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const submittedReviewStore = useInstance('SubmittedReviewStore')
  const flaggedReviewStore = useInstance('FlaggedReviewStore')

  const { visibleResources, setVisible } =
    useInstance<ResourceStore>('ResourceStore')
  const {
    setFilesReviewedCount,
    setCurrentReviewedContributor,
    currentReviewedContributor,
    setReviewedContributorLoading,
    reviewedContributorLoading,
  } = useInstance<NavStore>('NavStore')
  const { session } = useInstance<AuthStore>('AuthStore')

  const stateToAssign = useMemo(() => {
    if (location.pathname.includes('submitted')) {
      return 'submitted'
    } else if (location.pathname.includes('flagged')) {
      return 'flagged'
    }
    return null
  }, [location.pathname])

  const reviewState = useMemo(() => {
    if (/\/submitted\/[^/]+$/.test(location.pathname)) {
      return 'submitted'
    } else if (/\/flagged\/[^/]+$/.test(location.pathname)) {
      return 'flagged'
    }
    return null
  }, [location.pathname])

  const { mutate: assignNewBatch } =
    usePatchApiV1AdminResourcesAssignReviewBatch({
      queryParams: {
        contributor_import_guid: currentReviewedContributor || '',
        reviewer_import_guid: session?.id || '',
        state: stateToAssign ?? 'submitted',
      },
    })

  const { mutate: assignBatch, loading: batchLoading } =
    usePatchApiV1AdminResourcesAssignContributorReviewBatch({
      queryParams: {
        state: stateToAssign ?? 'submitted',
      },
    })

  const store: SubmittedReviewStore | FlaggedReviewStore = useMemo(() => {
    if (stateToAssign === 'submitted') {
      return submittedReviewStore as SubmittedReviewStore
    } else if (stateToAssign === 'flagged') {
      return flaggedReviewStore as FlaggedReviewStore
    } else {
      return submittedReviewStore as SubmittedReviewStore
    }
  }, [stateToAssign, submittedReviewStore, flaggedReviewStore])

  const selectedVisibleIds = store.selectedVisible.map(({ id }) => id)
  const submittableResources = store.reviewedResources.filter(reviewed =>
    selectedVisibleIds.includes(reviewed.resource.id)
  )
  const submittableCount = submittableResources.length
  const hasDuplicates = submittableResources.some(r => r.resource.duplicate_id)
  const donePath = store.state.includes('submitted')
    ? Routes.Submitted
    : Routes.Flagged

  const assignNewBatchWithRetries = useCallback(
    async (maxRetries = 3, delay = 1000) => {
      let attempts = 0

      while (attempts < maxRetries) {
        // @ts-ignore
        const { count }: ResponsesSingleItemCount = await assignNewBatch()
        if (count > 0) {
          return { count }
        } else {
          attempts += 1
          if (attempts < maxRetries) {
            await new Promise(resolve => setTimeout(resolve, delay))
          }
        }
      }
      return { count: 0 }
    },
    [assignNewBatch]
  )

  const assignBatchWithRetries = useCallback(
    async (maxRetries = 3, delay = 1000) => {
      let attempts = 0

      while (attempts < maxRetries) {
        // @ts-ignore
        const { count, type }: ResponsesSingleItemCount = await assignBatch()

        if (type) {
          setCurrentReviewedContributor(type)
        }

        if (count > 0 && type) {
          return { count, type }
        } else {
          attempts += 1
          if (attempts < maxRetries) {
            await new Promise(resolve => setTimeout(resolve, delay))
          }
        }
      }
      return { count: 0, type: null }
    },
    [assignBatch, setCurrentReviewedContributor]
  )

  const assignBatchWithContributorId = useCallback(
    async (delay = 1000, timeLimitMs = 120000) => {
      const startTime = Date.now()

      // eslint-disable-next-line no-constant-condition
      while (true) {
        // Check if we've exceeded the time limit (2 minutes by default)
        if (Date.now() - startTime > timeLimitMs) {
          throw new Error(
            'Failed to retrieve a contributor within the time limit.'
          )
        }

        // @ts-ignore
        const { count, type }: ResponsesSingleItemCount = await assignBatch({
          last_contributor_id: currentReviewedContributor,
        })

        if (type) {
          setCurrentReviewedContributor(type)
        }

        if (count > 0 && type) {
          return { count, type }
        }

        await new Promise(resolve => setTimeout(resolve, delay))
      }
    },
    [assignBatch, currentReviewedContributor, setCurrentReviewedContributor]
  )

  const handleSubmitError = (error: unknown) => {
    const message = `Bulk Actions error while submitting reviewed resources - ${
      submitPath ?? 'missing'
    } path - ResourceList/Actions/Bulk.tsx`

    setIsSubmitting(false)
    setSubmitPath('')
    setDuplicateModalVisibility(false)

    enqueueSnackbar(t('util.something_went_wrong'), {
      variant: 'error',
    })
    captureError(error, message)
  }

  const submitResources = async (
    reviewedResources: ReviewedResource[]
  ): Promise<Resource[]> => {
    setIsSubmitting(true)
    const { succeededIds } = await store.submitReviewedResources(
      '',
      reviewedResources
    )

    const successfulIds = succeededIds.map(String)

    store.setReviewedResources(
      store.reviewedResources.filter(
        ({ resource }) => !successfulIds.includes(String(resource.id))
      )
    )

    const remainingVisible = visibleResources
      .filter(res => !successfulIds.includes(String(res.id)))
      .map(res => res)

    const updatedCount = store.totalPendingCount - successfulIds.length
    const updatedFilteredCount = store.totalFilteredCount - successfulIds.length

    store.setTotalPendingCount(updatedCount)
    store.setTotalFilteredCount(updatedFilteredCount)
    setIsSubmitting(false)
    setSubmitPath('')
    setDuplicateModalVisibility(false)
    return remainingVisible
  }

  const submitResourcesAndContinue = async (
    reviewedResources: ReviewedResource[],
    bypass?: boolean
  ) => {
    setSubmitPath('continue')

    if (!bypass && hasDuplicates) {
      setDuplicateModalVisibility(true)
    } else {
      try {
        const remainingVisible = await submitResources(reviewedResources)

        setFilesReviewedCount(reviewedResources.length)

        if (remainingVisible.length > 0) {
          setVisible(remainingVisible)
        } else {
          setReviewedContributorLoading(true)
          const { count } = await assignNewBatchWithRetries(3, 1000)
          const contributorId = currentReviewedContributor

          if (count > 0) {
            window.location.href = `${donePath}/${contributorId}`
          } else {
            const result = await assignBatchWithContributorId()
            if (result.count > 0 && result.type) {
              navigate(`${donePath}/${result.type}`)
            } else {
              navigate(donePath)
            }
          }
        }
      } catch (error) {
        navigate(donePath)
        handleSubmitError(error)
      } finally {
        setReviewedContributorLoading(false)
      }
    }
  }

  const submitResourcesAndExit = async (
    reviewedResources: ReviewedResource[],
    bypass?: boolean
  ) => {
    setSubmitPath('exit')

    if (!bypass && hasDuplicates) {
      setDuplicateModalVisibility(true)
    } else {
      try {
        await submitResources(reviewedResources)
        await store.unassignResources()
        setFilesReviewedCount(reviewedResources.length)
        navigate(donePath)
      } catch (error) {
        handleSubmitError(error)
      }
    }
  }

  const handleSubmit = () => {
    try {
      if (submitPath === 'continue') {
        submitResourcesAndContinue(submittableResources, true)
      } else if (submitPath === 'exit') {
        submitResourcesAndExit(submittableResources, true)
      } else {
        return
      }
    } catch (error) {
      handleSubmitError(error)
    }
  }

  const handleStartReview = async () => {
    try {
      setReviewedContributorLoading(true)
      const { count, type } = await assignBatchWithRetries(3)
      const contributorId = type

      if (count > 0 && type) {
        navigate(`${donePath}/${contributorId}`)
      } else {
        const result = await assignBatchWithContributorId()
        if (result.count > 0 && result.type) {
          navigate(`${donePath}/${result.type}`)
        } else {
          enqueueSnackbar(t('review.review_no_contrib'), {
            variant: 'error',
          })
        }
      }
    } catch (error) {
      enqueueSnackbar(t('util.something_went_wrong'), {
        variant: 'error',
      })
      captureError(
        error,
        'Error while starting review - AutoAssignButton/index.tsx'
      )
    } finally {
      setReviewedContributorLoading(false)
    }
  }

  if (session?.canReviewContributors.isLegacyReviewer) {
    return <></>
  }

  if (canSubmit && (reviewState === 'flagged' || reviewState === 'submitted')) {
    return (
      <>
        <S.StyledGroup
          variant="contained"
          color="primary"
          aria-label="submission button group"
          ref={anchorRef}
        >
          <S.SubmitButton
            color="primary"
            disabled={
              !submittableCount ||
              isSubmitting ||
              batchLoading ||
              reviewedContributorLoading
            }
            loading={isSubmitting || batchLoading || reviewedContributorLoading}
            onClick={() => submitResourcesAndContinue(submittableResources)}
            testId="submit-batch"
          >
            {t('review.pending_submissions.submit')}
            &nbsp;
            {submittableCount && submittableCount > 1
              ? `(${submittableCount})`
              : ''}
          </S.SubmitButton>
          <S.DropdownButton
            disabled={!submittableCount || isSubmitting}
            color="primary"
            onClick={() => setSubmitOptionOpen(true)}
            testId="dropdown-button"
          >
            <ArrowDropDown />
          </S.DropdownButton>
        </S.StyledGroup>
        <Popper
          open={submitOptionOpen}
          anchorEl={anchorRef.current}
          placement="bottom-end"
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'right top' : 'right bottom',
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => setSubmitOptionOpen(false)}
                >
                  <MenuList id="split-button-menu">
                    <MenuItem
                      data-testid="submit-and-exit"
                      onClick={() =>
                        submitResourcesAndExit(submittableResources)
                      }
                    >
                      {t('review.pending_submissions.submit_exit')}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <DuplicateResourceModal
          isOpen={duplicateModalVisibility}
          setOpen={() => setDuplicateModalVisibility(!duplicateModalVisibility)}
          submitResources={handleSubmit}
          loading={isSubmitting}
        />
      </>
    )
  }

  return (
    <Button
      color="primary"
      onClick={handleStartReview}
      loading={batchLoading || reviewedContributorLoading}
      disabled={batchLoading || reviewedContributorLoading}
    >
      {t('review.start_review')}
    </Button>
  )
}

export default observer(AutoAssignButton)
