import React, { ReactNode } from 'react'
import { styled, css } from '@mui/system'
import {
  FormControl,
  InputLabel,
  Typography,
  FormHelperText,
} from '@mui/material'
import { themeBorderRadius, themeColor, themeSpacing } from '../../helpers'
import { TooltipIcon } from '../util'
import { WarningIcon } from '..'

export type InputWrapperProps = {
  className?: string
  fullWidth?: boolean
  label?: string
  labelColor?: string
  labelShrink?: boolean
  labelCaption?: string
  labelAsPlaceholder?: boolean
  required?: boolean
  tooltipContent?: ReactNode
  helperText?: string
  error?: boolean
  disabled?: boolean
  publiclyVisible?: boolean
  children?: React.ReactNode | React.ReactNode[]
}

const InputWrapper = ({
  children,
  className,
  fullWidth,
  label,
  labelColor,
  labelCaption,
  labelShrink,
  labelAsPlaceholder,
  required,
  tooltipContent,
  helperText,
  disabled,
  publiclyVisible,
  error = false,
}: InputWrapperProps): JSX.Element => (
  <StyledFormControl
    className={className}
    fullWidth={fullWidth}
    data-testid="input-wrapper"
    error={error}
    disabled={Boolean(disabled)}
  >
    {(label || tooltipContent) && (
      <InputHeader data-testid="input-header">
        {label && (
          <LabelWrapper data-testid="label-wrapper">
            {labelAsPlaceholder ? (
              <LabelAsPlaceholder
                id={`${label}-input-label`}
                data-testid="input-label"
                shrink={labelShrink}
                required={required}
              >
                <Typography
                  variant="mediumBoldText"
                  {...(labelColor?.length > 0
                    ? { sx: { color: labelColor } }
                    : {})}
                >
                  {label}
                </Typography>
              </LabelAsPlaceholder>
            ) : (
              <StyledLabel
                id={`${label}-input-label`}
                htmlFor={`${label}-input`}
                data-testid="input-label"
                shrink={labelShrink}
              >
                <Typography
                  variant="mediumBoldText"
                  {...(labelColor?.length > 0
                    ? { sx: { color: labelColor } }
                    : {})}
                >
                  {label}
                </Typography>

                {publiclyVisible ? <WarningIcon /> : null}

                {required ? (
                  <Typography variant="mediumBoldText" color="error">
                    *
                  </Typography>
                ) : null}
              </StyledLabel>
            )}

            {labelCaption && (
              <Typography data-testid="label-caption" variant="caption">
                {labelCaption}
              </Typography>
            )}
          </LabelWrapper>
        )}

        {tooltipContent && (
          <TooltipIcon
            data-testid="input-tooltip"
            tooltipContent={tooltipContent}
            interactive={true}
          />
        )}
      </InputHeader>
    )}

    {children}

    {helperText && (
      <FormHelperText data-testid="input-helper" error={error}>
        {helperText}
      </FormHelperText>
    )}
  </StyledFormControl>
)

const StyledFormControl = styled(FormControl)<{
  error?: boolean
}>`
  &.full-width {
    width: 100%;
  }

  & .MuiOutlinedInput-root {
    align-items: center;
    background-color: #fff;
    border: 1px solid ${themeColor('secondary', 'lighter')};
    padding: 0;
    border-radius: ${themeBorderRadius('small')};

    &.Mui-focused {
      border-color: ${themeColor('secondary', 'light')};

      & .MuiOutlinedInput-notchedOutline {
        border-width: 0;
      }
    }
  }

  & .MuiOutlinedInput-root.Mui-error {
    border: 1px solid ${themeColor('error', 'main')};

    &.Mui-focused {
      border-color: ${themeColor('error', 'main')};
    }
  }

  &:hover {
    & .MuiOutlinedInput-root {
      border-color: ${props =>
        props.error
          ? themeColor('error', 'main')
          : themeColor('secondary', 'light')};
    }
  }

  & .MuiOutlinedInput-input {
    padding: ${themeSpacing(1.5)} ${themeSpacing(1.75)};

    &::placeholder {
      color: ${themeColor('secondary', 'light')};
      font-style: italic;
    }
  }

  & .MuiSelect-select:focus {
    background-color: transparent;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-width: 0;
    border-color: ${themeColor('secondary', 'lighter')};
  }
`

const InputHeader = styled('div')`
  align-items: center;
  display: flex;
  margin-bottom: ${themeSpacing(1)};

  & .MuiTypography-caption {
    color: ${themeColor('secondary', 'light')};
  }

  & > *:last-child:not(:first-child) {
    margin-left: auto;
  }
`

const LabelWrapper = styled('div')`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-right: ${themeSpacing(1)};
`

const StyledLabel = styled('label')<{ shrink?: boolean }>`
  color: ${themeColor('secondary', 'main')};
  display: flex;
  gap: ${themeSpacing(1)};
  margin-right: ${themeSpacing(1)};
  align-items: center;

  ${props =>
    props.shrink &&
    css`
      span {
        font-size: ${themeSpacing(1.5)};
        font-weight: 500;
      }
    `}
`

const LabelAsPlaceholder = styled(InputLabel)`
  display: flex;

  & .MuiInputLabel-asterisk {
    position: relative;
    top: ${themeSpacing(0.5)};
    visibility: hidden;
  }

  &&.MuiInputLabel-shrink {
    background-color: #fff;
    color: ${themeColor('secondary', 'light')};
    padding: ${themeSpacing(0.5)};

    &.Mui-focused {
      color: ${themeColor('secondary', 'main')};
    }

    & .MuiInputLabel-asterisk {
      visibility: visible;
    }
  }
`

export default InputWrapper
