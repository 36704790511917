import { captureError } from 'helpers/error'
import { debounce } from 'lodash'
import { isStringable } from '../helpers'

export type Place = google.maps.places.PlaceResult
export type Prediction = google.maps.places.AutocompletePrediction
export type AddressComponent = google.maps.GeocoderAddressComponent

export type Coordinates = {
  lat?: number
  lng?: number
}

export default class MapsService {
  private node: HTMLDivElement | undefined = undefined
  private placesService: google.maps.places.PlacesService | undefined =
    undefined
  private placesAutocomplete:
    | google.maps.places.AutocompleteService
    | undefined = undefined

  constructor() {
    this.init()
  }

  init() {
    if (process.env.NODE_ENV === 'test') {
      return
      // @ts-ignore
    } else if (!window._cm_google_maps_loaded) {
      setTimeout(() => this.init(), 1000)
    } else {
      if (!this.placesService) {
        this.node = document.createElement('div')
        this.placesService = new google.maps.places.PlacesService(this.node)
      }

      if (!this.placesAutocomplete) {
        this.placesAutocomplete = new google.maps.places.AutocompleteService()
      }
    }
  }

  getCountryFromPlace = (place?: Place): AddressComponent | undefined => {
    if (!place?.address_components?.length) {
      return undefined
    }

    return place.address_components.find(x => x.types.includes('country'))
  }

  getCoordinatesFromPlace = (place?: Place): Coordinates => {
    if (!place) {
      return {}
    }

    return {
      lat: place.geometry?.location?.lat() || 0,
      lng: place.geometry?.location?.lng() || 0,
    }
  }

  fetchPlace = (
    placeId: string,
    onSuccess: (place: Place | null) => void,
    onError?: (status: string) => void
  ) => {
    if (!this.placesService) {
      return
    }

    try {
      this.placesService.getDetails(
        { placeId },
        (result: Place | null, status: string) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            onSuccess(result)
          } else {
            onError && onError(status)
          }
        }
      )
    } catch (err) {
      captureError(err, 'Error while fetching place - util/maps/index.ts')
    }
  }

  fetchPredictions = (
    input: string,
    onSuccess: (arr: Prediction[] | null) => void,
    onError?: (status: string) => void
  ) => {
    if (!this.placesAutocomplete) {
      if (process.env.NODE_ENV === 'test') {
        return onSuccess([])
      }

      return
    }

    try {
      this.placesAutocomplete.getPlacePredictions(
        {
          input,
          types: ['locality', 'route', 'street_number'],
        },
        (results: Prediction[] | null, status: string) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            onSuccess(results)
          } else {
            onError && onError(status)
          }
        }
      )
    } catch (err) {
      captureError(
        err,
        'Error while fetching place predictions - util/maps/index.ts'
      )
      if (onError && isStringable(err)) {
        onError && onError(err.toString())
      }
    }
  }

  fetchPredictionsDebounced = debounce(this.fetchPredictions, 1000)
}
