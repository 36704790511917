import React, { ReactNode } from 'react'
import { Typography } from '@mui/material'
import CheckboxInput from '../input/CheckboxInput'
import * as S from './styled'

type Props = {
  title: string
  active: boolean
  onChange: () => void
  children?: ReactNode
  className?: string
  testId?: string
  checkboxValue?: string
  checkboxChecked?: boolean
  checkboxAction?: (checked: boolean, value: string) => void
}

const Panel = ({
  title,
  active,
  onChange,
  children,
  className,
  testId,
  checkboxValue = '',
  checkboxChecked = false,
  checkboxAction,
}: Props): JSX.Element => {
  const handleCheckboxClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation()

    if (checkboxAction) {
      checkboxAction(!checkboxChecked, checkboxValue)
    }
  }

  return (
    <S.Container
      square
      elevation={0}
      expanded={active}
      onChange={onChange}
      className={className}
      data-testid={testId || 'panel'}
    >
      <S.Summary expandIcon={<S.Chevron />}>
        <S.Wrapper>
          <Typography variant="subtitle2">{title}</Typography>
          {checkboxAction ? (
            <S.CheckboxWrapper onClick={e => handleCheckboxClick(e)}>
              <CheckboxInput value={checkboxValue} checked={checkboxChecked} />
            </S.CheckboxWrapper>
          ) : null}
        </S.Wrapper>
      </S.Summary>

      <S.Details>{children}</S.Details>
    </S.Container>
  )
}

export default Panel
