import React from 'react'
import { Typography, Avatar } from '@mui/material'
import * as S from './styled'

type Props = {
  comment: string | JSX.Element
}

const CommentWithAvatar = ({ comment }: Props): JSX.Element => (
  <S.CommentContainer>
    <Avatar />
    <S.CommentBox>
      <Typography color="textSecondary">{comment}</Typography>
    </S.CommentBox>
  </S.CommentContainer>
)

export default CommentWithAvatar
