import { Button as MuiButton } from '@mui/material'
import { styled } from '@mui/system'
import {
  themeColor,
  themeSpacing,
  themeTypography,
  themeBorderRadius,
} from '../../helpers/themeHelpers'
import LoadingSpinner from '../LoadingSpinner'

export const ButtonTextWrap = styled('span')`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`

export const StyledLoadingSpinner = styled(LoadingSpinner)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const StyledButton = styled(MuiButton)`
  &,
  &:link,
  &:visited {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    border: none;
    box-shadow: none;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    display: inline-flex;
    flex-wrap: wrap;
    font-size: ${themeTypography('button', 'fontSize')};
    font-weight: ${themeTypography('button', 'fontWeight')};
    justify-content: center;
    line-height: ${themeTypography('button', 'lineHeight')};
    max-width: 100%;
    min-width: ${themeSpacing(12.5)};
    min-height: calc(
      ${themeTypography('button', 'lineHeight')} + (${themeSpacing(2)} * 2)
    );
    outline: 0;
    padding: ${themeSpacing(2)} ${themeSpacing(2)};
    pointer-events: ${props => (props.disabled ? 'none' : 'initial')};
    text-align: center;
    text-shadow: none;
    text-transform: ${themeTypography('button', 'textTransform')};
    user-select: none;
    vertical-align: baseline;
    white-space: nowrap;

    @media screen and (max-width: 400px) {
      font-size: 14px;
    }

    i {
      line-height: 0;
    }

    & > svg {
      flex-basis: 1em;
      flex-shrink: 0;
      margin-right: ${themeSpacing(2)};
      position: relative;
      height: 1.2em;
      width: 1.2em;

      &:last-child {
        margin-right: 0;
      }
    }

    &.has-spinner {
      overflow: hidden;
      pointer-events: none;
      position: relative;
      white-space: nowrap;

      ${ButtonTextWrap} {
        color: transparent;
      }
    }

    small {
      flex-basis: 100%;
      font-size: ${themeTypography('smallText', 'fontSize')};
      font-weight: ${themeTypography('smallText', 'fontWeight')};
      margin-top: 5px;
      width: 100%;

      @media screen and (max-width: 400px) {
        font-size: 11px;
      }

      &:empty {
        display: none;
      }
    }

    &.small {
      font-size: ${themeTypography('smallText', 'fontSize')};
      height: ${themeSpacing(4)};
      line-height: 0;
      min-height: auto;
      min-width: ${themeSpacing(8)};

      svg {
        margin-right: ${themeSpacing(1)};

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &.tiny {
      border-radius: ${themeBorderRadius('tiny')};
      font-size: ${themeTypography('smallText', 'fontSize')};
      line-height: 0;
      min-height: ${themeSpacing(3)};
      min-width: ${themeSpacing(4)};
      padding: 0 7px;

      svg {
        margin-right: ${themeSpacing(1)};

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &.default {
      background: ${props =>
        props.disabled
          ? themeColor('disabled', 'main')
          : themeColor('secondary', 'pale')};
      color: ${props =>
        props.disabled
          ? themeColor('disabled', 'contrastText')
          : themeColor('secondary', 'main')};

      &.on-grey {
        background: ${props =>
          props.disabled
            ? themeColor('disabled', 'main')
            : themeColor('secondary', 'veryLight')};

        &:hover,
        &.active {
          background-color: ${themeColor('secondary', 'lighter')};
        }
      }

      svg {
        fill: ${props =>
          props.disabled
            ? themeColor('disabled', 'contrastText')
            : themeColor('secondary', 'main')};
      }

      &:hover,
      &.active {
        background-color: ${themeColor('secondary', 'veryLight')};
      }

      &:active {
        background-color: ${themeColor('secondary', 'lighter')};
      }
    }

    &.primary {
      background: ${props =>
        props.disabled
          ? themeColor('disabled', 'main')
          : themeColor('primary', 'main')};
      color: ${props =>
        props.disabled
          ? themeColor('disabled', 'contrastText')
          : themeColor('primary', 'contrastText')};

      svg {
        fill: ${props =>
          props.disabled
            ? themeColor('disabled', 'contrastText')
            : themeColor('primary', 'contrastText')};
      }

      &:hover,
      &.active {
        background-color: ${themeColor('primary', 'light')};
        color: ${themeColor('primary', 'contrastText')};
      }

      &:active {
        background-color: ${themeColor('primary', 'lighter')};
      }
    }

    &.secondary {
      background: ${props =>
        props.disabled
          ? themeColor('disabled', 'main')
          : themeColor('error', 'main')};
      color: ${props =>
        props.disabled
          ? themeColor('disabled', 'contrastText')
          : themeColor('error', 'contrastText')};

      svg {
        fill: ${props =>
          props.disabled
            ? themeColor('disabled', 'contrastText')
            : themeColor('error', 'contrastText')};
      }

      &:hover,
      &.active {
        background-color: ${themeColor('error', 'lighter')};
      }

      &:active {
        background-color: ${themeColor('error', 'light')};
      }
    }
  }

  &:hover,
  &:active {
    box-shadow: none;
  }
`
