import React from 'react'
import { Button } from '../index'
import ModalBody from './ModalBody'
import ModalHeader from './ModalHeader'
import ModalActions from './ModalActions'
import { styled } from '@mui/system'
import { themeColor } from '../../helpers/themeHelpers'
import { Typography } from '@mui/material'
import { ButtonProps } from '@mui/material/Button'

type Props = {
  bodyText?: string | JSX.Element
  confirmButtonAction: () => void
  confirmButtonStyle?: ButtonProps['color']
  confirmButtonText?: string
  confirmLoading?: boolean
  denyButtonAction?: () => void
  denyButtonText?: string
  headerText?: string | JSX.Element
}

const ConfirmDialog = ({
  bodyText = '',
  confirmButtonAction,
  confirmButtonStyle = 'primary',
  confirmButtonText = 'Okay',
  confirmLoading,
  denyButtonAction,
  denyButtonText = 'Cancel',
  headerText = 'Are you sure?',
}: Props): JSX.Element => {
  const hasTextElement = textElement => {
    if (!textElement) return false
    if (typeof textElement === 'string' && textElement.length === 0)
      return false
    return true
  }

  return (
    <>
      {hasTextElement(headerText) && (
        <ModalHeader data-testid="dialog-header">{headerText}</ModalHeader>
      )}

      <StyledModalBody data-testid="confirm-dialog">
        {hasTextElement(bodyText) && (
          <DialogBody data-testid="dialog-body" variant="body1">
            {bodyText}
          </DialogBody>
        )}
      </StyledModalBody>

      <ModalActions>
        <Button
          testId="confirm-button"
          loading={confirmLoading}
          onClick={confirmButtonAction}
          color={confirmButtonStyle}
        >
          {confirmButtonText}
        </Button>

        {denyButtonAction && (
          <Button testId="deny-button" onClick={denyButtonAction}>
            {denyButtonText}
          </Button>
        )}
      </ModalActions>
    </>
  )
}

const StyledModalBody = styled(ModalBody)`
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
`

const DialogBody = styled(Typography)`
  color: ${themeColor('secondary', 'light')};
`

export default ConfirmDialog
