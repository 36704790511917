import * as Sentry from '@sentry/react'
import { Context } from '@sentry/types'
import { ApiError, ApiRequestError } from 'types'
import { isStringable } from 'util/helpers'

export const captureError = (error: unknown, title: string): void => {
  Sentry.captureException(getError(error), scope => {
    scope.setTransactionName(title)
    scope.setContext('extra-data', error as Context)

    return scope
  })
}

export const getError = (error: unknown): Error => {
  if (!error) {
    return new Error('Unknown Error')
  } else if (error instanceof Error) {
    return error
  } else if (typeof error === 'string') {
    return new Error(error)
  } else if (typeof error === 'object') {
    if (
      (error as ApiError)?.data?.message &&
      typeof (error as ApiError)?.data?.message === 'string'
    ) {
      return Error((error as ApiError)?.data?.message)
    } else if (
      (error as ApiError)?.data?.msg &&
      typeof (error as ApiError)?.data?.msg === 'string'
    ) {
      return Error((error as ApiError)?.data?.msg)
    } else if (
      (error as ApiError)?.data?.error &&
      typeof (error as ApiError)?.data?.error === 'string'
    ) {
      return Error((error as ApiError)?.data?.error)
    } else if (
      (error as ApiError).message &&
      typeof (error as ApiError).message === 'string'
    ) {
      return Error((error as ApiError).message)
    }
    return new Error('Unknown Error')
  }

  return new Error('Unknown Error')
}

export const getErrorMsg = (
  error: unknown,
  customErrorMsg?: string
): string | undefined => {
  if (error === null || error === undefined) {
    return customErrorMsg || 'errors.requests.generic'
  }

  if (typeof error === 'object') {
    if (
      (error as ApiError)?.data?.message &&
      typeof (error as ApiError)?.data?.message === 'string'
    ) {
      return (error as ApiError)?.data?.message
    } else if (
      (error as ApiError)?.data?.msg &&
      typeof (error as ApiError)?.data?.msg === 'string'
    ) {
      return (error as ApiError)?.data?.msg
    } else if (
      (error as ApiError)?.data?.error &&
      typeof (error as ApiError)?.data?.error === 'string'
    ) {
      return (error as ApiError)?.data?.error
    } else if (
      (error as ApiError).message &&
      typeof (error as ApiError).message === 'string'
    ) {
      return (error as ApiError).message
    } else if (
      (error as ApiRequestError).message &&
      (error as ApiRequestError).message.message &&
      typeof (error as ApiRequestError).message.message === 'string'
    ) {
      return (error as ApiRequestError).message.message
    } else if (
      (error as ApiRequestError) &&
      (error as ApiRequestError).message?.error &&
      typeof (error as ApiRequestError).message?.error === 'string'
    ) {
      return (error as ApiRequestError).message?.error
    }
    return customErrorMsg || 'errors.requests.generic'
  } else if (
    error instanceof Error &&
    error.message &&
    typeof error.message === 'string'
  ) {
    return error.message
  } else if (typeof error === 'string') {
    return error
  } else if (
    (error as ApiError) &&
    (error as ApiError).error &&
    typeof (error as ApiError).error === 'string'
  ) {
    return (error as ApiError).error
  } else if (
    (error as ApiRequestError) &&
    (error as ApiRequestError).message?.error &&
    typeof (error as ApiRequestError).message?.error === 'string'
  ) {
    return (error as ApiRequestError).message?.error
  }

  return customErrorMsg || 'errors.requests.generic'
}

export const getGeneralErrorString = (
  error: unknown,
  defaultMessage: string
): string => {
  let errorMessage = defaultMessage

  if (error && error !== undefined) {
    if (error instanceof Error) {
      errorMessage = error.message
    } else if (
      isStringable(error) &&
      typeof error !== 'object' &&
      typeof error === 'string'
    ) {
      errorMessage = (error as string).toString()
    } else {
      try {
        errorMessage = JSON.stringify(error, null, 2)
      } catch (e) {
        errorMessage = defaultMessage
      }
    }
  } else {
    errorMessage = defaultMessage
  }

  return errorMessage
}
