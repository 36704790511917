import { styled } from '@mui/system'
import MuiAvatar from '@mui/material/Avatar'
import { themeColor, themeSpacing } from '../../helpers/themeHelpers'
import _MissingAvatar from 'ez-styles/assets/icons/user/user.svg'

export const DefaultAvatar = styled(_MissingAvatar)`
  fill: ${themeColor('secondary', 'light')};
`

export const StyledAvatar = styled(MuiAvatar)`
  &&::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 50%;
  }

  &&.MuiAvatar {
    &-fallback {
      background-color: ${themeColor('secondary', 'lighter')};
      fill: #fff;
    }

    &-colorDefault {
      border: unset;
      background-color: ${themeColor('secondary', 'veryLight')};
    }
  }

  &&.tiny {
    height: ${themeSpacing(3)};
    width: ${themeSpacing(3)};

    ${DefaultAvatar} {
      height: ${themeSpacing(1.5)};
      width: ${themeSpacing(1.5)};
    }
  }

  &&.small {
    height: ${themeSpacing(4)};
    width: ${themeSpacing(4)};

    ${DefaultAvatar} {
      height: ${themeSpacing(2)};
      width: ${themeSpacing(2)};
    }
  }

  &&.medium {
    height: ${themeSpacing(6)};
    width: ${themeSpacing(6)};

    ${DefaultAvatar} {
      height: ${themeSpacing(3.4)};
      width: ${themeSpacing(3.4)};
    }
  }

  &&.large {
    height: ${themeSpacing(8)};
    width: ${themeSpacing(8)};

    ${DefaultAvatar} {
      height: ${themeSpacing(4.5)};
      width: ${themeSpacing(4.5)};
    }
  }

  &&.x-large {
    height: ${themeSpacing(10)};
    width: ${themeSpacing(10)};

    ${DefaultAvatar} {
      height: ${themeSpacing(6)};
      width: ${themeSpacing(6)};
    }
  }

  &&.xx-large {
    height: ${themeSpacing(15)};
    width: ${themeSpacing(15)};

    ${DefaultAvatar} {
      height: ${themeSpacing(8)};
      width: ${themeSpacing(8)};
    }
  }

  img {
    border-radius: 50%;
  }
`
