import { styled } from '@mui/material/styles'
import { themeColor, themeSpacing } from 'eezy-components'

type StyleProps = {
  $marginLeft?: number
  $marginRight?: number
}

export const Container = styled('div')<StyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${themeSpacing(0.5)} ${themeSpacing(1)};
  border-radius: 500vh;
  background-color: ${themeColor('primary', 'pale')};
  color: ${themeColor('primary', 'main')};
  font-weight: 400 !important;
  text-transform: uppercase;
  font-size: ${themeSpacing(1.33)};
  margin-left: ${({ $marginLeft }) => $marginLeft ?? 0}rem;
  margin-right: ${({ $marginRight }) => $marginRight ?? 0}rem;
`
