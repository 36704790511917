type Params = {
  key: $ValueOf<typeof ServiceTypes>
  host?: string
  token?: string
  isDefault?: boolean
}

export const ServiceTypes = Object.freeze({
  CM: 'cm',
  Referrals: 'referrals',
} as const)

export default class Service {
  key: string
  host: string
  token: string
  isDefault: boolean

  constructor(params: Params) {
    this.key = params.key || 'service'
    this.host = params.host || ''
    this.token = params.token || ''
    this.isDefault = params.isDefault || false
  }

  setHost(host: string): void {
    this.host = host
  }

  setToken(token: string): void {
    this.token = token
  }
}
