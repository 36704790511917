import { styled } from '@mui/material/styles'
import {
  LoadingSpinner,
  MenuPopper,
  TextInput,
  themeColor,
  themeSpacing,
} from 'eezy-components'
import { ChangeEvent, FocusEvent, useEffect, useState } from 'react'

import _SearchIcon from 'ez-styles/assets/icons/magnifier.svg'
import _ClearIcon from 'ez-styles/assets/icons/x/x-big.svg'

type Option = {
  description: string
}

type Props<T extends Option> = {
  options: T[]
  loading?: boolean
  label?: string
  placeholder?: string
  onSelect: (option: T) => void
  onInputChange: (value: string) => void
  onInputClear: () => void
  defaultValue?: string
}

const Suggestions = <T extends Option>({
  options,
  loading,
  label,
  placeholder,
  onSelect,
  onInputChange,
  onInputClear,
  defaultValue,
}: Props<T>): JSX.Element => {
  const [query, setQuery] = useState(defaultValue || '')
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined)
  const open = Boolean(anchorEl && options?.length)

  useEffect(() => {
    if (defaultValue) {
      setQuery(defaultValue)
    }
  }, [defaultValue])

  const onOpen = (ev: FocusEvent<HTMLElement>) => setAnchorEl(ev.currentTarget)
  const onClose = () => setAnchorEl(undefined)

  const onClear = () => {
    setQuery('')
    onInputClear && onInputClear()
  }

  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setQuery(ev.target.value)
    onInputChange(ev.target.value)
  }

  const onSelectOption = (option: T) => {
    onSelect(option)
    setQuery(option.description)
  }

  const endAdornment = loading ? (
    <StyledLoadingSpinner color="inherit" size={15} />
  ) : query.length ? (
    <ClearIcon onClick={onClear} />
  ) : (
    <SearchIcon />
  )

  return (
    <>
      <StyledTextInput
        fullWidth
        data-testid="suggestions-input"
        value={query}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        onClear={onClear}
        onFocus={onOpen}
        onBlur={onClose}
        endAdornment={endAdornment}
      />

      <StyledMenu
        open={open}
        handleClose={() => {}}
        anchorEl={anchorEl}
        menuWidth={300}
      >
        {options.map((option: T, idx) => (
          <Option key={idx} onMouseDown={() => onSelectOption(option)}>
            {option.description}
          </Option>
        ))}
      </StyledMenu>
    </>
  )
}

export default Suggestions

const StyledTextInput = styled(TextInput)`
  & .MuiOutlinedInput-input {
    padding-right: ${themeSpacing(5)};
  }
`

const StyledMenu = styled(MenuPopper)<{
  menuWidth: number
}>`
  & > .MuiPaper-root {
    min-width: 0;
    width: ${props => props.menuWidth}px;
  }
`

const Option = styled('div')`
  display: flex;
  align-items: center;
  color: ${themeColor('secondary', 'main')};
  padding: ${themeSpacing(1)} ${themeSpacing(2)};

  &:hover {
    cursor: pointer;
    background-color: ${themeColor('secondary', 'pale')};
  }
`

const StyledLoadingSpinner = styled(LoadingSpinner)`
  position: absolute;
  right: ${themeSpacing(2)};
`

const SearchIcon = styled(_SearchIcon)`
  position: absolute;
  right: ${themeSpacing(2)};
  width: ${themeSpacing(2)};
  height: ${themeSpacing(2)};
  fill: ${themeColor('secondary', 'light')};
`

const ClearIcon = styled(_ClearIcon)`
  position: absolute;
  right: ${themeSpacing(2)};
  width: ${themeSpacing(2)};
  height: ${themeSpacing(1.5)};
  cursor: pointer;

  fill: ${themeColor('secondary', 'light')};

  &:hover {
    fill: ${themeColor('secondary', 'main')};
  }
`
