import React from 'react'

import MuiTypography, { TypographyProps } from '@mui/material/Typography'

type Props = Pick<
  TypographyProps,
  Exclude<keyof TypographyProps, keyof { css; variant }>
> & {
  variant?: any
  component?: keyof HTMLElementTagNameMap
}

const VARIANTS = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'button',
  'caption',
  'overline',
  'srOnly',
  'inherit',
]

const Typography = ({
  variant,
  classes,
  className,
  ...rest
}: Props): JSX.Element => {
  const isCustom = !VARIANTS.includes(variant)
  const classNames = [className]

  if (isCustom) {
    classNames.unshift(classes[variant])
  }

  return (
    <MuiTypography
      className={classNames.join(' ')}
      variant={isCustom ? undefined : variant}
      {...rest}
    />
  )
}

export default Typography
// export default withStyles(({ typography }) =>
//   Object.keys(typography)
//     .filter(key => isObject(typography[key]))
//     .filter(key => !VARIANTS.includes(key))
//     .reduce((acc, key) => ({ ...acc, [key]: typography[key] }), {})
// )(Typography)
