import React, { useState, MouseEvent } from 'react'
import _ArrowSmallDown from 'ez-styles/assets/icons/arrows/arrow-small-down.svg'
import * as S from './styled'

type MenuOption = {
  label: string
  onClick: () => void
}

type Props = {
  label?: string
  options: MenuOption[]
  outlinedButton?: boolean
  testId?: string
  icon?: JSX.Element
  withArrow?: boolean
  className?: string
  disabled?: boolean
}

const ButtonMenu = ({
  label,
  testId,
  options,
  icon,
  withArrow,
  outlinedButton,
  className = '',
  disabled = false,
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (disabled) return
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChildClick = (option: MenuOption) => {
    if (disabled) return
    if (typeof option.onClick === 'function') {
      option.onClick()
    }
    handleClose()
  }

  return (
    <>
      {icon ? (
        <div
          onClick={handleClick}
          data-testid={testId}
          className={open ? `open ${className}` : className}
        >
          {icon}
        </div>
      ) : (
        <S.StyledButton
          testId={testId}
          onClick={handleClick}
          $outlined={outlinedButton}
          className={open ? `open ${className}` : className}
          disabled={disabled}
        >
          <S.StyledLabel>{label}</S.StyledLabel>
          {withArrow ? <S.ArrowSmallDown /> : null}
        </S.StyledButton>
      )}

      <S.StyledMenuPopper
        open={open}
        handleClose={handleClose}
        anchorEl={anchorEl}
        wrapButton={false}
      >
        {options.map((option, idx) => (
          <S.StyledMenuItem
            key={idx}
            onClick={() => handleChildClick(option)}
            disabled={disabled}
          >
            {option.label}
          </S.StyledMenuItem>
        ))}
      </S.StyledMenuPopper>
    </>
  )
}

export default ButtonMenu
