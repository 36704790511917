import { styled } from '@mui/system'
import { Box, MenuItem, Theme } from '@mui/material'
import { Button } from '../index'
import _ArrowSmallDown from 'ez-styles/assets/icons/arrows/arrow-small-down.svg'
import {
  themeBorderRadius,
  themeColor,
  themeSpacing,
  themeTypography,
} from '../../helpers'

export const StyledFilterBar = styled(Box)`
  border-bottom: 1px solid ${themeColor('secondary', 'veryLight')};
  display: flex;
  margin-bottom: ${themeSpacing(5)};
  padding: ${themeSpacing(2)} 0;
`

export const StyledButton = styled(Button)<{
  $outlined: boolean
  $minwidth: number
  theme: Theme
}>`
  border: ${props =>
    props.$outlined
      ? `1px solid ${themeColor('secondary', 'veryLight')(props)}`
      : 'none'};
  font-size: ${themeTypography('mediumText', 'fontSize')};
  font-weight: ${themeTypography('mediumText', 'fontWeight')};
  min-width: ${props => props.$minwidth}px;
  justify-content: flex-start;
  text-align: left;
  flex-wrap: nowrap;

  &.open {
    background: #fff;
  }
`

export const StyledLabel = styled('span')`
  margin-right: ${themeSpacing(2)};
  white-space: nowrap;
`

export const StyledSelectKey = styled('span')`
  display: inline-block;
  margin-right: ${themeSpacing(0.5)};
  text-transform: capitalize;
`

export const StyledGroupKey = styled('span')`
  display: inline-block;
  margin-right: ${themeSpacing(0.5)};
`

export const StyledValue = styled('span')`
  font-weight: ${themeTypography('mediumBoldText', 'fontWeight')};
`

export const ArrowSmallDown = styled(_ArrowSmallDown)`
  && {
    height: 5px;
    flex-basis: auto;
    flex-shrink: 0;
    margin-left: auto;
    margin-right: 0;
    width: ${themeSpacing(1)};
  }
`

export const StyledSelectMenuItem = styled(MenuItem)`
  color: ${themeColor('secondary', 'main')};
  display: flex;
  font-size: ${themeTypography('menuItem', 'fontSize')};
  font-weight: ${themeTypography('menuItem', 'fontWeight')};
  justify-content: space-between;
  height: ${themeSpacing(6)};
  width: 100%;
`

export const StyledGroupMenuItem = styled(MenuItem)<{
  $expanded?: boolean
}>`
  color: ${themeColor('secondary', 'main')};
  display: block;
  font-size: ${themeTypography('menuItem', 'fontSize')};
  font-weight: ${themeTypography('menuItem', 'fontWeight')};
  min-height: ${themeSpacing(6)};
  width: 100%;

  /* stylelint-disable declaration-empty-line-before, value-keyword-case */
  ${props =>
    props.$expanded &&
    `
      &:hover {
        background-color: #fff;
      }
    `}
`

export const MenuItemHeading = styled('div')`
  width: 100%;
  min-height: ${themeSpacing(5)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const MenuItemOptions = styled('div')<{
  $expanded?: boolean
}>`
  width: 100%;
  display: ${props => (props.$expanded ? 'block' : 'none')};
  padding: ${themeSpacing(1)} 0;
  border-radius: ${themeBorderRadius('medium')};
  background-color: ${themeColor('secondary', 'pale')};
`

export const MenuItemGroupOption = styled('div')`
  width: 100%;
  padding: ${themeSpacing(0.5)} ${themeSpacing(1)};

  &:hover {
    background-color: ${themeColor('secondary', 'veryLight')};
  }
`

export const ExpandButton = styled('div')`
  width: ${themeSpacing(3)};
  margin-left: ${themeSpacing(1.5)};
  font-size: ${themeTypography('largeText', 'fontSize')};
  text-align: center;
`
