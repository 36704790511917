import React, { forwardRef, ReactNode, useState } from 'react'
import { OutlinedInput, OutlinedInputProps } from '@mui/material'
import InputWrapper, { InputWrapperProps } from '../InputWrapper'
import _HideIcon from 'ez-styles/assets/icons/eye-crossed.svg'
import _ShowIcon from 'ez-styles/assets/icons/eye.svg'
import * as S from './styled'

type Props = OutlinedInputProps & {
  className?: string
  fullWidth?: boolean
  label?: string
  labelAsPlaceholder?: boolean
  value?: string
  onChange?: (event) => void
  required?: boolean
  size?: number | string
  labelShrink?: boolean
  labelCaption?: string
  tooltipContent?: ReactNode
  helperText?: string
  endAdornment?: ReactNode
}

type InputType = 'password' | 'text'

const PasswordInput = forwardRef(
  (
    {
      className,
      fullWidth,
      label,
      labelCaption,
      labelShrink,
      labelAsPlaceholder,
      onChange,
      required,
      size,
      value,
      helperText,
      tooltipContent,
      endAdornment,
      ...inputProps
    }: Props,
    ref
  ): JSX.Element => {
    const inputWrapperProps: InputWrapperProps = {
      fullWidth,
      label,
      labelCaption,
      labelShrink,
      labelAsPlaceholder,
      helperText,
      tooltipContent,
      required,
    }

    const [inputType, setInputType] = useState<InputType>('password')

    const hideShowPassword = event => {
      event.preventDefault()
      event.stopPropagation()
      setInputType(inputType === 'text' ? 'password' : 'text')
    }

    const passControlTitle =
      inputType === 'text' ? 'Hide Password' : 'Show Password'
    const passControlIcon =
      inputType === 'text' ? <S.HideIcon /> : <S.ShowIcon />

    return (
      <InputWrapper className={className} {...inputWrapperProps}>
        <OutlinedInput
          id={`${label}-input-label`}
          onChange={onChange}
          value={value}
          required={required}
          ref={ref}
          data-testid="password-input"
          endAdornment={
            <>
              <S.HideShowControl
                transparentBg
                title={passControlTitle}
                onClick={hideShowPassword}
              >
                {passControlIcon}
              </S.HideShowControl>

              {endAdornment}
            </>
          }
          inputProps={{ size }}
          {...inputProps}
          type={inputType}
        />
      </InputWrapper>
    )
  }
)

PasswordInput.displayName = 'PasswordInput'

export default PasswordInput
