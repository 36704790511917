import React, { useMemo, ReactNode } from 'react'
import RadioInput from '../RadioInput'
import * as S from './styled'

type Props = {
  children?: ReactNode
  value: string
  selectedValue: string
  handleChange: (value: string) => void
  label: string
  message?: ReactNode
}

const RadioAdvanced = ({
  children,
  value,
  selectedValue,
  handleChange,
  label,
  message,
}: Props) => {
  const selected = useMemo(
    () => value === selectedValue,
    [value, selectedValue]
  )

  return (
    <div>
      <S.Container
        $isSelected={selected}
        onClick={() => handleChange(value)}
        data-testid="radio-advanced-container"
      >
        <S.InputActionWrapper>
          <RadioInput
            value={value}
            checked={selected}
            onChange={() => handleChange(value)}
            label={label}
            className="radio-label"
          />
          {children ? (
            <S.ContentContainer>{children}</S.ContentContainer>
          ) : null}
        </S.InputActionWrapper>
        {message ? <S.MessageContainer>{message}</S.MessageContainer> : null}
      </S.Container>
    </div>
  )
}

export default RadioAdvanced
