import React, { forwardRef, ForwardedRef, useState } from 'react'
import { Paper, Typography } from '@mui/material'
import TextInput from '../TextInput'
import * as S from './styled'

type Option = {
  label: string
  value: string
}

type Props = {
  className?: string
  fullWidth?: boolean
  options: Option[]
  placeholder?: string
  value?: Option
  onChange?: (option: Option) => void
  otherValue?: string
  otherPlaceholder?: string
  isOtherSelected?: boolean
  onOtherChange?: (event) => void
  saveOther?: () => void
  disableClearable?: boolean
}

function AutocompleteInput(
  {
    className,
    options,
    placeholder,
    value,
    fullWidth,
    onChange,
    otherValue,
    otherPlaceholder,
    isOtherSelected,
    onOtherChange,
    saveOther,
    disableClearable,
    ...autocompleteProps
  }: Props,
  ref: ForwardedRef<unknown>
): JSX.Element {
  const [other, setOther] = useState<string>(otherValue || '')
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const handleOtherChange = event => {
    const { value } = event.target
    setOther(value)

    if (onOtherChange) onOtherChange(event)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (other && onOtherChange) {
        if (saveOther) {
          saveOther()
        }
        closeMenu()
      }
    }
  }

  const handleChange = option => {
    const value = option?.value

    if (value !== 'other') {
      setMenuOpen(false)
    }

    if (onChange) onChange(option)
  }

  const closeMenu = (event?) => {
    if (event) {
      event.stopPropagation()
      const role = event.target.getAttribute('role')
      const value = String(event.target.getAttribute('value') || '')
      if ((role === 'combobox' && value.toLowerCase() !== 'other') || !role) {
        setMenuOpen(false)
      }
    } else {
      setMenuOpen(false)
    }
  }

  return (
    <S.StyledAutocomplete
      ref={ref}
      open={menuOpen}
      onOpen={() => setMenuOpen(true)}
      onClose={closeMenu}
      className={className}
      options={options}
      value={value || null}
      onChange={(e, option: Option) => handleChange(option)}
      renderInput={params => (
        <S.StyledTextField {...params} placeholder={placeholder} />
      )}
      getOptionLabel={(option: Option) => option.label || ''}
      renderOption={(props, option: Option) => (
        <S.OptionWrapper {...props}>
          <Typography>{option.label}</Typography>
        </S.OptionWrapper>
      )}
      isOptionEqualToValue={(option: Option, value: Option) =>
        option.value === value.value
      }
      PaperComponent={({ children }) => {
        return (
          <Paper>
            {children}
            {isOtherSelected ? (
              <S.TextInputWrapper onKeyDown={handleKeyDown}>
                <TextInput
                  fullWidth
                  value={other || ''}
                  autoFocus
                  placeholder={otherPlaceholder}
                  onChange={handleOtherChange}
                  data-testid="other-text-input"
                />
              </S.TextInputWrapper>
            ) : null}
          </Paper>
        )
      }}
      fullWidth={fullWidth}
      disableClearable={disableClearable}
      {...autocompleteProps}
    />
  )
}

export default forwardRef(AutocompleteInput)
