import React from 'react'
import { AvatarProps } from '@mui/material/Avatar'
import _MissingAvatar from 'ez-styles/assets/icons/user/user.svg'
import * as S from './styled'

type AvatarSize = 'tiny' | 'small' | 'medium' | 'large' | 'x-large' | 'xx-large'

type Props = AvatarProps & {
  component?: string
  href?: string
  size?: AvatarSize
  className?: string
  alt?: string
}
// TODO: CM-2527 - this component is triggering a full page load
const Avatar = ({
  size = 'medium',
  className = '',
  ...avatarProps
}: Props): JSX.Element => (
  <S.StyledAvatar
    data-testid="avatar"
    className={`${className} ${size}`}
    {...avatarProps}
  >
    <S.DefaultAvatar />
  </S.StyledAvatar>
)

export default Avatar
