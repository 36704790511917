import React from 'react'
import { DIInstances } from './'
import DIContainer from './Container'
import { Provider } from './context'

type Props = {
  container: DIContainer<DIInstances>
  children: React.ReactNode
}

export default ({ container, children }: Props): JSX.Element => (
  <Provider value={container}>{children}</Provider>
)
