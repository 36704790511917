import { Context } from '.'

type Condition = (context: Context) => boolean

export default class Popup {
  context: Context
  conditions: Condition[]

  constructor(context?: Context) {
    this.context = context || {}
    this.conditions = []
  }

  addCondition(fn: Condition): void {
    this.conditions.push(fn)
  }

  canRender(): boolean {
    for (const fn of this.conditions) {
      if (!fn(this.context)) {
        return false
      }
    }

    return true
  }
}
