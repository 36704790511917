import { styled } from '@mui/system'
import { themeSpacing, themeColor } from '../../helpers/themeHelpers'
import { Theme } from '@mui/material'

export const Container = styled('div')<{ theme: Theme; $disabled: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${themeSpacing(3)};
  opacity: ${props => (props.$disabled ? 0.8 : 1)};
  pointer-events: ${props => (props.$disabled ? 'none' : 'auto')};
`

export const Prefix = styled('label')<{ theme: Theme; $active: boolean }>`
  color: ${props =>
    props.$active ? themeColor('secondary', 'main') : 'inherit'};
  margin-right: ${themeSpacing(1)};
`

export const Suffix = styled('label')<{ theme: Theme; $active: boolean }>`
  color: ${props =>
    props.$active ? themeColor('secondary', 'main') : 'inherit'};
  margin-left: ${themeSpacing(1)};
`

export const InputControl = styled('section')<{
  theme: Theme
  $active: boolean
}>`
  width: 100%;
  height: 100%;
  padding: 3px;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${themeColor('primary', 'main')};
  border-radius: ${themeSpacing(3)};
  background-color: ${themeColor('primary', 'main')};
  transition: all 0.4s ease;

  &:active {
    box-shadow: ${props =>
      props.$active
        ? 'none'
        : `inset 0 0 0 2em ${themeColor('secondary', 'lighter')(props)}`};

    &::after {
      margin-left: ${props =>
        props.$active ? `-${themeSpacing(1)(props)}` : ''};
      padding-right: ${themeSpacing(1)};
    }
  }

  &::after {
    content: '';
    display: block;
    position: relative;
    left: ${props => (props.$active ? '50%' : 0)};
    width: 47%;
    height: 100%;
    border-radius: ${themeSpacing(3)};
    background-color: #fff;
    box-sizing: initial;

    /* prettier-ignore */
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  }
`
