import { styled } from '@mui/system'
import { MenuItem } from '@mui/material'

import {
  themeColor,
  themeSpacing,
  themeTypography,
} from '../../../helpers/themeHelpers'

export const StyledMenuItem = styled(MenuItem)`
  color: ${themeColor('secondary', 'main')};
  display: flex;
  font-size: ${themeTypography('menuItem', 'fontSize')};
  font-weight: ${themeTypography('menuItem', 'fontWeight')};
  min-width: ${themeSpacing(30)};
  justify-content: space-between;
  height: ${themeSpacing(6)};

  &:hover {
    .MuiChip-root {
      background-color: ${themeColor('secondary', 'veryLight')};
    }
  }
`
