import { Box, Link as MUILink, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  TextInput,
  themeBorderRadius,
  themeBoxShadow,
  themeColor,
  themeSpacing,
  themeTypography,
} from 'eezy-components'
import { MobileProps } from 'helpers/hooks'

export const CenteredWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin: 0;
`

export const Heading = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const Header = styled(Typography)`
  margin-bottom: ${themeSpacing(1)};
`

export const SubHeader = styled(Typography)`
  margin-top: ${themeSpacing(1)};
  text-align: center;
`

export const Container = styled(Box)<MobileProps>`
  background-color: #fff;
  border-radius: ${themeBorderRadius('large')};
  box-shadow: ${themeBoxShadow('popper')};
  width: ${props => (props.$isMobile ? '100%' : '40%')};
  margin: ${themeSpacing(2)};

  .secondary-link {
    color: ${themeColor('secondary', 'main')};
    font-size: ${themeTypography('mediumText', 'fontSize')};

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
`

export const FormContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${themeSpacing(0.5)};
  width: 100%;
`

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: ${themeSpacing(1)};
  width: ${(props: MobileProps) => (props.$isMobile ? '100%' : '75%')};
  height: ${themeSpacing(6)};

  input {
    height: ${themeSpacing(2.825)};
  }
`

export const Link = styled(MUILink)`
  text-decoration-color: ${themeColor('secondary', 'main')};
`

export const StyledTextInput = styled(TextInput)`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    display: none;
  }
`

export const InputWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing(2)};
`

export const TextWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${themeSpacing(0.5)};
  width: 100%;
`
