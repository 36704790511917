import { styled } from '@mui/material/styles'
import { themeSpacing, Typography } from 'eezy-components'

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`

export const Title = styled(Typography)`
  margin-bottom: ${themeSpacing(2)};
`
