export const THRESHOLD_VALUE = 25
export const CLOSED_THRESHOLD_VALUE = 7

export const thresholdValue = (isClosedAccount: boolean) => {
  return isClosedAccount ? CLOSED_THRESHOLD_VALUE : THRESHOLD_VALUE
}

export enum PaymentMethods {
  PayPal = 'paypal',
  Payoneer = 'payoneer',
}
