import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Routes } from 'constants/index'
import {
  Button,
  themeBorderRadius,
  themeBoxShadow,
  themeColor,
  themeSpacing,
} from 'eezy-components'
import _CloseIcon from 'ez-styles/assets/icons/x/x-small.svg'
import cookie from 'js-cookie'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Countdown from 'util/countdown'
import _HeroImage from './referrals-hero2x.png'

type Props = {
  countdown: Countdown
}

/**
 * Interpolated translations:
 *
 * i18n-tasks-use t('referrals.toast.message')
 */
export const ReferralsToast = ({ countdown }: Props): JSX.Element | null => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [open, setOpen] = useState(!cookie.get('referrals-toast'))

  const onClick = () => {
    onClose()

    navigate(Routes.Referrals)
  }

  const onClose = () => {
    cookie.set('referrals-toast', 'true', { expires: 1 })
    setOpen(false)
  }

  if (!open) {
    return null
  }

  return (
    <Container data-testid="referrals-toast">
      <CloseIcon onClick={onClose} />
      <Image src={_HeroImage} />

      <Body>
        <Typography variant="h6">{t('referrals.toast.title')}</Typography>
        <Msg>
          <Trans
            i18nKey="referrals.toast.message"
            components={{ s: <s />, b: <strong /> }}
          />
        </Msg>
        <Msg>
          {t('referrals.toast.countdown', {
            date: Countdown.parse(countdown.duration),
          })}
        </Msg>

        <Button color="primary" onClick={onClick}>
          {t('referrals.toast.refer_a_friend')}
        </Button>
      </Body>
    </Container>
  )
}

export default observer(ReferralsToast)

const Container = styled('div')`
  position: fixed;
  right: ${themeSpacing(2)};
  bottom: ${themeSpacing(4)};
  width: ${themeSpacing(40)};
  border-radius: ${themeBorderRadius('small')};
  box-shadow: ${themeBoxShadow('popper')};
  background-color: #fff;
  overflow: hidden;
  z-index: 99;
`

const Body = styled('div')`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: ${themeSpacing(2)};
  padding: ${themeSpacing(3)};
`

const Msg = styled(Typography)`
  & > strong {
    font-weight: 600;
  }
`

export const Image = styled('div')<{
  src?: string
}>`
  width: 100%;
  height: ${themeSpacing(22)};
  background-image: url('${props => props.src}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

export const CloseIcon = styled(_CloseIcon)`
  position: absolute;
  top: ${themeSpacing(2)};
  right: ${themeSpacing(2)};
  fill: ${themeColor('secondary', 'light')};
  cursor: pointer;
  width: ${themeSpacing(1.5)};
  height: ${themeSpacing(1.5)};

  &:hover {
    fill: ${themeColor('secondary', 'main')};
  }
`
