import React from 'react'
import { DIInstances } from '.'
import DIContainer from './Container'

const context: React.Context<DIContainer<DIInstances>> = React.createContext(
  new DIContainer<DIInstances>({ instances: { nacl: false }, stores: {} })
)
const { Provider, Consumer } = context

export { context, Provider, Consumer }
