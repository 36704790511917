import { styled } from '@mui/material/styles'
import { themeColor, themeSpacing } from 'eezy-components'

export const MobileMenu = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${themeSpacing(8)};
  background-color: #fff;
`

export const LogoWrapper = styled('div')`
  margin-left: ${themeSpacing(2)};

  svg {
    width: ${themeSpacing(5)};
    height: ${themeSpacing(5)};
  }

  &:hover {
    cursor: pointer;
  }
`

export const ActionsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${themeSpacing(1)};
`

export const NavIconWrapper = styled('div')`
  width: ${themeSpacing(2.75)};
  height: ${themeSpacing(2.75)};
  cursor: pointer;
  margin: 0 ${themeSpacing(1.5)};

  svg {
    fill: ${themeColor('secondary', 'main')};
    height: 100%;
    width: 100%;
  }
`
