import { Theme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { css } from 'styled-components'

// What's the difference between this and useCallback? $FixMe
export const useAfterEffect = (fn: () => void, deps: unknown[]): void => {
  const mountStatus = useRef(false)

  useEffect(() => {
    if (mountStatus.current) {
      fn()
    } else {
      mountStatus.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export const useViewport = (): { isMobile: boolean } => {
  const [isMobile, setIsMobile] = useState(false)
  const [isMobileSize, setIsMobileSize] = useState(window.innerWidth <= 1080)

  useEffect(() => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : window.navigator.userAgent
    const mobileRegex =
      /Mobile|Android|webOS|iPhone|iPad|iPod|huawei|BlackBerry|IEMobile|Opera Mini/i
    setIsMobile(mobileRegex.test(userAgent))
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsMobileSize(window.innerWidth <= 1080)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { isMobile: isMobile || isMobileSize }
}

export type MobileProps = {
  $isMobile?: boolean
}
type ThemePropsFn = (props: { theme: Theme }) => string
type ThemePropsMobile = MobileProps & {
  theme: Theme
}

export const mobileStyles =
  (...args: [TemplateStringsArray, ThemePropsFn?]) =>
  ({ $isMobile }: ThemePropsMobile) =>
    $isMobile ? css(...args) : ''
