import React from 'react'
import * as S from './styled'

type Props = {
  className?: string
  children: React.ReactNode | React.ReactNode[]
}

const ListBlock = ({ children, className }: Props): JSX.Element => (
  <S.List className={className}>{children}</S.List>
)

export default ListBlock
