import { styled } from '@mui/material/styles'
import { Button, themeSpacing } from 'eezy-components'

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  width: 100%;

  h5 {
    margin-bottom: ${themeSpacing(1)};
  }
`

export const ResetButton = styled(Button)`
  margin-top: ${themeSpacing(2)};
`
