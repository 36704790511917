import React from 'react'
import { styled } from '@mui/system'
import { Theme, Typography } from '@mui/material'
import { themeColor, themeSpacing } from '../../helpers'
import { useViewport } from '../../hooks'

type Props = {
  children?: React.ReactNode | React.ReactNode[]
}

const ModalHeader = ({ children, ...rest }: Props): JSX.Element => {
  const { isMobile } = useViewport()

  return (
    <Wrapper
      data-testid="modal-header"
      variant={isMobile ? 'mediumBoldText' : 'h4'}
      $isMobile={isMobile}
      id={'modal-header'}
      {...rest}
    >
      {children}
    </Wrapper>
  )
}

const Wrapper = styled(Typography)<{
  theme: Theme
  $isMobile: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${themeSpacing(3)};
  width: 100%;
  text-transform: capitalize;
  border-bottom: 1px solid
    ${props =>
      props.$isMobile ? themeColor('secondary', 'veryLight') : 'transparent'};
`

export default ModalHeader
