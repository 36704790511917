import React, {
  useState,
  useEffect,
  useMemo,
  forwardRef,
  ReactNode,
} from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import _ArrowSmallDown from 'ez-styles/assets/icons/arrows/arrow-small-down.svg'
import _Checkmark from 'ez-styles/assets/icons/checkmarks/checkmark-small.svg'
import InputWrapper, { InputWrapperProps } from '../InputWrapper'
import TextInput from '../TextInput'
import * as S from './styled'

export type SelectOption<T = string> = {
  value: string
  label: T | string
}

type Props<T> = {
  className?: string
  fullWidth?: boolean
  label?: string
  labelColor?: string
  selectOptions: SelectOption<T>[]
  value?: string[]
  otherValue?: string
  onChange?: (event) => void
  onOtherChange?: (event) => void
  labelAsPlaceholder?: boolean
  labelShrink?: boolean
  labelCaption?: string
  placeholder?: string
  otherPlaceholder?: string
  required?: boolean
  tooltipContent?: ReactNode
  helperText?: string
  validate?: (value: string[]) => { valid: boolean; message: string }
  disabled?: boolean
}

function MultiSelect<T = string>(
  {
    className,
    fullWidth,
    label,
    labelColor,
    labelCaption,
    labelShrink,
    helperText,
    labelAsPlaceholder,
    value,
    otherValue,
    selectOptions,
    placeholder,
    otherPlaceholder,
    onChange,
    onOtherChange,
    required,
    tooltipContent,
    validate,
    disabled,
    ...selectProps
  }: Props<T>,
  ref: React.ForwardedRef<HTMLInputElement>
): JSX.Element {
  useEffect(() => {
    validate && setIsValid(validate(value))
  }, [value, validate])

  const [isValid, setIsValid] = useState({ valid: true, message: '' })
  const [selected, setSelected] = useState<string[]>(value || [])
  const [other, setOther] = useState<string>(otherValue || '')
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const options = useMemo(() => {
    if (onOtherChange) {
      const optionsWithOther = [...selectOptions]
      optionsWithOther.push({
        value: 'other',
        label: 'Other',
      })
      return optionsWithOther
    } else {
      return selectOptions
    }
  }, [onOtherChange, selectOptions])
  const otherIndex = options.findIndex(option => option.label === 'Other')
  const otherId = options[otherIndex]?.value

  const inputWrapperProps: InputWrapperProps = {
    fullWidth,
    label,
    labelColor,
    labelCaption,
    labelShrink,
    labelAsPlaceholder,
    helperText,
    tooltipContent,
    required,
    disabled,
    error: isValid.valid !== true,
  }

  const handleChange = event => {
    validate && setIsValid(validate(event.target.value))
    setSelected(event.target.value)

    if (onChange) onChange(event)
  }

  const handleOtherChange = event => {
    const { value } = event.target
    setOther(value)

    if (onOtherChange) onOtherChange(event)
  }

  const closeMenu = () => {
    if (!other && selected.length) {
      const selectedValues = [...selected]
      const otherIndex = selectedValues.indexOf('other')

      if (otherIndex > -1) {
        selectedValues.splice(otherIndex, 1)
      }

      setSelected(selectedValues)
    }
    setMenuOpen(false)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      closeMenu()
    } else {
      event.stopPropagation()
    }
  }

  const renderMenuItem = (option, idx: number) => {
    return (
      <MenuItem key={idx} value={option.value}>
        <S.StyledListItemText
          primary={option.label}
          $selected={selected.indexOf(option.value) > -1}
        />
        {selected.indexOf(option.value) > -1 ? (
          <Checkbox
            checked={selected.indexOf(option.value) > -1}
            checkedIcon={<S.Checkmark />}
          />
        ) : null}
      </MenuItem>
    )
  }

  return (
    <InputWrapper className={className} {...inputWrapperProps}>
      <S.StyledMultiSelect
        multiple
        value={selected}
        input={<OutlinedInput label={label} />}
        renderValue={(selected: T[]) => {
          const mappedSelected = [...selected].map(val => {
            const index = options.findIndex(option => val === option.value)
            return options[index]?.label
          })

          if (mappedSelected.length === 0) {
            return placeholder
          } else if (mappedSelected.includes('Other')) {
            mappedSelected.splice(mappedSelected.indexOf('Other'), 1)
            if (other) {
              mappedSelected.sort().push(`Other: ${other}`)
            }
          } else {
            mappedSelected.sort()
          }

          return mappedSelected.join(', ')
        }}
        labelId={`${label}-input-label`}
        IconComponent={S.ArrowSmallDown}
        inputProps={{ 'data-testid': 'multi-select-input' }}
        displayEmpty={placeholder && !labelAsPlaceholder}
        required={required}
        onChange={handleChange}
        ref={ref}
        open={menuOpen}
        onClose={closeMenu}
        onOpen={() => setMenuOpen(true)}
        MenuProps={{
          sx: {
            '&& .Mui-selected': {
              backgroundColor: 'transparent',
              height: '33px',
              '& .MuiListItemText-primary': {
                color: '#000',
              },
            },
          },
        }}
        {...selectProps}
      >
        {placeholder && !labelAsPlaceholder && (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )}

        {options.map((option, index) => renderMenuItem(option, index))}

        {selected.indexOf(otherId) > -1 && onOtherChange ? (
          <S.TextInputWrapper onKeyDown={handleKeyDown}>
            <TextInput
              fullWidth
              value={other || ''}
              placeholder={otherPlaceholder}
              onChange={handleOtherChange}
              data-testid="other-text-input"
              required
            />
          </S.TextInputWrapper>
        ) : null}
      </S.StyledMultiSelect>

      {validate ? (
        <S.ErrorMessage>{!isValid.valid && isValid.message}</S.ErrorMessage>
      ) : null}
    </InputWrapper>
  )
}

export default forwardRef(MultiSelect)
