import { styled } from '@mui/material/styles'
import { themeBorderRadius, themeColor, themeSpacing } from 'eezy-components'

export const Container = styled('div')`
  display: grid;
  grid-template-columns: auto auto;
  gap: ${themeSpacing(2)};
  padding-bottom: ${themeSpacing(3)};
`

export const LoadingContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${themeSpacing(16)};
  width: 100%;
`

export const MetricsTableHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${themeSpacing(2)};
`

export const MetricsTable = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${themeColor('secondary', 'pale')};
  padding: ${themeSpacing(1.5)};
  border-radius: ${themeBorderRadius('medium')};
`

export const MetricRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${themeColor('grey', '200')};
  padding: ${themeSpacing(1)} 0;
`
