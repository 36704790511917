import * as React from 'react'
import * as S from './styled'

type Props = {
  onClick?(string): void
  dest: string
  testId?: string
  className?: string
}

class NavMenuItem extends React.Component<React.PropsWithChildren<Props>> {
  public render() {
    const { children, dest, onClick, testId, className = '' } = this.props

    return (
      <S.StyledMenuItem
        className={className}
        onClick={() => onClick(dest)}
        data-testid={testId}
      >
        {children}
      </S.StyledMenuItem>
    )
  }
}

export default NavMenuItem
