import { styled } from '@mui/system'
import { themeSpacing } from '../../../../helpers'

export const StyledTagsList = styled('ul')`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-top: ${themeSpacing(0.25)};
  margin: 0;
  gap: ${themeSpacing(1)};
  padding-inline-start: 0;
`
