import { intervalToDuration } from 'date-fns'
import { daysInMonth, daysInYear } from 'helpers/dates'
import { action, makeAutoObservable } from 'mobx'

type Duration = {
  years?: number
  months?: number
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
}

export default class Countdown {
  to: Date | null = null
  duration: Duration = {}

  static parse(duration: Duration): string {
    const result = Object.values(duration).reduce(
      (acc, value) => {
        if (!value || value < 10) {
          acc.push(`0${value}`)
        } else {
          acc.push(value)
        }

        return acc
      },
      [] as (string | number)[]
    )

    return result.join(':')
  }

  constructor(to: Date) {
    makeAutoObservable(this)
    this.to = to
    this.duration = this.getDuration()
    setInterval(() => this.update(), 1000)
  }

  getDuration(): Duration {
    const now = new Date()
    const duration = intervalToDuration({
      start: now,
      end: this.to ?? now,
    })

    return Object.entries(duration).reduce(
      (acc, [key, value]) => {
        if (key === 'years' && value) {
          acc.days += daysInYear(now)
        } else if (key === 'months' && value) {
          acc.days += daysInMonth(now)
        }

        return acc
      },
      {
        days: duration.days || 0,
        hours: duration.hours || 0,
        minutes: duration.minutes || 0,
        seconds: duration.seconds || 0,
      } as Required<Duration>
    )
  }

  @action update(): void {
    this.duration = this.getDuration()
  }
}
