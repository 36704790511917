import { styled } from '@mui/system'
import { css } from 'styled-components'
import { Popper, MenuList, Theme } from '@mui/material'
import { themeBoxShadow, themeSpacing, themeTypography } from '../../../helpers'

export const StyledButtonCopy = styled('span')`
  align-items: center;
  display: flex;
  font-size: ${themeTypography('mediumText', 'fontSize')};
  padding: 0 ${themeSpacing(2)};
  width: 100%;
`

export const StyledMenuList = styled(MenuList)`
  position: relative;
`

export const StyledMenu = styled(Popper)<{
  $wrapbutton: string
  $buttonheight: number
  $menuwidth: number
  theme: Theme
}>`
  z-index: 9999;

  .MuiPaper-root {
    box-shadow: ${themeBoxShadow('popperBorder')}, ${themeBoxShadow('popper')};
    min-width: ${themeSpacing(30)};
    margin-top: -${themeSpacing(1)};
    /* stylelint-disable declaration-empty-line-before, value-keyword-case */
    ${props =>
      props.$wrapbutton === 'true' &&
      css`
        margin-top: -${props.$buttonheight};
        min-width: auto;
        width: ${props.$menuwidth};

        ${StyledMenuList} {
          padding-top: 0;
        }

        ${StyledButtonCopy} {
          height: ${props.$buttonheight};
        }
      `}/* stylelint-enable declaration-empty-line-before, value-keyword-case */
  }
`
