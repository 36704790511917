import { UsersResourceCounts } from 'api'
import ResourceCounter from './ResourceCounter'

import { gevent } from 'helpers/gtag'

import { useNavigate } from 'react-router-dom'
import * as S from './styled'

export type SubNavItem = {
  path: string
  label: string
  count?: (keyof UsersResourceCounts)[]
  admin_count?: number
}

type Props = {
  navItems: SubNavItem[]
  refresh?: boolean
}

const SubNav = ({
  navItems,
  refresh = false,
  ...rest
}: Props): JSX.Element | null => {
  const navigate = useNavigate()
  const handleNav = (path: string) => {
    gevent('navigation', 'Header', { destination_url: path })
    if (refresh) {
      window.location.href = path
    } else {
      navigate(path)
    }
  }

  return (
    <S.Container square elevation={0} data-testid="sub-nav" {...rest}>
      {navItems.map(navItem => (
        <S.StyledNavLink
          key={navItem.path}
          onClick={() => handleNav(navItem.path)}
          to={navItem.path}
          className={({ isActive }: { isActive: boolean }) =>
            isActive ? 'active' : undefined
          }
        >
          {navItem.label}

          {navItem.count ? (
            <ResourceCounter count={navItem.count} />
          ) : navItem.admin_count ? (
            <S.Count>({navItem.admin_count.toLocaleString()})</S.Count>
          ) : null}
        </S.StyledNavLink>
      ))}
    </S.Container>
  )
}

export default SubNav
