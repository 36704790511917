import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import Api, { NestedResponseError } from 'util/api'
import { DIContainer, DIInstances } from 'util/di'
import { FtpImport } from '../api'

export default class FTPStore {
  api: Api
  interval: number | undefined
  batchId: string | undefined

  constructor(container: DIContainer<DIInstances>) {
    makeObservable(this)
    this.api = container.find('api')
    this.interval = undefined
    this.batchId = undefined
  }

  @observable error: string | null = null
  @observable import: FtpImport | undefined = undefined
  @observable loading = true

  @computed get inProgress(): boolean {
    return !!this.import?.status
  }

  @action reset = (): void => {
    this.import = undefined
    this.loading = false
    this.error = null
  }

  @action createBatch = async (assignmentId?: string): Promise<void> => {
    try {
      runInAction(() => {
        this.loading = true
      })
      const batch = await this.api.request('POST', '/api/v1/ftp_imports', {
        body: { assignment_id: assignmentId },
      })

      if (batch?.id) {
        this.batchId = batch.id
        this.initPolling()
      }
      runInAction(() => {
        this.loading = false
        this.import = batch
      })
    } catch (er) {
      const err = er as NestedResponseError
      console.error(err)

      this.error = err.message?.code
        ? err.message.code
        : err.message?.message || null
      runInAction(() => {
        this.loading = false
      })
    }
  }

  @action getLatestImport = async (): Promise<void> => {
    runInAction(() => {
      this.loading = true
    })
    const batches = (await this.api.request(
      'GET',
      '/api/v1/ftp_imports'
    )) as FtpImport[]
    if (batches[0] && batches[0].state !== 'complete') {
      this.import = batches[0]
      this.batchId = this.import.id
      this.initPolling()
    } else {
      this.import = undefined
      this.batchId = undefined
    }
    runInAction(() => {
      this.loading = false
    })
  }

  initPolling = (): void => {
    if (!this.interval) {
      this.interval = window.setInterval(() => this.pollBatches(), 1000)
    }
  }

  pollBatches = async (): Promise<void> => {
    if (!this.batchId || this.import?.state == 'complete') {
      clearInterval(this.interval)
      this.interval = undefined
      return
    }

    const res = (await this.api.request(
      'GET',
      `/api/v1/ftp_imports/${this.batchId}`
    )) as FtpImport

    runInAction(() => {
      this.import = res
    })
  }
}
