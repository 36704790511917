import { usePostApiV1UsersPassword } from 'api'
import LogoHeader from 'components/LogoHeader'
import { Routes } from 'constants/index'
import {
  Box,
  Button,
  Center,
  Stack,
  TextInput,
  Typography,
} from 'eezy-components'
import { useViewport } from 'helpers/hooks'
import { enqueueSnackbar } from 'notistack'
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as S from '../styled'

const ForgotPassword = () => {
  const { t } = useTranslation()
  const { isMobile } = useViewport()

  const [email, setEmail] = useState<string>('')
  const { mutate: resetPassword, loading: resettingPassword } =
    usePostApiV1UsersPassword({})
  const onChangeEmail: ChangeEventHandler<HTMLInputElement> = ev =>
    setEmail(ev.target.value)

  const onResetPassword = () => {
    resetPassword({ email }).then(
      () => {
        enqueueSnackbar(t('util.auth.password_instructions_sent'), {
          variant: 'success',
        })
      },
      () => {
        enqueueSnackbar(t('errors.requests.generic'), {
          variant: 'error',
        })
      }
    )
  }

  const isValid = useMemo(() => {
    return !!email
  }, [email])

  const onEmailKey: KeyboardEventHandler<HTMLInputElement> = ev => {
    if (ev.key === 'Enter') {
      onResetPassword()
    }
  }

  return (
    <S.CenteredWrapper>
      <S.Container padding={4} $isMobile={isMobile}>
        <Stack space={4}>
          <LogoHeader centered />
          <Center>
            <S.Header variant="h5">{t('util.auth.forgot_password')}</S.Header>
          </Center>
          <S.InputWrapper>
            <TextInput
              fullWidth
              required
              label={t('util.email')}
              aria-label={t('util.email')}
              placeholder={t('profile.account_details.email_address')}
              value={email || ''}
              onChange={onChangeEmail}
              onKeyDown={onEmailKey}
              data-testid="email-input"
            />
            <Link to={Routes.SignIn} className="secondary-link">
              {t('util.auth.return_log_in')}
            </Link>
          </S.InputWrapper>
          <Box padding={0}>
            <Button
              color="primary"
              onClick={onResetPassword}
              loading={resettingPassword}
              data-testid="reset-password-button"
              disabled={!isValid || resettingPassword}
              fullWidth
            >
              {t('util.auth.send_password_instructions')}
            </Button>
          </Box>

          <S.TextWrapper>
            <Typography variant="body1" color="secondary.main">
              {t('util.auth.not_contributor')}
            </Typography>
            <S.Link
              href="https://www.vecteezy.com/contributors"
              className="secondary-link"
            >
              {t('util.sign_up')}
            </S.Link>
          </S.TextWrapper>
        </Stack>
      </S.Container>
    </S.CenteredWrapper>
  )
}

export default ForgotPassword
