import React from 'react'
import LogoSVG from './logo.svg'

type Props = {
  className?: string
}

const VecteezyLogo = ({ className }: Props): JSX.Element => {
  return <LogoSVG className={className} />
}

export default VecteezyLogo
