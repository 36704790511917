import { Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { useEffect, useMemo, useState } from 'react'
import { AuthStore, NavStore } from 'stores'
import { useInstance } from 'util/di'
import ReviewedFilesModal from './ReviewedFilesModal'
import * as S from './styled'

const ReviewFileCounter = (): JSX.Element => {
  const { fetchFileReviewCount, filesReviewedCount } =
    useInstance<NavStore>('NavStore')
  const { session } = useInstance<AuthStore>('AuthStore')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (session) {
      fetchFileReviewCount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchFileReviewCount()
    }, 900000)

    return () => {
      clearInterval(interval)
    }
  }, [fetchFileReviewCount])

  const count = useMemo(() => filesReviewedCount || 0, [filesReviewedCount])
  const renderEmoji = useMemo(() => (count > 0 ? '🔥' : ''), [count])

  if (!count) {
    return <></>
  }

  return (
    <>
      <S.Container onClick={() => setIsModalOpen(true)} className="fade-in">
        <Typography
          variant="body1"
          className="count"
        >{`${count} ${renderEmoji}`}</Typography>
      </S.Container>
      <ReviewedFilesModal isOpen={isModalOpen} setOpen={setIsModalOpen} />
    </>
  )
}

export default observer(ReviewFileCounter)
