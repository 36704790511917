import React, { MouseEvent } from 'react'
import { CircularProgress } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import * as S from './styled'

type Props = {
  children: React.ReactNode
  variant?: 'danger' | 'primary'
  onClick?: (ev: MouseEvent<HTMLElement>) => void
  className?: string
  size?: 'small' | 'medium' | 'large'
  inProgress?: boolean
  title?: string
  transparentBg?: boolean
  disabled?: boolean
  outlined?: boolean
}

const IconContainer = ({
  variant,
  children,
  className,
  onClick,
  size = 'medium',
  title,
  transparentBg,
  inProgress,
  disabled,
  outlined,
  ...rest
}: Props): JSX.Element => {
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (!disabled) {
      onClick(event)
    }
  }

  const theme = useTheme()

  const iconSize =
    size === 'small' ? 3 : size === 'medium' ? 4 : size === 'large' ? 7 : 4

  return (
    <S.Container
      className={className}
      data-testid={'icon-container'}
      onClick={handleClick}
      size={iconSize}
      title={title}
      transparentBg={transparentBg}
      variant={variant}
      disabled={disabled}
      outlined={outlined}
      sizeVariant={size}
      {...rest}
    >
      {inProgress && <CircularProgress size={theme.spacing(4)} />}
      {!inProgress && children}
    </S.Container>
  )
}

export default IconContainer
