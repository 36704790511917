import React, { useState, useRef, useEffect } from 'react'
import { styled, css } from '@mui/system'
import { themeSpacing } from '../../../helpers'
import { Theme } from '@mui/material'

type Props = {
  children: React.ReactNode
  height?: string
  itemWidth?: string
  overflowing?: boolean
  space?: number
  thumbColor?: string
  trackColor?: string
  trackHeight?: number
}

const Reel = ({
  itemWidth = 'auto',
  overflowing = false,
  space = 1,
  height = 'auto',
  trackColor = 'transparent',
  thumbColor = 'transparent',
  trackHeight = 0,
  children,
}: Props): JSX.Element => {
  const reelRef = useRef<HTMLDivElement>(null)
  const [isOverflowing, setIsOverflowing] = useState(overflowing)
  const [elementWidth, setElementWidth] = useState(null)

  useEffect(() => {
    const element = reelRef?.current

    const updateElementWidth = () => {
      if (
        element &&
        reelRef &&
        reelRef.current &&
        reelRef.current.offsetWidth
      ) {
        setElementWidth(reelRef.current.offsetWidth)
        setIsOverflowing(element.scrollWidth > reelRef.current.offsetWidth)
      }
    }

    // Initial mount
    updateElementWidth()

    // Update on resize
    window.addEventListener('resize', updateElementWidth)

    // Cleanup
    return () => window.removeEventListener('resize', updateElementWidth)
  }, [elementWidth])

  return (
    <Wrapper
      itemWidth={itemWidth}
      space={space}
      height={height}
      trackColor={trackColor}
      thumbColor={thumbColor}
      trackHeight={trackHeight}
      overflowing={isOverflowing}
      ref={reelRef}
      data-testid="eezy-layout-reel"
    >
      {children}
    </Wrapper>
  )
}

export default Reel

const Wrapper = styled('div')<{
  theme: Theme
  itemWidth: string
  height: string
  thumbColor: string
  trackColor: string
  space: number
  overflowing: boolean
  trackHeight: number
}>`
  display: flex;
  height: ${({ height }) => height};
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: ${({ thumbColor }) => thumbColor}
    ${({ trackColor }) => trackColor};

  ::-webkit-scrollbar {
    height: ${({ trackHeight }) => themeSpacing(trackHeight)};
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ trackColor }) => trackColor};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ trackColor }) => trackColor};
    background-image: linear-gradient(
      ${({ trackColor }) => trackColor} 0,
      ${({ trackColor }) => trackColor} 0.25rem,
      ${({ thumbColor }) => thumbColor} 0.25rem,
      ${({ thumbColor }) => thumbColor} 0.75rem,
      ${({ trackColor }) => trackColor} 0.75rem
    );
  }

  > * {
    flex: 0 0 ${({ itemWidth }) => itemWidth};
  }

  > img {
    height: 100%;
    flex-basis: auto;
    width: auto;
  }

  > * + * {
    margin-left: ${({ space }) => themeSpacing(space)};
  }

  ${({ space, overflowing }) =>
    overflowing &&
    css`
      padding-bottom: ${themeSpacing(space)};
    `}
`
