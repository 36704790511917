import React from 'react'
import * as S from './styled'

type Props = {
  actions?: JSX.Element // TODO: Remove this after CM-2570 is merged
  className?: string
  children?: React.ReactNode | React.ReactNode[]
}

const FilterBar = ({ className, children }: Props): JSX.Element => (
  <S.StyledFilterBar data-testid="filter-bar" className={className}>
    {children}
  </S.StyledFilterBar>
)

export default FilterBar
