import { Status } from 'constants/filters'
import {
  ContentType,
  DistributionStatusLabel,
  LicenseType,
} from 'constants/resources'
import { format, parseISO } from 'date-fns'
import { DateRange } from 'helpers/dates'
import { isEqual } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { parse, stringify } from 'qs'
import { BulkOperation } from 'types/operations'
import { GetApiV1ResourcesSearchQueryParams } from '../api'

export enum SearchBy {
  Keyword = 'keywords',
  Title = 'title',
  Filename = 'filename',
}

export type ResourcesFilter = {
  [key: string]: unknown
  category_id: string[]
  content_type: ContentType[]
  license: LicenseType[]
  uploaded?: DateRange
  approved?: DateRange
  nonce?: string
  search_field: SearchBy
  query: string
  distribution_status: string
  marketplace_ids: string[]
}

export type FormProps = {
  marketplace_id?: string
  host?: string
  protocol?: string
  portfolio?: string
  username?: string
  password?: string
  name?: string
  embed_metadata?: boolean
  titles?: boolean
  trim_keywords?: boolean
  copy_titles?: boolean
  max_keywords?: string
  max_characters?: string
}

const initFilter = (filter: ResourcesFilter): ResourcesFilter => {
  const query = parse(window.location.search.replace('?', ''))

  return Object.keys(filter).reduce((acc, key) => {
    if (key === 'uploaded') {
      acc.uploaded = {}

      if ('created_at_start' in query && 'created_at_end' in query) {
        acc.uploaded.from = parseISO(query['created_at_start'] as string)
        acc.uploaded.to = parseISO(query['created_at_end'] as string)
      } else if ('created_at_start' in query) {
        acc.uploaded.from = parseISO(query['created_at_start'] as string)
        acc.uploaded.to = undefined
      } else if ('created_at_end' in query) {
        acc.uploaded.from = undefined
        acc.uploaded.to = parseISO(query['created_at_end'] as string)
      } else {
        acc.uploaded = filter[key]
      }
    } else if (key === 'approved') {
      acc.approved = {}

      if ('approved_at_start' in query && 'approved_at_end' in query) {
        acc.approved.from = parseISO(query['approved_at_start'] as string)
        acc.approved.to = parseISO(query['approved_at_end'] as string)
      } else if ('approved_at_start' in query) {
        acc.approved.from = parseISO(query['approved_at_start'] as string)
        acc.approved.to = undefined
      } else if ('approved_at_end' in query) {
        acc.approved.from = undefined
        acc.approved.to = parseISO(query['approved_at_end'] as string)
      } else {
        acc.approved = filter[key]
      }
    } else if (key === 'status') {
      if ('state' in query) {
        const newStatus = Array.isArray(query['state'])
          ? query['state']
          : [query['state']]
        acc['status'] = newStatus.map(v => {
          switch (v) {
            case 'submitted':
              return Status.PendingReview
            case 'needs_corrections':
              return Status.ActionNeeded
            default:
              return v
          }
        }) as Status[]
      } else {
        acc['status'] = filter['status']
      }
    } else {
      acc[key] = query[key] || filter[key]
    }

    return acc
  }, {} as ResourcesFilter)
}

export default class FileDistributionStore {
  defaultFilter: ResourcesFilter = {
    category_id: [],
    content_type: [],
    license: [],
    uploaded: undefined,
    approved: undefined,
    search_field: SearchBy.Title,
    query: '',
    distribution_status: DistributionStatusLabel.Transferred,
    marketplace_ids: [],
  }
  defaultFormState: FormProps = {
    marketplace_id: undefined,
    host: undefined,
    portfolio: undefined,
    username: undefined,
    password: undefined,
    name: undefined,
    protocol: 'sftp',
    embed_metadata: true,
    titles: false,
    trim_keywords: false,
    copy_titles: false,
    max_keywords: '',
    max_characters: '',
  }

  @observable filter: ResourcesFilter = initFilter(this.defaultFilter)
  @observable bulkOperations: BulkOperation[] = []
  @observable formState: FormProps = this.defaultFormState
  @observable isAdvancedMetadataOpen = false

  constructor() {
    makeObservable(this)
  }

  @action setFilter = (filter: ResourcesFilter, syncUrl = false): void => {
    this.filter = filter

    if (syncUrl) {
      const url = `?${stringify(this.queryParams)}`
      history.pushState({}, '', url)
    }
  }

  @action resetFilters = (): void => {
    this.filter = { ...this.defaultFilter }
  }

  @action addBulkOperation = (operation: BulkOperation) => {
    const operations = [...this.bulkOperations]

    operations.push(operation)

    this.bulkOperations = operations
  }

  @action removeBulkOperation = (operation: BulkOperation) => {
    const operations = [...this.bulkOperations]

    operations.splice(
      operations.findIndex((op: BulkOperation) => op.id === operation.id),
      1
    )

    this.bulkOperations = operations
  }

  @action updateBulkOperation = (operation: BulkOperation) => {
    const operations = [...this.bulkOperations]

    operations.splice(
      operations.findIndex((op: BulkOperation) => op.id === operation.id),
      1,
      operation
    )

    this.bulkOperations = operations
  }

  @action setFormState = (form: FormProps) => {
    this.formState = form
  }

  @action setIsAdvancedMetadataOpen = (isOpen: boolean) => {
    this.isAdvancedMetadataOpen = isOpen
  }

  @computed get filterIsDirty(): boolean {
    return Object.entries(this.defaultFilter).some(
      ([key, value]) => !isEqual(this.filter[key], value)
    )
  }

  @computed get queryParams(): GetApiV1ResourcesSearchQueryParams {
    const params: GetApiV1ResourcesSearchQueryParams = {}

    if (this.filter.query && this.filter.search_field) {
      params.search_field = this.filter
        .search_field as GetApiV1ResourcesSearchQueryParams['search_field']
      params.query = this.filter.query
    }

    if (this.filter.category_id.length) {
      params.category_id = this.filter.category_id.map(x => Number(x))
    }

    if (this.filter.license.length > 0) {
      params.license = this.filter
        .license as GetApiV1ResourcesSearchQueryParams['license']
    }

    if (this.filter.uploaded) {
      if (
        'from' in this.filter.uploaded &&
        this.filter.uploaded.from instanceof Date
      ) {
        params.created_at_start = format(
          this.filter.uploaded.from,
          'yyyy-MM-dd'
        )
      }

      if (
        'to' in this.filter.uploaded &&
        this.filter.uploaded.to instanceof Date
      ) {
        params.created_at_end = format(this.filter.uploaded.to, 'yyyy-MM-dd')
      }
    }

    if (this.filter.approved) {
      if (
        'from' in this.filter.approved &&
        this.filter.approved.from instanceof Date
      ) {
        params.approved_at_start = format(
          this.filter.approved.from,
          'yyyy-MM-dd'
        )
      }

      if (
        'to' in this.filter.approved &&
        this.filter.approved.to instanceof Date
      ) {
        params.approved_at_end = format(this.filter.approved.to, 'yyyy-MM-dd')
      }
    }

    if (this.filter.marketplace_ids.length && this.filter.distribution_status) {
      params.marketplace_statuses = []
      switch (this.filter.distribution_status) {
        case DistributionStatusLabel.Transferring:
          params.marketplace_statuses.push({
            marketplace_transferring: this.filter.marketplace_ids,
          })
          break
        case DistributionStatusLabel.Transferred:
          params.marketplace_statuses.push({
            marketplace_transferred: this.filter.marketplace_ids,
          })
          break
        case DistributionStatusLabel.TransferFailed:
          params.marketplace_statuses.push({
            marketplace_transfer_failed: this.filter.marketplace_ids,
          })
          break
        default:
          break
      }
    } else {
      params.marketplace_statuses = []
    }

    return params
  }
}
