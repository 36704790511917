import { Theme } from '@mui/material'
import { styled } from '@mui/system'
import { themeSpacing } from '../../helpers/themeHelpers'

export const StyledLoadingSpinner = styled('div')<{
  $padding: number
  theme: Theme
}>`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: ${props => themeSpacing(props.$padding)(props)};
  position: relative;
`
