import { Routes } from 'constants/index'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { AuthStore } from 'stores'
import { useInstance } from 'util/di'

const RequireAuth = () => {
  const { session } = useInstance<AuthStore>('AuthStore')
  const location = useLocation()

  if (!session) {
    localStorage.setItem(
      'redirectAfterLogin',
      location.pathname + location.search
    )
    return <Navigate to={Routes.SignIn} replace />
  }

  return <Outlet />
}

export default RequireAuth
