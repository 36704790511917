import React from 'react'
import Slider from 'react-slick'
import Arrow from './Arrow'
import * as S from './styled'

type Props<T> = {
  items: T[]
  renderItem: (item: T, index: number) => JSX.Element
  dots?: boolean
  infinite?: boolean
  rows?: number
  speed?: number
  slidesToShow?: number
  slidesToScroll?: number
  className?: string
  afterChange?: (index: number) => void
}

function Carousel<T>({
  items,
  renderItem,
  className,
  ...rest
}: Props<T>): JSX.Element {
  const config = {
    dots: false,
    infinite: false,
    rows: 1,
    speed: 300,
    slidesToScroll: 1,
    nextArrow: <Arrow direction="next" />,
    prevArrow: <Arrow direction="prev" />,
    ...rest,
  }

  return (
    <S.CarouselContainer className={className}>
      <Slider {...config}>
        {items.map((item, idx) => renderItem(item, idx))}
      </Slider>
    </S.CarouselContainer>
  )
}

export default Carousel
