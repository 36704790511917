export enum Source {
  Royalty = 'royalty',
  Referral = 'referral',
  Bonus = 'bonus',
  Other = 'other',
}

export enum SourceCategory {
  Pro = 'pro',
  Free = 'free',
  Editorial = 'editorial',
  Carryover = 'Rounding Carryover',
  None = 'none',
}

export enum Status {
  Unpaid = 'unpaid',
  Pending = 'pending',
  Paid = 'paid',
}

export enum TransactionType {
  Earnings = 'earnings',
  Adjustments = 'adjustments',
}

export enum Region {
  US = 'US',
  International = 'Intl',
}
