import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { themeBorderRadius, themeColor, themeSpacing } from 'eezy-components'
import _WarningCircleIcon from 'ez-styles/assets/icons/circle/alert-circle-small.svg'
import _ErrorCircleIcon from 'ez-styles/assets/icons/circle/error-circle-small.svg'
import _SuccessCircleIcon from 'ez-styles/assets/icons/circle/success-circle-small.svg'
import { css } from 'styled-components'

type MessageProps = {
  $filled?: boolean
  $clickable?: boolean
}

export const ErrorContainer = styled('div')<MessageProps>`
  display: flex;
  align-items: center;
  background: ${themeColor('error', 'pale')};
  border-radius: ${themeBorderRadius('small')};
  border: 1px solid ${themeColor('error', 'main')};
  padding: ${themeSpacing(1)} ${themeSpacing(2)};
  gap: ${themeSpacing(1)};

  ${props =>
    props.$filled
      ? css`
          background: ${themeColor('error', 'main')};

          p {
            color: #fff !important;
          }

          a {
            color: #fff !important;
          }

          svg {
            fill: #fff !important;
          }
        `
      : ''}

  ${props =>
    props.$clickable
      ? css`
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }
        `
      : ''}
`

export const ErrorText = styled(Typography)`
  color: ${themeColor('error', 'main')};

  a {
    color: ${themeColor('error', 'main')};
    text-decoration: underline;
  }
`

export const ErrorCircleIcon = styled(_ErrorCircleIcon)`
  fill: ${themeColor('error', 'main')};
  flex-shrink: 0;
  height: ${themeSpacing(2)};
  margin-right: ${themeSpacing(1)};
  width: ${themeSpacing(2)};
`

export const ErrorInfoCircleIcon = styled(_WarningCircleIcon)`
  fill: ${themeColor('error', 'main')};
  flex-shrink: 0;
  height: ${themeSpacing(2)};
  margin-right: ${themeSpacing(1)};
  width: ${themeSpacing(2)};
`

export const SuccessContainer = styled('div')<MessageProps>`
  display: flex;
  align-items: center;
  background: ${themeColor('success', 'lighter')};
  border-radius: ${themeBorderRadius('small')};
  padding: ${themeSpacing(1)} ${themeSpacing(2)};
  gap: ${themeSpacing(1)};

  ${props =>
    props.$filled
      ? css`
          background: ${themeColor('success', 'main')};

          p {
            color: #fff !important;
          }

          a {
            color: #fff !important;
          }

          svg {
            fill: #fff !important;
          }
        `
      : ''}
`

export const SuccessText = styled(Typography)`
  color: ${themeColor('success', 'dark')};
`

export const SuccessCircleIcon = styled(_SuccessCircleIcon)`
  fill: ${themeColor('success', 'main')};
  flex-shrink: 0;
  height: ${themeSpacing(2)};
  margin-right: ${themeSpacing(1)};
  width: ${themeSpacing(2)};
`

export const WarningContainer = styled('div')<MessageProps>`
  display: flex;
  align-items: center;
  background: #fef3c7;
  border-radius: ${themeBorderRadius('small')};
  padding: ${themeSpacing(1)} ${themeSpacing(2)};
  gap: ${themeSpacing(1)};

  ${props =>
    props.$filled
      ? css`
          background: ${themeColor('warning', 'main')};

          p {
            color: #fff !important;
          }

          a {
            color: #fff !important;
          }

          svg {
            fill: #fff !important;
          }
        `
      : ''}
`

export const WarningText = styled(Typography)`
  color: ${themeColor('warning', 'dark')};
`

export const WarningCircleIcon = styled(_WarningCircleIcon)`
  fill: ${themeColor('warning', 'main')};
  flex-shrink: 0;
  height: ${themeSpacing(2)};
  margin-right: ${themeSpacing(1)};
  width: ${themeSpacing(2)};
`
