import { Routes as AppRoutes } from 'constants/index'
import NotFound from 'pages/404'
import { Navigate, Outlet } from 'react-router-dom'
import { AuthStore } from 'stores'
import { useInstance } from 'util/di'

type RequireRoleProps = {
  requiredRoles: string[]
}

const RequireRole = ({ requiredRoles }: RequireRoleProps) => {
  const { session } = useInstance<AuthStore>('AuthStore')

  if (!session) {
    localStorage.setItem(
      'redirectAfterLogin',
      location.pathname + location.search
    )
    return <Navigate to={AppRoutes.SignIn} replace />
  }

  const userRoles = session?.roles || []

  const hasRequiredRole = requiredRoles.some(role => userRoles.includes(role))

  if (!hasRequiredRole) {
    return <NotFound />
  }

  return <Outlet />
}

export default RequireRole
