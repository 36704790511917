import React from 'react'
import { styled } from '@mui/system'
import { Theme } from '@mui/material'

type Props = {
  children: React.ReactNode
  andText?: boolean
  gutters?: string
  intrinsic?: boolean
  maxWidth?: string
}

const Center = ({
  andText = false,
  gutters = '0',
  intrinsic = false,
  maxWidth = '60ch',
  children,
}: Props): JSX.Element => {
  return (
    <Wrapper
      andText={andText}
      gutters={gutters}
      intrinsic={intrinsic}
      maxWidth={maxWidth}
      data-testid="eezy-layout-center"
    >
      {children}
    </Wrapper>
  )
}

export default Center

const Wrapper = styled('div')<{
  theme: Theme
  andText?: boolean
  gutters?: string
  intrinsic: boolean
  maxWidth: string
}>`
  box-sizing: content-box;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ maxWidth }) => maxWidth};
  ${({ intrinsic }) =>
    intrinsic
      ? `
  align-items: center;
  display: flex;
  flex-direction: column;`
      : ''}
  ${({ gutters }) =>
    gutters
      ? `
  padding-left: ${gutters};
  padding-right: ${gutters};`
      : ''}
  ${({ andText }) => (andText ? 'text-align: center;' : '')}
`
