import { Typography } from '@mui/material'
import {
  usePostApiV1SessionResendTwoFactor,
  usePostApiV1SessionTwoFactor,
} from 'api'
import LogoHeader from 'components/LogoHeader'
import { Routes } from 'constants/index'
import { Button, LoadingSpinner, Stack, themeSpacing } from 'eezy-components'
import { captureError, getErrorMsg } from 'helpers/error'
import { useViewport } from 'helpers/hooks'
import cookie from 'js-cookie'
import { enqueueSnackbar } from 'notistack'
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AuthStore } from 'stores'
import { useInstance } from 'util/di'
import * as S from '../styled'

/**
 * Interpolated translations:
 *
 * i18n-tasks-use t('util.auth.verification_error')
 * i18n-tasks-use t('util.auth.verification_resend_error')
 */
const Verification = () => {
  const { t } = useTranslation()
  const { isMobile } = useViewport()
  const navigate = useNavigate()
  const { tempSession, clearTempSession, setSession } =
    useInstance<AuthStore>('AuthStore')
  const [verificationCode, setVerificationCode] = useState<string>('')
  const email = useMemo(() => tempSession?.email, [tempSession])

  useEffect(() => {
    if (!tempSession) {
      navigate(Routes.SignIn)
    }
  }, [navigate, tempSession])

  const onChangeVerificationCode: ChangeEventHandler<HTMLInputElement> = ev =>
    setVerificationCode(ev.target.value)
  const { mutate: verifyUser, loading: verifying } =
    usePostApiV1SessionTwoFactor({})
  const { mutate: resendNewCode, loading: resending } =
    usePostApiV1SessionResendTwoFactor({})

  const onVerify = async () => {
    try {
      const userSession = await verifyUser({
        otp_attempt: verificationCode,
      })

      cookie.set(`eezy-cm-auth-token`, userSession.api_token)
      setSession(userSession)
      clearTempSession()
      navigate('/')

      enqueueSnackbar(t('util.success.sign_in'), {
        variant: 'success',
        autoHideDuration: 1500,
      })
    } catch (e) {
      enqueueSnackbar(t(getErrorMsg(e) || 'util.auth.verification_error'), {
        variant: 'error',
      })
      captureError(e, '2FA Verification Error - auth/Verification/index.tsx')
    }
  }

  const handleResend = async () => {
    resendNewCode()
      .then(() => {
        enqueueSnackbar(t('util.auth.verification_resend_success'), {
          variant: 'success',
          autoHideDuration: 1500,
        })
      })
      .catch(e => {
        enqueueSnackbar(
          t(getErrorMsg(e) || 'util.auth.verification_resend_error'),
          {
            variant: 'error',
          }
        )
        captureError(
          e,
          'Resending 2FA Verification Error - auth/Verification/index.tsx'
        )
      })
  }

  const isValid = useMemo(() => {
    return !!verificationCode
  }, [verificationCode])

  const onVerifyKey: KeyboardEventHandler<HTMLInputElement> = ev => {
    if (ev.key === 'Enter') {
      onVerify()
    }
  }

  return (
    <S.CenteredWrapper>
      <S.Container padding={4} $isMobile={isMobile}>
        <Stack space={4}>
          <LogoHeader centered />
          <S.Heading>
            <S.Header variant="h5">{t('util.auth.verification')}</S.Header>
            <S.SubHeader variant="body1">
              {t('util.auth.confirm_account', { email })}
            </S.SubHeader>
          </S.Heading>
          <S.FormContainer>
            <S.Wrapper $isMobile={isMobile}>
              <S.StyledTextInput
                fullWidth
                required
                placeholder={t('util.auth.verification_placeholder')}
                aria-label={t('util.auth.verification_code_label')}
                value={verificationCode || ''}
                onKeyDown={onVerifyKey}
                onChange={onChangeVerificationCode}
                type="number"
                data-testid="verification-code-input"
              />
              <Button
                color="primary"
                onClick={onVerify}
                data-testid="verification-code-button"
                loading={verifying}
                disabled={!isValid || verifying}
                style={{ maxHeight: `${themeSpacing(6)}` }}
              >
                {t('util.verify')}
              </Button>
            </S.Wrapper>
          </S.FormContainer>

          <S.FormContainer>
            <Typography variant="body1">
              {t('util.auth.receive_code')}
            </Typography>
            {resending ? (
              <LoadingSpinner size={14} />
            ) : (
              <S.Link
                onClick={handleResend}
                className="secondary-link"
                data-testid="resend-code-link"
              >
                {t('util.auth.resend_code')}
              </S.Link>
            )}
          </S.FormContainer>
        </Stack>
      </S.Container>
    </S.CenteredWrapper>
  )
}

export default Verification
