import { differenceInSeconds } from 'date-fns'
import cookie from 'js-cookie'
import { Context } from '.'
import Popup from './Popup'

export default class ReferralsToast extends Popup {
  constructor(context: Context) {
    super(context)
    this.addCondition(this.checkDateDifference)
    this.addCondition(this.checkVisibility)
    this.addCondition(this.checkCookie)
  }

  checkCookie = (): boolean => !cookie.get('referrals-toast')

  checkVisibility = (): boolean => {
    const { session } = this.context
    return Boolean(!session?.isAdmin)
  }

  checkDateDifference = (): boolean => {
    const now = new Date()
    const promotionEndDate = new Date('2022-01-01T07:59:59Z')
    return differenceInSeconds(promotionEndDate, now) > 0
  }
}
