import React, { ReactNode, useState, useEffect } from 'react'
import { MenuItem } from '@mui/material'
import _ArrowSmallDown from 'ez-styles/assets/icons/arrows/arrow-small-down.svg'
import InputWrapper, { InputWrapperProps } from '../InputWrapper'
import TextInput from '../TextInput'
import * as S from './styled'

export type SelectOption<T = string> = {
  label: string
  value: T
}

type Props<T> = {
  className?: string
  fullWidth?: boolean
  label?: string
  labelColor?: string
  selectOptions: SelectOption<T>[]
  value?: string
  otherValue?: string
  onChange?: (event) => void
  onOtherChange?: (event) => void
  labelAsPlaceholder?: boolean
  labelShrink?: boolean
  labelCaption?: string
  placeholder?: string | boolean
  otherPlaceholder?: string
  required?: boolean
  tooltipContent?: ReactNode
  helperText?: string
  validate?: (value: string) => { valid: boolean; message: string }
  disabled?: boolean
  enableDeselect?: boolean
}

function SelectInput<T = string>(
  {
    className,
    fullWidth,
    label,
    labelColor,
    labelCaption,
    labelShrink,
    helperText,
    labelAsPlaceholder,
    value,
    otherValue,
    selectOptions,
    placeholder = false,
    otherPlaceholder,
    onChange,
    onOtherChange,
    required,
    tooltipContent,
    validate,
    disabled,
    enableDeselect,
    ...selectProps
  }: Props<T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: React.ForwardedRef<any>
): JSX.Element {
  useEffect(() => {
    validate && setIsValid(validate(value))
  }, [value, validate])

  const [isValid, setIsValid] = useState({ valid: true, message: '' })
  const [other, setOther] = useState<string>(otherValue || '')
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const inputWrapperProps: InputWrapperProps = {
    fullWidth,
    label,
    labelColor,
    labelCaption,
    labelShrink,
    labelAsPlaceholder,
    helperText,
    tooltipContent,
    required,
    disabled,
    error: isValid.valid !== true,
  }

  const [selected, setSelected] = useState(value || '')

  useEffect(() => {
    setSelected(value || '')
  }, [value])

  useEffect(() => {
    setOther(otherValue || '')
  }, [otherValue])

  const handleChange = event => {
    const value = event.target.value
    validate && setIsValid(validate(value))
    setSelected(value)

    if (value !== 'other') {
      setMenuOpen(false)
    }

    if (onChange) onChange(event)
  }

  const handleOtherChange = event => {
    const { value } = event.target
    setOther(value)

    if (onOtherChange) onOtherChange(event)
  }

  const handleMenuItemClick = (event, option) => {
    if (option.value === value && onChange && enableDeselect) {
      onChange(event)
    } else {
      event.preventDefault()
    }
  }

  const makeOption = (option, idx) => {
    return (
      <MenuItem
        key={idx}
        value={option.value}
        onClick={e => handleMenuItemClick(e, option)}
      >
        {option.label}
      </MenuItem>
    )
  }

  const closeMenu = (event?) => {
    if (event) {
      event.stopPropagation()
      const role = event.target.getAttribute('role')
      const value = event.target.getAttribute('data-value')
      if ((role === 'option' && value !== 'other') || !role) {
        setMenuOpen(false)
      }
    } else {
      setMenuOpen(false)
    }
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      closeMenu()
    } else {
      event.stopPropagation()
    }
  }

  return (
    <InputWrapper className={className} {...inputWrapperProps}>
      <S.StyledSelect
        labelId={`${label}-input-label`}
        IconComponent={S.ArrowSmallDown}
        inputProps={{ 'data-testid': 'select-input' }}
        displayEmpty={placeholder && !labelAsPlaceholder}
        value={selected}
        required={required}
        open={menuOpen}
        onOpen={() => setMenuOpen(true)}
        onClose={closeMenu}
        onChange={handleChange}
        ref={ref}
        {...selectProps}
      >
        {placeholder && !labelAsPlaceholder && (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )}

        {selectOptions.map(makeOption)}

        {selected === 'other' && onOtherChange ? (
          <S.TextInputWrapper onKeyDown={handleKeyDown}>
            <TextInput
              fullWidth
              value={other || ''}
              placeholder={otherPlaceholder}
              onChange={handleOtherChange}
              data-testid="other-text-input"
              required
            />
          </S.TextInputWrapper>
        ) : null}
      </S.StyledSelect>

      {validate ? (
        <S.ErrorMessage>{!isValid.valid && isValid.message}</S.ErrorMessage>
      ) : null}
    </InputWrapper>
  )
}

export default React.forwardRef(SelectInput)
