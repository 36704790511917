import { Autocomplete, TextField } from '@mui/material'
import { themeSpacing, themeColor } from '../../../helpers'
import { styled } from '@mui/system'

export const StyledAutocomplete = styled(Autocomplete)`
  overflow: hidden;
`

export const StyledTextField = styled(TextField)`
  & input {
    color: ${themeColor('secondary', 'main')};
    height: ${themeSpacing(2)};
    padding-left: ${themeSpacing(1)};
  }

  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${themeColor('secondary', 'lighter')};
      border-width: 1px;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${themeColor('secondary', 'veryLight')};
  }

  .Mui-disabled {
    background-color: ${themeColor('secondary', 'pale')};
  }
`

export const OptionWrapper = styled('li')`
  padding: ${themeSpacing(1)} ${themeSpacing(2)};
  color: ${themeColor('secondary', 'main')};
`

export const TextInputWrapper = styled('div')`
  padding: 0 ${themeSpacing(2)} ${themeSpacing(2)};
`
