import {
  usePatchApiV1UsersPasswordUnlock,
  usePatchApiV1UsersPasswordUpdate,
} from 'api'
import LogoHeader from 'components/LogoHeader'
import { Routes } from 'constants/index'
import {
  Box,
  Button,
  Center,
  Stack,
  TextInput,
  Typography,
} from 'eezy-components'
import { captureError, getErrorMsg } from 'helpers/error'
import { useViewport } from 'helpers/hooks'
import { enqueueSnackbar } from 'notistack'
import * as queryString from 'querystring'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { checkPasswordSecurity } from 'util/helpers'
import * as S from '../styled'

type IFormInput = {
  password: string
  passwordConfirmation: string
}

/**
 * Interpolated translations:
 *
 * i18n-tasks-use t('util.auth.password_character_length')
 * i18n-tasks-use t('util.auth.password_lowercase')
 * i18n-tasks-use t('util.auth.password_numbers')
 * i18n-tasks-use t('util.auth.password_symbols')
 * i18n-tasks-use t('util.auth.password_uppercase')
 * i18n-tasks-use t('util.auth.password_compromised')
 */

const ResetPassword = () => {
  const { t } = useTranslation()
  const { isMobile } = useViewport()
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    clearErrors,
    getValues,
  } = useForm<IFormInput>()
  const { mutate: resetPassword, loading: resetLoading } =
    usePatchApiV1UsersPasswordUpdate({})
  const { mutate: unlockAccount, loading: unlockLoading } =
    usePatchApiV1UsersPasswordUnlock({})
  const { search } = useLocation()

  const values = queryString.parse(search.replace(/^\?/, ''))
  const loading = resetLoading || unlockLoading

  const handleResetPassword = (formData: IFormInput) => {
    if (values.unlock_token) {
      return unlockAccount({
        password: formData.password,
        password_confirmation: formData.passwordConfirmation,
        unlock_token: values['unlock_token'] as string,
      })
    }
    return resetPassword({
      password: formData.password,
      password_confirmation: formData.passwordConfirmation,
      reset_password_token: values['reset_password_token'] as string,
    })
  }

  const onResetPassword = () => {
    const formData = getValues()
    handleResetPassword(formData)
      .then(() => {
        enqueueSnackbar(t('util.auth.password_changed'), {
          variant: 'success',
        })
        window.location.replace('/sign_in')
      })
      .catch(e => {
        const errorMsg = getErrorMsg(e)

        if (errorMsg?.includes('compromised')) {
          setError('password', {
            type: 'string',
            message: t('util.auth.password_compromised'),
          })
          captureError(
            e,
            'Reset password - Password is compromised - auth/ResetPassword/index.tsx'
          )
        } else {
          enqueueSnackbar(t(errorMsg || 'errors.requests.generic'), {
            variant: 'error',
          })
          captureError(e, 'Reset password error - auth/ResetPassword/index.tsx')
        }
      })
  }

  const checkPassword = (field: string, formData: IFormInput) => {
    clearErrors()
    const passwordErrors = checkPasswordSecurity(formData.password)

    if (passwordErrors.length) {
      const translatedErrors = passwordErrors.map(e => t(e))
      const message = `${t(
        'util.auth.password_at_least'
      )} ${translatedErrors.join(', ')}`

      setError('password', {
        type: 'string',
        message,
      })
    }
    if (
      formData.password &&
      formData.passwordConfirmation &&
      formData.passwordConfirmation != formData.password
    ) {
      setError('passwordConfirmation', {
        type: 'string',
        message: t('util.auth.confirmation_must_match'),
      })
    }
    return (
      passwordErrors.length === 0 &&
      formData.passwordConfirmation == formData.password
    )
  }

  return (
    <S.CenteredWrapper>
      <S.Container padding={4} $isMobile={isMobile}>
        <form onSubmit={handleSubmit(onResetPassword)}>
          <Stack space={4}>
            <LogoHeader centered />
            <Center>
              <S.Header variant="h5">{t('util.auth.reset_password')}</S.Header>
            </Center>

            <TextInput
              fullWidth
              required
              error={!!errors.password}
              helperText={errors.password?.message}
              label={t('util.auth.password')}
              aria-label={t('util.auth.password')}
              data-testid="auth-password-input"
              type="password"
              {...register('password', {
                required: true,
                validate: checkPassword,
              })}
            />
            <S.InputWrapper>
              <TextInput
                fullWidth
                required
                error={!!errors.passwordConfirmation}
                helperText={errors.passwordConfirmation?.message}
                label={t('util.auth.password_confirmation')}
                aria-label={t('util.auth.password_confirmation')}
                data-testid="auth-password-confirmation-input"
                type="password"
                {...register('passwordConfirmation', {
                  required: true,
                  validate: checkPassword,
                })}
              />
              <Link to={Routes.SignIn} className="secondary-link">
                {t('util.auth.return_log_in')}
              </Link>
            </S.InputWrapper>
            <Box padding={0}>
              <Button
                color="primary"
                onClick={onResetPassword}
                data-testid="reset-password-button"
                disabled={!isValid || loading}
                loading={loading}
                fullWidth
              >
                {t('util.auth.update_password')}
              </Button>
            </Box>

            <S.TextWrapper>
              <Typography variant="body1" color="secondary.main">
                {t('util.auth.not_contributor')}
              </Typography>
              <S.Link
                href="https://www.vecteezy.com/contributors"
                className="secondary-link"
              >
                {t('util.sign_up')}
              </S.Link>
            </S.TextWrapper>
          </Stack>
        </form>
      </S.Container>
    </S.CenteredWrapper>
  )
}

export default ResetPassword
