import { useGetApiV1UiMessagesKey, User } from 'api'
import { IdVerificationStatus } from 'constants/id-verification'
import cookie from 'js-cookie'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Session } from 'types'
import _FailedHeroImage from './images/failure.png'
import _HeroImage from './images/id-hero2x.png'
import _StartedHeroImage from './images/pending.png'

import PersonaCard from './PersonaCard'

import { gevent } from 'helpers/gtag'

type Props = {
  user: User
  session: Session
}

const getCookieID = (user: User) => {
  const status = user.id_inquiry_status as IdVerificationStatus
  if (
    !status ||
    [
      IdVerificationStatus.NotStarted,
      IdVerificationStatus.Expired,
      IdVerificationStatus.Started,
    ].includes(status)
  ) {
    return 'persona-toast'
  }

  return 'persona-toast-failed'
}

export const PersonaToast = ({ user, session }: Props): ReactElement | null => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(!cookie.get(getCookieID(user)))

  const setCookie = () => {
    cookie.set(getCookieID(user), 'true', { expires: 1 })
  }

  const onClose = () => {
    gevent('verify_id_start', 'ID')
    setCookie()
    setOpen(false)
  }

  const { data: toastStarted, loading: toastStartedLoading } =
    useGetApiV1UiMessagesKey({
      key: 'id-verification-started',
    })

  const { data: toastFailed, loading: toastFailedLoading } =
    useGetApiV1UiMessagesKey({
      key: 'id-verification-failed',
    })

  let href = session?.entityVerificationUrl
  let message = t('persona.toast.default')
  let image = _HeroImage

  if (
    session.enabledFeatures.includes('id_verification_v2_messaging') &&
    !toastStartedLoading &&
    toastStarted
  ) {
    message = toastStarted?.copy || ''
    image = _StartedHeroImage
  }

  let buttonText = t('persona.toast.verify')
  let bullets = [
    t('persona.toast.increased_exposure'),
    t('persona.toast.review_times'),
    t('persona.toast.more_files'),
  ]

  if (
    [IdVerificationStatus.Failed, IdVerificationStatus.Declined].includes(
      user.id_inquiry_status as IdVerificationStatus
    )
  ) {
    href = 'https://eezycontributors.zendesk.com/hc/en-us/requests/new'
    message = t('persona.toast.failed')

    if (
      session.enabledFeatures.includes('id_verification_v2_messaging') &&
      !toastFailedLoading &&
      toastFailed
    ) {
      message = toastFailed?.copy || ''
      image = _FailedHeroImage
    }

    buttonText = t('persona.toast.contact')
    bullets = []
  }

  if (!open) {
    return null
  }

  return (
    <PersonaCard
      type="toast"
      href={href}
      message={message}
      bullets={
        session.enabledFeatures.includes('id_verification_v2_messaging')
          ? []
          : bullets
      }
      buttonText={buttonText}
      onClick={onClose}
      heroImage={image}
    />
  )
}

export default PersonaToast
