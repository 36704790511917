import { ButtonGroup } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Button, themeColor, themeSpacing } from 'eezy-components'

export const StyledButton = styled(Button)`
  height: ${themeSpacing(4)} !important;
  padding: 0 ${themeSpacing(1.5)} !important;
`

export const StyledGroup = styled(ButtonGroup)`
  box-shadow: none;
`

export const DropdownButton = styled(Button)`
  height: ${themeSpacing(4)} !important;
  padding: ${themeSpacing(1.5)};
  min-width: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 2px solid transparent;

  &:not(:disabled) {
    border-left-color: ${themeColor('primary', 'light')};
  }

  & > svg {
    margin: 0 !important;
  }
`

export const SubmitButton = styled(Button)`
  height: ${themeSpacing(4)} !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
`
