import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  Specialty,
  useGetApiV1Specialties,
  useGetApiV1UserProfileId,
  usePatchApiV1UserProfileId,
  UserProfile,
} from 'api'
import { ErrorCircleIcon, ErrorContainer, ErrorText } from 'components/Message'
import { competitors } from 'constants/competitors'
import {
  Avatar,
  Button,
  CheckboxInput,
  LoadingSpinner,
  Modal,
  MultiSelect,
  TextInput,
  themeBorderRadius,
  themeColor,
  themeSpacing,
} from 'eezy-components'
import { captureError } from 'helpers/error'
import cookie from 'js-cookie'
import { Split } from 'layouts/section'
import { isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import API from 'util/api'
import { useInstance } from 'util/di'
import {
  arrayStringChecker,
  isValidHttpUrl,
  isValidSocialMediaUrl,
} from 'util/helpers'
import MapsService, { Coordinates, Place, Prediction } from 'util/maps'
import LocationLookup from '../../pages/profile/ProfileDetails/LocationLookup'
import { Session } from '../../types'

import _FacebookIcon from 'ez-styles/assets/icons/social/facebook.svg'
import _LinkedInIcon from 'ez-styles/assets/icons/social/linkedin.svg'
import _WebsiteIcon from 'ez-styles/assets/icons/social/weblink.svg'
import _XIcon from 'ez-styles/assets/icons/social/x.svg'
import _InstagramIcon from 'util/images/instagram-icon@2x.png'

type Props = {
  user: UserProfile
  session: Session
}

/**
 * Interpolated translations
 *
 * i18n-tasks-use t('util.not_permitted')
 */
const UserProfileModal = ({ user, session }: Props): JSX.Element => {
  const { t } = useTranslation()
  const api = useInstance<API>('api')
  const { enqueueSnackbar } = useSnackbar()

  // Fetch Data for Modal
  const { fetchPlace, getCountryFromPlace, getCoordinatesFromPlace } =
    useInstance<MapsService>('maps')
  const { data: specialtiesData, loading: specialtiesLoading } =
    useGetApiV1Specialties({})
  const {
    data: fetchedUser,
    loading,
    refetch,
  } = useGetApiV1UserProfileId({
    id: session?.id || '',
    lazy: !session?.id,
  })
  const { mutate: saveProfile } = usePatchApiV1UserProfileId({
    id: user.id,
  })

  // Component State Handlers
  const [data, setData] = useState<FormData | null>(null)
  const [avatarUrl, setAvatarUrl] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [coordinates, setCoordinates] = useState<Coordinates>({})
  const [country, setCountry] = useState<string>('')
  const [userSpecialties, setUserSpecialties] = useState<string[]>([])
  const [otherSpecialty, setOtherSpecialty] = useState<string>('')
  const [twitter, setTwitter] = useState<string>('')
  const [instagram, setInstagram] = useState<string>('')
  const [facebook, setFacebook] = useState<string>('')
  const [linkedIn, setLinkedIn] = useState<string>('')
  const [website, setWebsite] = useState<string>('')
  const [dontShowIsChecked, setDontShowIsChecked] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [validArr, setValidArr] = useState<string[]>([])
  const [saving, setSaving] = useState<boolean>(false)
  const [formLoading, setFormLoading] = useState<boolean>(false)
  const initDontShowIsChecked = false
  const hasCompetitorInfo = useMemo(
    () => arrayStringChecker(competitors, website),
    [website]
  )

  useEffect(() => {
    const loadedUser = { ...fetchedUser }

    const initAvatar = loadedUser?.avatar_url || ''
    const initFirstName = loadedUser?.first_name || ''
    const initLastName = loadedUser?.last_name || ''
    const initCountry = loadedUser?.vecteezy_user_info?.country || ''
    const initLocation = loadedUser?.location || ''
    const initCoordinates = loadedUser?.location_coordinates || {}
    const initSpecialties = loadedUser?.specialties || []
    const initOtherSpecialties = loadedUser?.other_specialties || ''
    const initTwitter =
      loadedUser?.vecteezy_user_info?.social_media?.twitter || ''
    const initInstagram =
      loadedUser?.vecteezy_user_info?.social_media?.instagram || ''
    const initFacebook =
      loadedUser?.vecteezy_user_info?.social_media?.facebook || ''
    const initLinkedIn =
      loadedUser?.vecteezy_user_info?.social_media?.linkedin || ''
    const initWebsite =
      loadedUser?.vecteezy_user_info?.social_media?.website || ''

    setAvatarUrl(initAvatar)
    setFirstName(initFirstName)
    setLastName(initLastName)
    setCountry(initCountry)
    setLocation(initLocation)
    setCoordinates(initCoordinates)
    setOtherSpecialty(initOtherSpecialties)
    setTwitter(initTwitter)
    setInstagram(initInstagram)
    setFacebook(initFacebook)
    setLinkedIn(initLinkedIn)
    setWebsite(initWebsite)
    setUserSpecialties(
      [...initSpecialties].map((specialty: Specialty) => specialty.id)
    )

    setFormLoading(loading)
  }, [fetchedUser, refetch, loading])

  // Form Action Handlers
  const onChangeFirstName: ChangeEventHandler<HTMLInputElement> = ev =>
    setFirstName(ev.target.value)
  const onChangeLastName: ChangeEventHandler<HTMLInputElement> = ev =>
    setLastName(ev.target.value)
  const [isOpen, setOpen] = useState<boolean>(
    !cookie.get(`user-profile-modal-${user.id}`)
  )
  const onSelectLocation = (option: Prediction) => {
    fetchPlace(option.place_id, (place: Place | null) => {
      if (place) {
        const country = getCountryFromPlace(place)
        setCountry(country?.short_name || '')
        setLocation(place.formatted_address || '')
        setCoordinates(getCoordinatesFromPlace(place))
      }
    })
  }
  const onClearLocation = () => {
    setCountry('')
    setLocation('')
  }
  const handleSpecialtySelect = (value: string[]) => {
    setUserSpecialties(value)
  }
  const handleOtherSpecialty = (value: string) => {
    setOtherSpecialty(value)
  }

  // Component Functions
  const updatedSpecialties = useMemo(() => {
    const specialtyIds = [...userSpecialties]
    if (otherSpecialty && !specialtyIds.includes('other')) {
      specialtyIds.push('other')
    }

    return specialtyIds
  }, [otherSpecialty, userSpecialties])

  const handleCookie = () => {
    cookie.set(`user-profile-modal-${user?.id}`, 'true', {
      // expiration after a week unless don't show is checked
      expires: dontShowIsChecked ? 3650 : 7,
    })
  }

  const onClose = () => {
    handleCookie()
    setOpen(false)
  }

  const onSave = async () => {
    if (hasCompetitorInfo) {
      setErrorMsg('util.not_permitted')
    } else {
      setErrorMsg(undefined)
      const specialtyIds = [...userSpecialties]
      const hasOtherSpecialty = specialtyIds.includes('other')
      setSaving(true)

      if (hasOtherSpecialty) {
        specialtyIds.splice(specialtyIds.indexOf('other'), 1)
      }

      setError(false)

      if (data) {
        try {
          await api.request('PATCH', `/api/v1/user_profile/${user.id}`, {
            body: data,
          })
          await refetch()
        } catch (e) {
          captureError(
            e,
            'Error saving user profile - Popups/UserProfileModal.tsx'
          )
          setError(true)
        }
      }

      try {
        await saveProfile({
          user: {
            first_name: firstName,
            last_name: lastName,
            specialty_ids: specialtyIds,
            other_specialties: hasOtherSpecialty ? otherSpecialty : '',
            location,
            location_coordinates: coordinates,
          },
          vecteezy_user: {
            country,
            twitter_url: twitter,
            instagram_url: instagram,
            facebook_url: facebook,
            linkedin_url: linkedIn,
            website,
          },
        })
        enqueueSnackbar(t('util.saved'), {
          variant: 'success',
        })
        await refetch()
        setSaving(false)
        onClose()
      } catch {
        enqueueSnackbar(t('util.something_went_wrong'), {
          variant: 'error',
        })
        setSaving(false)
      }
    }
  }

  const onDrop = useCallback(([upload]: File[]): void => {
    const reader = new FileReader()
    reader.onerror = () =>
      captureError(
        'Profile photo upload error',
        'Error uploading user profile photo - Popups/UserProfileModal.tsx'
      )
    reader.onload = () => {
      const formData = new FormData()
      formData.set('user[avatar]', upload, upload.name)
      setData(formData || '')

      setAvatarUrl(reader.result as string)
    }
    reader.readAsDataURL(upload)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })

  const validateUrl = (
    val: string | undefined,
    validator: (val: string | undefined) => boolean,
    errorMsg: string,
    type: string
  ) => {
    let error = ''
    let validCheckArr: string[] = []

    if (!validator(val)) {
      error = errorMsg
      validCheckArr.push(type)
      setValidArr(validCheckArr)
    } else if (validator(val) && val && validArr.includes(type)) {
      validCheckArr = validCheckArr.filter(v => v !== type)
      setValidArr(validCheckArr)
    }

    return {
      valid: validator(val),
      message: error,
    }
  }

  return (
    <StyledModal
      size="larger"
      modalOpen={isOpen}
      setModalOpen={onClose}
      data-testid="user-profile-modal"
    >
      <Modal.Header>{t('profile.popup.header_title')}</Modal.Header>

      <ModalBody>
        <Typography
          variant="largeText"
          color="secondary.main"
          sx={{ textAlign: 'center' }}
        >
          {t('profile.popup.header_subtitle')}
        </Typography>

        {specialtiesLoading || formLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <SplitSection {...getRootProps()}>
              <AvatarSection
                $dragging={isDragActive}
                data-testid="user-avatar-input"
              >
                <DropzoneContainer>
                  <input {...getInputProps()} />
                  <Avatar src={avatarUrl} size="x-large" />
                </DropzoneContainer>
              </AvatarSection>
              <UploadWrapper>
                <UploadButton>{t('profile.profile_photo.button')}</UploadButton>
              </UploadWrapper>
            </SplitSection>

            <Split>
              <TextInput
                fullWidth
                label={t('profile.account_details.first_name')}
                value={firstName || ''}
                onChange={onChangeFirstName}
                data-testid="first-name-input"
              />

              <TextInput
                fullWidth
                id="popup-last-name-input"
                label={t('profile.account_details.last_name')}
                value={lastName || ''}
                onChange={onChangeLastName}
                data-testid="last-name-input"
              />
            </Split>

            <FormSection>
              <MultiSelect
                value={updatedSpecialties || []}
                otherValue={otherSpecialty || ''}
                label={t('profile.profile_details.specialty')}
                placeholder={t('select_specialty') as string}
                otherPlaceholder={
                  t('profile.profile_details.other_specialty') as string
                }
                selectOptions={
                  specialtiesData
                    ? specialtiesData.map(({ id, name }) => ({
                        value: id,
                        label: name ?? '',
                      }))
                    : []
                }
                onChange={event => {
                  const { value } = event.target
                  handleSpecialtySelect(value)
                }}
                onOtherChange={event => {
                  const { value } = event.target
                  handleOtherSpecialty(value)
                }}
                data-testid="specialty-select"
              />

              <LocationLookup
                defaultValue={location}
                onSelect={onSelectLocation}
                onClear={onClearLocation}
                data-testid="location-lookup"
              />
            </FormSection>

            <SocialMediaContainer>
              <Typography variant="mediumBoldText">
                {t('profile.social_media.title')}
              </Typography>

              <TextInput
                fullWidth
                id="popup-twitter-url-input"
                aria-label="Twitter url"
                value={twitter || ''}
                placeholder="https://www.twitter.com"
                onChange={ev => setTwitter(ev.target.value)}
                startAdornment={<XIcon position="start" />}
                data-testid="twitter-input"
                validate={(val?: string) =>
                  validateUrl(
                    val,
                    isValidSocialMediaUrl,
                    t('profile.social_media.validation'),
                    'twitter'
                  )
                }
              />

              <TextInput
                fullWidth
                id="popup-instagram-url-input"
                aria-label="Instagram url"
                value={instagram || ''}
                placeholder="https://www.instagram.com"
                onChange={ev => setInstagram(ev.target.value)}
                startAdornment={<Image src={_InstagramIcon} />}
                data-testid="instagram-input"
                validate={(val?: string) =>
                  validateUrl(
                    val,
                    isValidSocialMediaUrl,
                    t('profile.social_media.validation'),
                    'instagram'
                  )
                }
              />

              <TextInput
                fullWidth
                id="popup-facebook-url-input"
                aria-label="Facebook url"
                value={facebook || ''}
                placeholder="https://www.facebook.com"
                onChange={ev => setFacebook(ev.target.value)}
                startAdornment={<FacebookIcon position="start" />}
                data-testid="facebook-input"
                validate={(val?: string) =>
                  validateUrl(
                    val,
                    isValidSocialMediaUrl,
                    t('profile.social_media.validation'),
                    'facebook'
                  )
                }
              />

              <TextInput
                fullWidth
                id="popup-linkedin-url-input"
                aria-label="LinkedIn url"
                value={linkedIn || ''}
                placeholder="https://www.linkedin.com"
                onChange={ev => setLinkedIn(ev.target.value)}
                startAdornment={<LinkedInIcon position="start" />}
                data-testid="linkedin-input"
                validate={(val?: string) =>
                  validateUrl(
                    val,
                    isValidSocialMediaUrl,
                    t('profile.social_media.validation'),
                    'linkedin'
                  )
                }
              />

              <TextInput
                fullWidth
                id="popup-website-url-input"
                aria-label="Your website url"
                value={website || ''}
                placeholder={t('profile.social_media.your_website')}
                onChange={ev => setWebsite(ev.target.value)}
                startAdornment={<WebsiteIcon position="start" />}
                data-testid="website-input"
                validate={(val?: string) =>
                  validateUrl(
                    val,
                    isValidHttpUrl,
                    t('profile.social_media.web_validation'),
                    'website'
                  )
                }
              />
            </SocialMediaContainer>

            <CheckboxContainer>
              <CheckboxInput
                value="charge-id-header"
                checked={dontShowIsChecked}
                onChange={() => setDontShowIsChecked(!dontShowIsChecked)}
                data-testid="dont-show-checkbox"
                label={
                  <Typography variant="body1" color="secondary.main">
                    {t('profile.popup.dont_show')}
                  </Typography>
                }
              />
            </CheckboxContainer>
          </>
        )}

        {error || errorMsg ? (
          <ErrorFooter>
            <ErrorContainer>
              <ErrorCircleIcon />
              <ErrorText>
                {errorMsg
                  ? t(errorMsg)
                  : t('errors.requests.save', { text: 'user profile' })}
              </ErrorText>
            </ErrorContainer>
          </ErrorFooter>
        ) : null}
      </ModalBody>

      <Modal.Actions>
        <Button
          color="primary"
          onClick={onSave}
          loading={saving}
          data-testid="save-user-button"
          disabled={
            isEqual(
              [
                fetchedUser?.avatar_url || '',
                fetchedUser?.first_name || '',
                fetchedUser?.last_name || '',
                [...(fetchedUser?.specialties || [])]
                  .map((specialty: Specialty) => specialty.id)
                  .toString(),
                fetchedUser?.location || '',
                fetchedUser?.vecteezy_user_info?.country || '',
                fetchedUser?.vecteezy_user_info?.social_media?.twitter || '',
                fetchedUser?.vecteezy_user_info?.social_media?.instagram || '',
                fetchedUser?.vecteezy_user_info?.social_media?.facebook || '',
                fetchedUser?.vecteezy_user_info?.social_media?.linkedin || '',
                fetchedUser?.vecteezy_user_info?.social_media?.website || '',
                initDontShowIsChecked,
              ],
              [
                avatarUrl,
                firstName,
                lastName,
                userSpecialties.toString(),
                location,
                country,
                twitter,
                instagram,
                facebook,
                linkedIn,
                website,
                dontShowIsChecked,
              ]
            ) || validArr.length > 0
          }
        >
          {t('profile.save_changes')}
        </Button>

        <Button onClick={onClose}>{t('cancel')}</Button>
      </Modal.Actions>
    </StyledModal>
  )
}

export default UserProfileModal

const StyledModal = styled(Modal)`
  & .MuiPaper-root {
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(199, 199, 199);
      border-radius: 4px;
      &:hover {
        background-color: rgb(153, 153, 153);
      }
    }
  }
`

const ModalBody = styled(Modal.Body)`
  gap: ${themeSpacing(4)};
`

const SplitSection = styled(Split)`
  align-items: center;
  border: 1px solid ${themeColor('grey', '200')};
  border-radius: ${themeBorderRadius('medium')};
  padding: ${themeSpacing(2)};
`

const AvatarSection = styled('div')<{
  $dragging?: boolean
}>`
  max-width: ${themeSpacing(12)};
  opacity: ${props => (props.$dragging ? 0.5 : 1)};
`

const UploadWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const DropzoneContainer = styled('span')`
  outline: none;
  cursor: pointer;
`

const UploadButton = styled(Button)`
  display: inline-block;
  margin: ${themeSpacing(2)} 0;
`

const FormSection = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  gap: ${themeSpacing(4)};
`

const SocialMediaContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${themeSpacing(2)};
`

const Image = styled('div')<{
  src?: string
}>`
  width: ${themeSpacing(3)};
  height: ${themeSpacing(3)};
  margin-left: ${themeSpacing(1)};
  background-image: url('${props => props.src}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`

const XIcon = styled(_XIcon)`
  fill: #000;
  width: ${themeSpacing(3)};
  height: ${themeSpacing(3)};
  margin-left: ${themeSpacing(1)};
`

const FacebookIcon = styled(_FacebookIcon)`
  fill: #4b598f;
  width: ${themeSpacing(3)};
  height: ${themeSpacing(3)};
  margin-left: ${themeSpacing(1)};
`

const LinkedInIcon = styled(_LinkedInIcon)`
  fill: #0077b5;
  width: ${themeSpacing(3)};
  height: ${themeSpacing(3)};
  margin-left: ${themeSpacing(1)};
`

const WebsiteIcon = styled(_WebsiteIcon)`
  width: ${themeSpacing(3)};
  height: ${themeSpacing(3)};
  margin-left: ${themeSpacing(1)};
`

const CheckboxContainer = styled('div')`
  display: flex;
  align-items: center;

  p {
    margin-left: ${themeSpacing(1)};
  }
`

const ErrorFooter = styled('div')`
  margin-top: ${themeSpacing(2)};
`
