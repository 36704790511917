import React from 'react'
import * as S from './styled'

type Props = {
  outlined?: boolean
  className?: string
}

const WarningIcon = ({ outlined, className = '' }: Props): JSX.Element => (
  <S.Container className={className} $outlined={Boolean(outlined)} />
)

export default WarningIcon
