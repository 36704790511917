import React, { useRef, useState, MouseEvent } from 'react'
import { MenuPopper } from '../index'
import _ArrowSmallDown from 'ez-styles/assets/icons/arrows/arrow-small-down.svg'
import { useMinWidth } from '../../hooks'
import * as S from './styled'

type FilterSelectOption = {
  label: string
  value: string
}

type FilterSelectGroupOption = {
  value: string
  label: string
  options: FilterSelectOption[]
}

type Props = {
  className?: string
  label: string
  options: FilterSelectGroupOption[]
  value?: string
  onChange(value: string, isGroup: boolean)
  outlinedButton?: boolean
}

const FilterSelectGroup = ({
  value,
  options,
  label,
  onChange,
  outlinedButton,
  className = '',
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openGroup, setOpenGroup] = useState<string | undefined>(undefined)
  const selectedGroup = getSelectedGroup(options, value)
  const selectedChild = getSelectedChild(options, value)
  const buttonRef = useRef()
  const minWidth = useMinWidth(buttonRef, getLabels(options))
  const open = Boolean(anchorEl)
  const adornmentOffset = 20

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleGroupClick = (group: FilterSelectGroupOption) => {
    onChange(group.value, true)
    handleClose()
  }

  const handleChildClick = (
    ev: MouseEvent<HTMLElement>,
    option: FilterSelectOption
  ) => {
    ev.preventDefault()
    ev.stopPropagation()
    onChange(option.value, false)
    handleClose()
  }

  const onExpandGroup = (
    ev: MouseEvent<HTMLElement>,
    group: FilterSelectGroupOption
  ) => {
    ev.preventDefault()
    ev.stopPropagation()

    if (group.value === openGroup) {
      setOpenGroup(undefined)
    } else {
      setOpenGroup(group.value)
    }
  }

  return (
    <>
      <S.StyledButton
        onClick={handleClick}
        testId={'select-button'}
        $minwidth={minWidth + adornmentOffset}
        $outlined={outlinedButton}
        ref={buttonRef}
        className={open ? `open ${className}` : className}
      >
        <S.StyledLabel>
          {selectedChild ? (
            <>
              <S.StyledGroupKey>{label}:</S.StyledGroupKey>
              <S.StyledValue>{selectedChild.label}</S.StyledValue>
            </>
          ) : selectedGroup ? (
            <>
              <S.StyledGroupKey>{label}:</S.StyledGroupKey>
              <S.StyledValue>{selectedGroup.label}</S.StyledValue>
            </>
          ) : (
            <>
              <S.StyledGroupKey>{label}:</S.StyledGroupKey>
              <S.StyledValue>All</S.StyledValue>
            </>
          )}
        </S.StyledLabel>

        <S.ArrowSmallDown />
      </S.StyledButton>

      <MenuPopper
        wrapButton
        open={open}
        handleClose={handleClose}
        anchorEl={anchorEl}
      >
        {options.map((group, idx) => (
          <S.StyledGroupMenuItem
            key={idx}
            $expanded={group.value === openGroup}
            onClick={() => handleGroupClick(group)}
          >
            <S.MenuItemHeading>
              {group.label}

              <S.ExpandButton onClick={ev => onExpandGroup(ev, group)}>
                {group.value === openGroup ? '-' : '+'}
              </S.ExpandButton>
            </S.MenuItemHeading>

            <S.MenuItemOptions $expanded={group.value === openGroup}>
              {group.options.map(option => (
                <S.MenuItemGroupOption
                  key={`${group.value}-${option.value}`}
                  onClick={ev => handleChildClick(ev, option)}
                >
                  {option.label}
                </S.MenuItemGroupOption>
              ))}
            </S.MenuItemOptions>
          </S.StyledGroupMenuItem>
        ))}
      </MenuPopper>
    </>
  )
}

const getSelectedGroup = (
  options: FilterSelectGroupOption[],
  value: string
): FilterSelectGroupOption | undefined => {
  return options.reduce((acc, group) => {
    if (value === group.value) {
      return group
    }

    if (acc) {
      return acc
    }

    const items = group.options || []
    return items.find(x => x.value === value) ? group : undefined
  }, undefined)
}

const getSelectedChild = (
  options: FilterSelectGroupOption[],
  value: string
): FilterSelectOption | undefined => {
  return options.reduce((acc, group) => {
    if (value === group.value) {
      return undefined
    }

    if (acc) {
      return acc
    }

    const items = group.options || []
    return items.find(x => x.value === value)
  }, undefined)
}

const getLabels = (options: FilterSelectGroupOption[]): string[] => {
  return options.reduce((acc, group) => {
    const items = group.options || []
    acc.push(group.label)
    acc = acc.concat(items.map(x => x.label))
    return acc
  }, [] as string[])
}

export default FilterSelectGroup
