export const SpecialCharsRegex = {
  Strict: /^[A-Za-z0-9 ]*$/g,
  Loose: /^[A-Za-z0-9,.'\- ]*$/g,
}

export const hasSpecialChars = (
  values: string[],
  pattern: RegExp = SpecialCharsRegex.Strict
): boolean => {
  return values.some(s => !s.match(pattern))
}

export const hasFewerWordsThan = (
  values: string[],
  wordCount: number
): boolean => {
  return values.some(s => s.trim().split(' ').length < wordCount)
}

export const capitalizeWord = (string: string): string =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`

export const hasWhiteSpace = (str: string) => {
  return str.indexOf(' ') >= 0
}

export const toSnakeCase = (string: string) =>
  string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_')

export const stripHtml = (string: string) => string.replace(/<(.|\n)*?>/g, '')

export const generateUID = () => {
  let firstPart = String((Math.random() * 46656) | 0)
  let secondPart = String((Math.random() * 46656) | 0)
  firstPart = ('000' + firstPart).slice(-3)
  secondPart = ('000' + secondPart).slice(-3)

  return firstPart + secondPart
}
export const hasNumber = (str: string) => {
  return /\d/.test(str)
}

export const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}
