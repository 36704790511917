import { styled } from '@mui/system'
import { themeSpacing } from '../../../helpers'
import { OutlinedInput } from '@mui/material'
import TagsList from './TagsList'

export const TagsField = styled(OutlinedInput)`
  && {
    gap: ${themeSpacing(1)};
    flex-direction: column;
    min-height: ${themeSpacing(25)};
    overflow: hidden;
    padding: ${themeSpacing(1.5)} ${themeSpacing(1.75)};
  }

  &:hover {
    cursor: pointer;
  }

  && input {
    padding: 0;
  }
`

export const StyledTagsList = styled(TagsList)`
  width: 100%;
`
