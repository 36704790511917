import { useEffect, useState } from 'react'
import { Challenge } from 'types'
import * as S from './styled'

import AI from './ai/active.png'
import AIComplete from './ai/complete.png'
import Asymmetrical from './asymmetrical/active.png'
import AsymmetricalComplete from './asymmetrical/complete.png'
import Barkitecture from './barkitecture/active.png'
import BarkitectureComplete from './barkitecture/complete.png'
import Contrast from './contrast/active.png'
import ContrastComplete from './contrast/complete.png'
import Diversity from './diversity/active.png'
import DiversityComplete from './diversity/complete.png'
import Duotone from './duotone/active.png'
import DuotoneComplete from './duotone/complete.png'
import Futuristic from './futuristic/active.png'
import FuturisticComplete from './futuristic/complete.png'
import Geometric from './geometric/active.png'
import GeometricComplete from './geometric/complete.png'
import Golden from './golden/active.png'
import GoldenComplete from './golden/complete.png'
import Grunge from './grunge/active.png'
import GrungeComplete from './grunge/complete.png'
import Handmade from './handmade/active.png'
import HandmadeComplete from './handmade/complete.png'
import HighContrast from './high-contrast/active.png'
import HighContrastComplete from './high-contrast/complete.png'
import Hygge from './hygge/active.png'
import HyggeComplete from './hygge/complete.png'
import InMotion from './in-motion/active.png'
import InMotionComplete from './in-motion/complete.png'
import LoFi from './lo-fi/active.png'
import LoFiComplete from './lo-fi/complete.png'
import Locked from './locked.svg'
import Macro from './macro/active.png'
import MacroComplete from './macro/complete.png'
import Metallic from './metallic/active.png'
import MetallicComplete from './metallic/complete.png'
import Minimalism from './minimalism/active.png'
import MinimalismComplete from './minimalism/complete.png'
import Monochrome from './monochrome/active.png'
import MonochromeComplete from './monochrome/complete.png'
import Moody from './moody/active.png'
import MoodyComplete from './moody/complete.png'
import Muted from './muted/active.png'
import MutedComplete from './muted/complete.png'
import Nostalgia from './nostalgia/active.png'
import NostalgiaComplete from './nostalgia/complete.png'
import Pastel from './pastel/active.png'
import PastelComplete from './pastel/complete.png'
import Pixelation from './pixelation/active.png'
import PixelationComplete from './pixelation/complete.png'
import Portraits from './portraits/active.png'
import PortraitsComplete from './portraits/complete.png'
import Riso from './riso/active.png'
import RisoComplete from './riso/complete.png'
import Rustic from './rustic/active.png'
import RusticComplete from './rustic/complete.png'
import ScienceFiction from './science-fiction/active.png'
import ScienceFictionComplete from './science-fiction/complete.png'
import StillLife from './still-life/active.png'
import StillLifeComplete from './still-life/complete.png'
import Ultramodern from './ultramodern/active.png'
import UltramodernComplete from './ultramodern/complete.png'
import Vertical from './vertical/active.png'
import VerticalComplete from './vertical/complete.png'
import Vivid from './vivid/active.png'
import VividComplete from './vivid/complete.png'
import Wedding from './wedding/active.png'
import WeddingComplete from './wedding/complete.png'

type BadgePng = string
type BadgeSvg = () => JSX.Element
type BadgeSource = BadgePng | BadgeSvg

type Props = {
  challenge: Challenge
  animate?: boolean
  className?: string
}

const Badge = ({
  challenge,
  className,
  animate,
}: Props): JSX.Element | null => {
  const [classname, setClassname] = useState<string>(className || '')
  const {
    type = '',
    isCompleted,
    isLocked,
    incomplete_badge,
    complete_badge,
  } = challenge

  const Badges: { [key: string]: BadgeSource[] } = {
    pastel: [Pastel, PastelComplete],
    wedding: [Wedding, WeddingComplete],
    geometric: [Geometric, GeometricComplete],
    ai: [AI, AIComplete],
    golden: [Golden, GoldenComplete],
    barkitecture: [Barkitecture, BarkitectureComplete],
    hygge: [Hygge, HyggeComplete],
    vertical: [Vertical, VerticalComplete],
    ultramodern: [Ultramodern, UltramodernComplete],
    minimalism: [Minimalism, MinimalismComplete],
    portraits: [Portraits, PortraitsComplete],
    vivid: [Vivid, VividComplete],
    macro: [Macro, MacroComplete],
    nostalgia: [Nostalgia, NostalgiaComplete],
    monochrome: [Monochrome, MonochromeComplete],
    stilllife: [StillLife, StillLifeComplete],
    highcontrast: [HighContrast, HighContrastComplete],
    sciencefiction: [ScienceFiction, ScienceFictionComplete],
    lofi: [LoFi, LoFiComplete],
    rustic: [Rustic, RusticComplete],
    inmotion: [InMotion, InMotionComplete],
    riso: [Riso, RisoComplete],
    moody: [Moody, MoodyComplete],
    duotone: [Duotone, DuotoneComplete],
    futuristic: [Futuristic, FuturisticComplete],
    diversity: [Diversity, DiversityComplete],
    grunge: [Grunge, GrungeComplete],
    handmade: [Handmade, HandmadeComplete],
    contrast: [Contrast, ContrastComplete],
    pixelation: [Pixelation, PixelationComplete],
    metallic: [Metallic, MetallicComplete],
    asymmetrical: [Asymmetrical, AsymmetricalComplete],
    muted: [Muted, MutedComplete],
  }

  useEffect(() => {
    animate && setClassname(`${className} animated`)
    // eslint-disable-next-line
  }, [])

  if (isLocked) {
    return (
      <S.Container>
        <Locked />
      </S.Container>
    )
  }

  if (!Badges[type] && !complete_badge && !incomplete_badge) {
    return null
  }

  const getBadge = () => {
    let badge: BadgeSource | undefined = isCompleted
      ? complete_badge
      : incomplete_badge

    if (!badge) {
      badge = !isCompleted ? Badges[type][0] : Badges[type][1]
    }

    return badge
  }
  const BadgeToRender = getBadge()

  if (typeof BadgeToRender === 'string') {
    return (
      <S.Container className={classname} animate={animate}>
        <S.Image src={BadgeToRender} />
      </S.Container>
    )
  }

  return (
    <S.Container className={classname} animate={animate}>
      <BadgeToRender />
    </S.Container>
  )
}

export default Badge
