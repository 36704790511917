import React, { forwardRef } from 'react'
import { styled } from '@mui/system'
import PasswordInput from '../PasswordInput'
import TextInput from '../TextInput'
import { InputProps } from '../TextInput/types'
import { ThemedTooltip } from '../../util'
import _CheckmarkIcon from 'ez-styles/assets/icons/circle/success-circle-small.svg'
import { themeSpacing } from '../../../helpers/themeHelpers'
import { Typography } from '@mui/material'
import Button from '../../button'

export const CopyButton = styled(Button)`
  margin-left: ${themeSpacing(1)};
  margin-right: ${themeSpacing(0.5)};
`

export const CopiedTooltip = styled(Typography)`
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  user-select: none;
`

export const StyledThemedTooltip = styled(ThemedTooltip)`
  & .tooltip {
    top: ${themeSpacing(4)};
  }
`

export const CheckmarkIcon = styled(_CheckmarkIcon)`
  fill: #fff;
  height: ${themeSpacing(1.25)};
  margin-right: ${themeSpacing(0.5)};
  width: ${themeSpacing(1.25)};
`

const InputComponent = forwardRef<
  HTMLElement,
  InputProps & { password: string }
>(function InputComponent(props, ref) {
  const InputComponent = props.password === 'true' ? PasswordInput : TextInput

  return <InputComponent ref={ref} {...props} />
})

export const StyledInputComponent = styled(InputComponent)`
  & .MuiInputBase-root,
  & .MuiInputBase-input {
    cursor: pointer;
  }
`
