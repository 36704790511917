import React, { useState } from 'react'
import { Typography } from '@mui/material'
import LeftArrow from 'ez-styles/assets/icons/arrows/arrow-small-left.svg'
import RightArrow from 'ez-styles/assets/icons/arrows/arrow-small-right.svg'
import { Select } from '../index'
import * as S from './styled'

type Props = {
  perPage: number
  numPages: number
  currentPage: number
  updatePage: (page: number) => void
  updatePerPage: (value: number) => void
  withPageLimits?: boolean
}

const InputPager = ({
  perPage,
  numPages,
  currentPage,
  updatePage,
  updatePerPage,
  withPageLimits,
}: Props): JSX.Element => {
  const [currentPageInputValue, setCurrentPageInputValue] = useState(
    currentPage.toString()
  )

  const inputSubmit = event => {
    if (
      !event.target.value ||
      parseInt(event.target.value, 10) < 1 ||
      isNaN(event.target.value)
    ) {
      event.target.blur()
      return
    }

    if (parseInt(event.target.value, 10) > numPages) {
      updatePage(numPages)
    } else {
      updatePage(event.target.value)
    }
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      inputSubmit(event)
    }
  }

  const onSetPerPage = ev => {
    if (ev.target.value) {
      updatePerPage(Number(ev.target.value))
    }
  }

  return (
    <S.StyledPagination
      data-testid={'input-pager'}
      role="navigation"
      aria-label="Pagination Navigation"
    >
      <S.PaginationList>
        <S.PaginationButton
          disabled={!(currentPage > 1)}
          className="on-grey"
          aria-label="Previous Page"
          size="small"
          onClick={() => updatePage(currentPage - 1)}
        >
          <LeftArrow />
        </S.PaginationButton>

        <S.PageInput
          aria-label="Current Page"
          aria-current="true"
          onFocus={() => setCurrentPageInputValue('')}
          onBlur={() => setCurrentPageInputValue(currentPage.toString())}
          onKeyDown={handleKeyDown}
          onChange={event =>
            setCurrentPageInputValue(event.target.value.toString())
          }
          value={currentPageInputValue}
        />

        <S.PaginationButton
          disabled={!(currentPage < numPages)}
          className="on-grey"
          aria-label="Next Page"
          size="small"
          onClick={() => updatePage(currentPage + 1)}
        >
          <RightArrow />
        </S.PaginationButton>
      </S.PaginationList>

      <Typography variant="body1">
        of {numPages.toLocaleString('en-US')}
      </Typography>

      {withPageLimits ? (
        <Select
          value={String(perPage)}
          selectOptions={[
            { value: '5', label: '5 per page' },
            { value: '10', label: '10 per page' },
            { value: '25', label: '25 per page' },
            { value: '50', label: '50 per page' },
            { value: '100', label: '100 per page' },
            { value: '125', label: '125 per page' },
          ]}
          onChange={onSetPerPage}
        />
      ) : null}
    </S.StyledPagination>
  )
}

export default InputPager
