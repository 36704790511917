import React from 'react'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import SortableTag from '../SortableTag'
import Tag from '../Tag'
import * as S from './styled'

type Props = {
  tags: string[]
  onRemove: (word: string) => void
  hasError?: (tag: string, idx: number) => boolean
  className?: string
  draggable?: boolean
  onDrag?: (from: number, to: number) => void
}

const TagsList = ({
  tags,
  onRemove,
  hasError,
  draggable,
  className,
  onDrag,
}: Props): JSX.Element => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  if (!tags.length) {
    return null
  }

  const onDragOver = ({ active, over }) => {
    if (over && active.id !== over.id) {
      const from = tags.indexOf(active.id)
      const to = tags.indexOf(over.id)
      onDrag(from, to)
    }
  }

  if (draggable) {
    return (
      <DndContext sensors={sensors} onDragOver={onDragOver}>
        <SortableContext items={tags}>
          <S.StyledTagsList className={className} data-testid="tags-list">
            {tags.map((tag, idx) => (
              <SortableTag
                key={`tag-${tag}-${idx}`}
                tag={tag}
                index={idx}
                hasError={hasError && hasError(tag, idx)}
                removeTag={() => onRemove(tag)}
              />
            ))}
          </S.StyledTagsList>
        </SortableContext>
      </DndContext>
    )
  }

  return (
    <S.StyledTagsList className={className} data-testid="tags-list">
      {tags.map((tag, idx) => (
        <li key={`tag-${tag}-${idx}`}>
          <Tag
            tag={tag}
            index={idx}
            removeTag={() => onRemove(tag)}
            hasError={hasError && hasError(tag, idx)}
          />
        </li>
      ))}
    </S.StyledTagsList>
  )
}

export default TagsList
