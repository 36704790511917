import React, { useRef } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import * as S from './styled'

type Props = {
  className?: string
  fullWidth?: boolean
  label?: string
  password?: boolean | string
  value: string
}

const CredentialCopyInput = ({
  className,
  fullWidth,
  label,
  password,
  value,
}: Props): JSX.Element => {
  const inputRef = useRef<HTMLElement>(null)

  const copiedTooltipContent = (
    <S.CopiedTooltip variant="smallText">
      <S.CheckmarkIcon />
      Copied!
    </S.CopiedTooltip>
  )

  return (
    <S.StyledThemedTooltip
      data-testid="copied-tooltip"
      tooltipContent={copiedTooltipContent}
      placement="top"
      clickToShow
      timer={1500}
      triggerFullWidth={true}
      disableMobileTooltip
    >
      <CopyToClipboard text={value}>
        <S.StyledInputComponent
          className={className}
          data-testid="credential-copy-input"
          ref={inputRef}
          fullWidth={fullWidth}
          label={label}
          password={password?.toString()}
          endAdornment={<S.CopyButton size="small">Copy</S.CopyButton>}
          value={value}
          readOnly
        />
      </CopyToClipboard>
    </S.StyledThemedTooltip>
  )
}

export default CredentialCopyInput
