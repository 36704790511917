import React from 'react'
import { styled } from '@mui/system'
import { themeSpacing } from '../../../helpers'
import { Theme } from '@mui/material'

type Props = {
  children: React.ReactNode
  breakout?: boolean
  margin?: number
  fixed?: boolean
}

const Imposter = ({
  breakout = false,
  margin = 0,
  fixed = false,
  children,
}: Props): JSX.Element => {
  return (
    <Wrapper
      breakout={breakout}
      margin={margin}
      fixed={fixed}
      data-testid="eezy-layout-imposter"
    >
      {children}
    </Wrapper>
  )
}

export default Imposter

const Wrapper = styled('div')<{
  theme: Theme
  fixed: boolean
  breakout: boolean
  margin: number
}>`
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* Contents */

  overflow: auto;
  max-height: ${({ margin }) => `calc(100% - (${themeSpacing(margin)} * 2))`};
  max-width: ${({ margin }) => `calc(100% - (${themeSpacing(margin)} * 2))`};
`
