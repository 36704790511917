import React, { ReactNode } from 'react'
import { ThemedTooltip } from '../../util'
import _InfoIcon from 'ez-styles/assets/icons/circle/circle-question.svg'
import * as S from './styled'

type Props = {
  placement?: string
  interactive?: boolean
  tooltipContent: ReactNode
  tooltipHeader?: string
  isModal?: boolean
}

const TooltipIcon = ({
  interactive,
  tooltipContent,
  tooltipHeader,
  isModal,
  ...rest
}: Props): JSX.Element => (
  <ThemedTooltip
    tooltipContent={tooltipContent}
    tooltipHeader={tooltipHeader}
    isModal={isModal}
    interactive={interactive}
    {...rest}
  >
    <S.InfoIcon aria-label="Tooltip" tabIndex="0" />
  </ThemedTooltip>
)

export default TooltipIcon
