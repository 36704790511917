type HeaderConfig = {
  [key: string]: {
    label: string
    sortable: boolean
  }
}

export const TABLE_COLUMNS: HeaderConfig = {
  batch_id: {
    label: 'Payout Batch ID',
    sortable: true,
  },
  payment_method: {
    label: 'Payment Method',
    sortable: true,
  },
  state: {
    label: 'Batch Status',
    sortable: true,
  },
  total_invoice_count: {
    label: 'Total Invoice Count',
    sortable: true,
  },
  paid_invoice_count: {
    label: 'Paid Invoice Count',
    sortable: true,
  },
  unpaid_invoice_count: {
    label: 'Not Paid Invoice Count',
    sortable: true,
  },
  amount_cents: {
    label: 'Amount',
    sortable: true,
  },
  fees_cents: {
    label: 'Fees',
    sortable: true,
  },
  created_at: {
    label: 'Date Created',
    sortable: true,
  },
  error_count: {
    label: 'Errors',
    sortable: true,
  },
}

export enum PaymentMethod {
  CSV = 'csv',
  PayPal = 'paypal',
  Payoneer = 'payoneer',
}

export enum BatchStatus {
  Processing = 'processing',
  Success = 'success',
  Failed = 'failed',
}
