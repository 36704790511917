import React from 'react'
import _ArrowLeft from 'ez-styles/assets/icons/arrows/arrow-small-left.svg'
import _ArrowRight from 'ez-styles/assets/icons/arrows/arrow-small-right.svg'
import * as S from './styled'

type Props = {
  direction: 'next' | 'prev'
  onClick?: () => void
  className?: string
}

const Arrow = ({ direction, onClick, className }: Props): JSX.Element => (
  <S.ArrowContainer
    onClick={onClick}
    boxShadow={1}
    $direction={direction}
    $disabled={className?.includes('disabled')}
  >
    {direction === 'next' ? (
      <S.ChevronIcon />
    ) : (
      <S.ChevronIcon as={_ArrowLeft} />
    )}
  </S.ArrowContainer>
)

export default Arrow
