import { Box } from '@mui/material'
import {
  ResponsesResourceCounts,
  useGetApiV1ContributorNotifications,
  useGetApiV1ResourcesCounts,
  useGetApiV1UsersIdSubmissionQuotas,
  User,
} from 'api'
import { ADMIN_ROLES, Routes } from 'constants/index'
import { addTestId } from 'helpers/tests'
import { observer } from 'mobx-react'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { NavStore, ReferralsStore, ResourceStore } from 'stores'

import Notifications from './Notifications'
import Popups from './Popups'

import AutoAssignButton from 'components/AutoAssignButton'
import PillLabel from 'components/PillLabel'
import ReviewFileCounter from 'components/ReviewFileCounter'
import SubNav, { SubNavItem } from '../components/SubNav'
import ResourceCounter from '../components/SubNav/ResourceCounter'
import { Session } from '../types'
import { useInstance } from '../util/di'
import AdminNav from './admin_nav'
import MobileNav from './Mobile'

import * as S from './styled'

import { gevent } from 'helpers/gtag'

export type AdminResourceNav = {
  key: string
  path: string
  label: string
  count: keyof ResponsesResourceCounts
}

const RESOURCE_NAV: AdminResourceNav[] = [
  // i18n-use-tasks t('nav.admin.all_assets')
  {
    key: 'all-assets',
    path: Routes.Assets,
    label: 'nav.admin.all_assets',
    count: 'all',
  },
  // i18n-use-tasks t('nav.admin.submitted')
  {
    key: 'submitted',
    path: Routes.Submitted,
    label: 'nav.admin.submitted',
    count: 'pending_rating',
  },
  // i18n-use-tasks t('nav.admin.flagged')
  {
    key: 'flagged',
    path: Routes.Flagged,
    label: 'nav.admin.flagged',
    count: 'unapproved',
  },
]

type Props = {
  session: Session
  isMobile: boolean
}

const Nav = ({ isMobile, session }: Props): JSX.Element => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { popups, formIsDirty, setFormIsDirty } =
    useInstance<NavStore>('NavStore')
  const { resourcesAreDirty, setSubmissionQuotas } =
    useInstance<ResourceStore>('ResourceStore')
  const { fetchChallenges } = useInstance<ReferralsStore>('ReferralsStore')
  const isAdmin = Boolean(
    session?.roles.length &&
      ADMIN_ROLES.some((role: string) => session?.roles.includes(role))
  )
  const hasDistributionSettings =
    Boolean(session?.hasFeature('content_distribution')) && !session.wo
  const hasDistributionBetaLabel =
    Boolean(session?.hasFeature('content_distribution_beta_label')) &&
    !session.wo
  const needsTerms =
    session && !session.current_on_terms && location.pathname === Routes.Terms
  const directoryInfoNotConfirmed =
    session &&
    !session.directory_confirmation &&
    location.pathname === Routes.ContributorDirectoryInfo
  const directoryInformation =
    session && location.pathname === Routes.ContributorDirectoryInformation
  const shouldHideNav = useMemo(
    () => needsTerms || directoryInfoNotConfirmed || directoryInformation,
    [directoryInfoNotConfirmed, directoryInformation, needsTerms]
  )

  const shouldShowReviewButton = useMemo(() => {
    const isAutoAssignReviewer =
      session.canReviewContributors.isAutoAssignReviewer
    return isAdmin && isAutoAssignReviewer
  }, [session.canReviewContributors.isAutoAssignReviewer, isAdmin])

  const { data: notifications, loading } = useGetApiV1ContributorNotifications(
    {}
  )

  const { data: counts, refetch } = useGetApiV1ResourcesCounts({
    queryParams: {
      states: RESOURCE_NAV.map(nav => nav.count),
    },
    lazy: true,
  })

  const { data: submissionQuotas, refetch: refetchQuotas } =
    useGetApiV1UsersIdSubmissionQuotas({
      id: session?.id || '',
      lazy: true,
    })

  useEffect(() => {
    if (session?.id && submissionQuotas && submissionQuotas.length > 0) {
      setSubmissionQuotas(submissionQuotas)
    }
  }, [session?.id, setSubmissionQuotas, submissionQuotas])

  useEffect(() => {
    refetch()
    /* eslint-disable-next-line */
  }, [isAdmin])

  useEffect(() => {
    refetchQuotas()
    /* eslint-disable-next-line */
  }, [])

  const onUnload = (ev: BeforeUnloadEvent): BeforeUnloadEvent => {
    if (resourcesAreDirty) {
      ev.preventDefault()
      ev.returnValue = 'ResourceStore is dirty'
    }
    return ev
  }

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload)

    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }

    /* eslint-disable-next-line */
  }, [])

  useEffect(() => {
    if (!shouldHideNav) {
      fetchChallenges().then(challenges => {
        if (Array.isArray(challenges)) {
          popups.addContext(
            'challenges',
            challenges.filter(c => c.isCompleted)
          )
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldHideNav])

  const userSelected = (user: User): void => {
    navigateTo(`/admin/trestle/all_users/${user.id}`)
  }

  const renderHelp = (): JSX.Element => {
    return (
      <S.StyledNavLink
        dest="http://eezycontributors.zendesk.com/hc/en-us"
        navigate={navigateTo}
        label={t('nav.support.base')}
        activeTab={pathname}
      />
    )
  }

  const renderInsightsNav = (): JSX.Element => {
    if (!session) {
      return <></>
    }

    const yourInsights = !session.wo

    return (
      <S.StyledNavLink
        dest={Routes.Insights}
        navigate={navigateTo}
        label={t('nav.insights.base')}
        activeTab={pathname}
        {...addTestId('nav-link-insights')}
      >
        <S.StyledNavMenuItem
          dest={Routes.InsightsVecteezy}
          {...addTestId('vecteezy-insights-nav-link')}
        >
          {t('nav.insights.vecteezy')}
        </S.StyledNavMenuItem>
        {yourInsights && (
          <S.StyledNavMenuItem
            dest={Routes.InsightsYour}
            {...addTestId('your-insights-nav-link')}
          >
            {t('nav.insights.your')}
          </S.StyledNavMenuItem>
        )}
        <S.StyledNavMenuItem
          dest={Routes.InsightsCommunity}
          {...addTestId('community-insights-nav-link')}
        >
          {t('nav.insights.community')}
        </S.StyledNavMenuItem>
      </S.StyledNavLink>
    )
  }

  const renderEarningsNav = (): JSX.Element | null => {
    if (
      !session?.enabledFeatures.includes('contrib_earnings') ||
      session.isAdmin ||
      session.wo
    ) {
      return null
    }

    return (
      <S.StyledNavLink
        dest={Routes.Earnings}
        navigate={navigateTo}
        label={t('nav.admin.earnings.title')}
        activeTab={pathname}
        {...addTestId('nav-link-contrib-earnings')}
      >
        <S.StyledNavMenuItem dest={Routes.Earnings}>
          {t('nav.admin.earnings.title')}
        </S.StyledNavMenuItem>
        <S.StyledNavMenuItem dest={Routes.Payments}>
          {t('nav.admin.payments.title')}
        </S.StyledNavMenuItem>
      </S.StyledNavLink>
    )
  }

  const renderSubNav = (): JSX.Element | null => {
    if (
      !isAdmin ||
      ![Routes.Assets, Routes.Submitted, Routes.Flagged].includes(
        pathname as Routes
      )
    ) {
      return null
    }

    const subNavItems: SubNavItem[] = RESOURCE_NAV.map(nav => ({
      path: nav.path,
      label: t(nav.label),
      admin_count: counts ? counts[nav.count] : undefined,
    }))

    return <SubNav navItems={subNavItems} data-testid="resource-subnav" />
  }

  // this method is really only necessary while we have a hybrid app
  // come the revolution, we'll move to using plain react-router links
  const navigateTo = (path: string): void => {
    gevent('navigation', 'Header', { destination_url: path })

    if (formIsDirty) {
      const confirmNavigation = window.confirm(t('util.unsaved_changes'))
      if (!confirmNavigation) return
    }

    setFormIsDirty(false)
    if (path.match(/http/)) {
      window.open(path, '_blank')
    } else {
      navigate(path)
    }
  }

  const handleUploadClick = () => {
    gevent('upload', 'Any', { source: 'Web Nav Button' })
    navigateTo(Routes.PortfolioUpload)
  }

  if (shouldHideNav) {
    return <></>
  }

  return (
    <>
      <S.HiddenInput type="text" name="disable-chrome-username-autocomplete" />
      <S.HiddenInput
        type="password"
        name="disable-chrome-password-autocomplete"
      />

      {isMobile && !isAdmin ? (
        <MobileNav
          notifications={notifications}
          notificationsLoading={loading}
          session={session}
        />
      ) : (
        <Box mb={0}>
          <S.StyledNavRoot square elevation={0}>
            <S.StyledVecteezyLogo />

            <S.StyledNavRow display="flex" alignItems="left" width="100%">
              {isAdmin ? (
                <AdminNav
                  navigate={navigateTo}
                  session={session}
                  currentPath={pathname}
                  resourceNav={RESOURCE_NAV}
                  resourceCounts={counts}
                />
              ) : (
                <>
                  <S.StyledNavLink
                    dest="/"
                    navigate={navigateTo}
                    label={t('nav.dashboard')}
                    activeTab={pathname}
                  />

                  <S.StyledNavLink
                    dest={Routes.Portfolio}
                    navigate={navigateTo}
                    label={t('nav.portfolio')}
                    activeTab={pathname}
                  >
                    <S.StyledNavMenuItem dest={Routes.PortfolioUpload}>
                      {t('nav.upload')}
                    </S.StyledNavMenuItem>
                    <S.StyledNavMenuItem dest={Routes.PortfolioAddData}>
                      {t('nav.add_data')}
                      <ResourceCounter count={['started']} variant="chip" />
                    </S.StyledNavMenuItem>
                    <S.StyledNavMenuItem dest={Routes.PortfolioPendingReview}>
                      {t('nav.pending_review')}
                      <ResourceCounter count={['submitted']} variant="chip" />
                    </S.StyledNavMenuItem>
                    <S.StyledNavMenuItem dest={Routes.PortfolioApproved}>
                      {t('nav.approved')}
                      <ResourceCounter
                        count={['active', 'approved']}
                        variant="chip"
                      />
                    </S.StyledNavMenuItem>
                    <S.StyledNavMenuItem dest={Routes.PortfolioNotApproved}>
                      {t('nav.not_approved')}
                      <ResourceCounter
                        count={['rejected', 'needs_corrections', 'inactive']}
                        variant="chip"
                      />
                    </S.StyledNavMenuItem>
                  </S.StyledNavLink>

                  {hasDistributionSettings ? (
                    <S.StyledNavLink
                      dest={Routes.Distribution}
                      navigate={navigateTo}
                      label={t('nav.distribution.title')}
                      activeTab={pathname}
                      customComponent={
                        hasDistributionBetaLabel ? (
                          <PillLabel label={t('util.beta')} marginLeft={0.25} />
                        ) : undefined
                      }
                      {...addTestId('distribution-nav-link')}
                    >
                      <S.StyledNavMenuItem dest={Routes.DistributionManager}>
                        {t('nav.distribution.file')}
                      </S.StyledNavMenuItem>
                      <S.StyledNavMenuItem dest={Routes.DistributionSettings}>
                        {t('nav.distribution.settings')}
                      </S.StyledNavMenuItem>
                    </S.StyledNavLink>
                  ) : null}

                  {renderInsightsNav()}

                  <S.StyledNavLink
                    dest={Routes.Referrals}
                    navigate={navigateTo}
                    label={t('nav.referrals')}
                    activeTab={pathname}
                  />

                  {renderEarningsNav()}

                  {renderHelp()}
                </>
              )}
            </S.StyledNavRow>

            <Box display="flex" alignItems="center">
              {!isAdmin ? (
                <Notifications
                  notifications={notifications}
                  loading={loading}
                />
              ) : null}
              {isAdmin ? (
                <S.ReviewActionsWrapper>
                  <ReviewFileCounter />
                  {shouldShowReviewButton ? <AutoAssignButton /> : null}
                </S.ReviewActionsWrapper>
              ) : null}
              {isAdmin ? (
                <S.StyledUserSearch
                  onSelect={userSelected}
                  withLoginAs={Boolean(session?.isSuperAdmin)}
                />
              ) : (
                <S.StyledUploadButton
                  disabled={window.location.pathname === Routes.PortfolioUpload}
                  color="primary"
                  onClick={handleUploadClick}
                >
                  {t('nav.upload')}
                </S.StyledUploadButton>
              )}

              <S.StyledAvatar
                component="a"
                onClick={() => navigateTo(Routes.Profile)}
                variant="circular"
                src={session?.avatarUrl}
              />
            </Box>
          </S.StyledNavRoot>

          {renderSubNav()}
        </Box>
      )}

      <Popups session={session} popups={popups} />
    </>
  )
}

export default observer(Nav)
