import React from 'react'
import { styled } from '@mui/system'
import { css } from 'styled-components'
import { Radio, FormControlLabel, Theme } from '@mui/material'
import { themeBorderRadius, themeColor, themeSpacing } from '../../../helpers'

const Icon = styled('span')`
  border-radius: 50%;
  width: ${themeSpacing(2.5)};
  height: ${themeSpacing(2.5)};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themeColor('grey', '200')};
`

const CheckedIcon = styled(Icon)`
  &&::before {
    display: block;
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
    content: '';
  }
`
interface StyleProps {
  theme: Theme
  $buttonStyle: boolean
  $checked: boolean
}
const getValue = (props: StyleProps, key) =>
  ({
    backgroundColor: props.$buttonStyle
      ? props.$checked
        ? '#fff'
        : `${themeColor('secondary', 'pale')(props)}`
      : 'none',
    backgroundColorHover: props.$buttonStyle
      ? props.$checked
        ? '#fff'
        : `${themeColor('secondary', 'veryLight')(props)}`
      : 'none',
    border: props.$buttonStyle
      ? props.$checked
        ? `1px solid ${themeColor('secondary', 'veryLight')(props)}`
        : `1px solid ${themeColor('secondary', 'pale')(props)}`
      : 'none',
    borderHover: props.$buttonStyle
      ? props.$checked
        ? `1px solid ${themeColor('secondary', 'lighter')(props)}`
        : `1px solid ${themeColor('secondary', 'veryLight')(props)}`
      : 'none',
    borderRadius: props.$buttonStyle ? ` ${themeBorderRadius('small')}` : '0',
    color: props.$buttonStyle
      ? `${themeColor('secondary', 'main')(props)}`
      : `${themeColor('secondary', 'light')(props)}`,
    padding: props.$buttonStyle ? `${themeSpacing(1)(props)}` : '0',
    uncheckedRadioColor: props.$buttonStyle
      ? '#fff'
      : `${themeColor('secondary', 'veryLight')(props)}`,
  })[key]

export const StyledLabel = styled(FormControlLabel)<StyleProps>`
  align-items: center;
  background-color: ${props => getValue(props, 'backgroundColor')};
  border: ${props => getValue(props, 'border')};
  color: ${props => getValue(props, 'color')};
  margin: 0;
  padding: ${props => getValue(props, 'padding')};
  border-radius: ${props => getValue(props, 'borderRadius')};

  &&:hover {
    background-color: ${props => getValue(props, 'backgroundColorHover')};
    border: ${props => getValue(props, 'borderHover')};
  }

  & .MuiTypography-root {
    color: ${props => getValue(props, 'color')};
  }

  /* stylelint-disable declaration-empty-line-before, value-keyword-case */
  ${props =>
    props.$buttonStyle &&
    css`
      ${Icon} {
        background-color: ${getValue(props, 'uncheckedRadioColor')};
      }
    `}
  /* stylelint-enable declaration-empty-line-before, value-keyword-case */

  ${CheckedIcon} {
    background-color: ${themeColor('primary', 'main')};
  }
`

export const StyledRadio = styled(props => (
  <Radio checkedIcon={<CheckedIcon />} icon={<Icon />} {...props} />
))`
  &&.MuiButtonBase-root {
    margin-right: ${themeSpacing(1.5)};
    padding: 0;
  }
`
