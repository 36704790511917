import React from 'react'
import { styled } from '@mui/system'
import { Theme } from '@mui/material'

type Props = {
  children: React.ReactNode
  ratio?: string
}

type RatioArray = [string, string]

const Frame = ({ ratio = '16:9', children }: Props): JSX.Element => {
  return (
    <Wrapper ratio={ratio} data-testid="eezy-layout-frame">
      {children}
    </Wrapper>
  )
}

export default Frame

const selectRatioArray = (ratio: string): RatioArray => {
  const ratioParts = ratio.split(':', 2)

  return [ratioParts[0], ratioParts[1]]
}

const Wrapper = styled('div')<{
  theme: Theme
  ratio: string
}>`
  display: block;
  padding-bottom: ${({ ratio }) => {
    const [ratioNumerator, ratioDenominator] = selectRatioArray(ratio)
    return `calc(${ratioNumerator} / ${ratioDenominator} * 100%)`
  }};
  position: relative;

  > * {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  > img,
  > video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`
