type WidthOfText = {
  c?: HTMLCanvasElement
  ctx?: CanvasRenderingContext2D
}

// This gets the visual width of the longest text in an array of strings,
// compared using font settings from the reference element passed
const widthOfLongestTextInArray = (
  arr: string[],
  referenceEl: Element
): number => {
  const longestStr = arr.reduce((a, b) => (a.length > b.length ? a : b), '')

  const getWidthOfTextCache: WidthOfText = {}

  const getWidthOfText = (txt, fontName, fontSize, fontWeight) => {
    if (typeof getWidthOfTextCache.c === 'undefined') {
      getWidthOfTextCache.c = document.createElement('canvas')
      getWidthOfTextCache.ctx = getWidthOfTextCache.c.getContext('2d')
    }

    const fontspec = `${fontWeight} ${fontSize} ${fontName}`
    if (getWidthOfTextCache.ctx) {
      if (getWidthOfTextCache.ctx.font !== fontspec)
        getWidthOfTextCache.ctx.font = fontspec

      return getWidthOfTextCache.ctx.measureText(txt).width
    } else {
      return txt.length
    }
  }

  const refStyles = window.getComputedStyle(referenceEl)

  return getWidthOfText(
    longestStr,
    refStyles.fontFamily,
    refStyles.fontSize,
    refStyles.fontWeight
  )
}

export default widthOfLongestTextInArray
