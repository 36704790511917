import React from 'react'
import { styled } from '@mui/system'
import { Button } from '../../index'
import { themeBorderRadius, themeColor } from '../../../helpers'
import Typography from '@mui/material/Typography'

export const ToggleButton = styled(props => <Button {...props} />)`
  && {
    display: flex;
    flex-grow: 1;
    background-color: ${props =>
      props.active ? themeColor('grey', '50') : themeColor('grey', '100')};
    border: 1px solid
      ${props =>
        props.active
          ? themeColor('secondary', 'light')
          : themeColor('secondary', 'lighter')};
    color: ${props =>
      props.active ? themeColor('grey', '700') : themeColor('grey', '300')};

    :hover {
      background-color: ${props =>
        props.active ? themeColor('grey', '100') : themeColor('grey', '200')};
      color: ${props =>
        props.active ? themeColor('grey', '800') : themeColor('grey', '400')};
    }
  }
`

export const ToggleButtonContainer = styled(Typography)`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  list-style: none;
  padding: 0;
  margin: 0;

  button {
    border-radius: 0;
  }

  button:first-child {
    border-top-left-radius: ${themeBorderRadius('medium')};
    border-bottom-left-radius: ${themeBorderRadius('medium')};
  }

  button:last-child {
    border-top-right-radius: ${themeBorderRadius('medium')};
    border-bottom-right-radius: ${themeBorderRadius('medium')};
  }
`
