import { Theme } from '@mui/material'
import token from 'ez-styles/tokens/json-token.json'

export type Color = {
  main?: string
  dark?: string
  light?: string
  lighter?: string
  veryLight?: string
  pale?: string
  contrastText?: string
  '30'?: string
  '50'?: string
  '100'?: string
  '200'?: string
  '300'?: string
  '400'?: string
  '500'?: string
  '600'?: string
  '700'?: string
  '800'?: string
  '900'?: string
}

export type Palette = {
  primary: Color
  secondary: Color
  disabled: Color
  grey?: Color
  success: Color
  warning: Color
  error: Color
  overlay?: Color
}

type Setting = {
  color?: string
  fontDisplay?: string
  fontFamily?: string
  fontSize?: string
  fontStyle?: string
  fontWeight?: string
  letterSpacing?: Setting
  lineHeight?: Setting
  textTransform?: Setting
}

type Typography = {
  h1?: Setting
  h2?: Setting
  h3?: Setting
  h4?: Setting
  h5?: Setting
  h6?: Setting
  title?: Setting
  subtitle?: Setting
  subtitle1?: Setting
  subtitle2?: Setting
  body1?: Setting
  body2?: Setting
  button?: Setting
  caption?: Setting
  overline?: Setting
  navLink?: Setting
  menuItem?: Setting
  smallText?: Setting
  smallBoldText?: Setting
  mediumText?: Setting
  mediumBoldText?: Setting
  largeText?: Setting
  largeBoldText?: Setting
  vlargeText?: Setting
}

type ThemeVar = {
  borderColor: string
  borderRadius: string
}

type BorderRadius = {
  large: string
  medium: string
  small: string
  tiny: string
}

type BoxShadow = {
  mediaBlock: string
  popper: string
  popperBorder: string
}

export const themeBorderRadius = (key: keyof BorderRadius): string =>
  ({
    large: token['radius--large'],
    medium: token['radius--medium'],
    small: token['radius--small'],
    tiny: token['radius--tiny'],
  })[key]

export const themeBoxShadow =
  (key: keyof BoxShadow) =>
  (props: { theme: Theme }): string =>
    ({
      mediaBlock: '0 1px 2px rgba(0, 0, 0, 0.1)',
      popper: '0 8px 20px rgba(0, 0, 0, 0.08)',
      popperBorder: `0 0 1px ${props.theme.palette?.secondary?.pale}`,
    })[key]

export const themeColor =
  (variant: keyof Palette, type: keyof Color = 'main') =>
  (props: { theme: Theme }): string =>
    props.theme.palette?.[variant]?.[type]

export const themeSpacing =
  (spacing: number) =>
  (props: { theme: Theme }): string =>
    props.theme.spacing(spacing)

export const themeTypography =
  (variant: keyof Typography, setting: keyof Setting) =>
  (props: { theme: Theme }): string =>
    props.theme.typography?.[variant]?.[setting]

export const themeVar =
  (variable: keyof ThemeVar) =>
  (props: { theme: Theme }): string =>
    props.theme[variable]
