import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Badge from 'components/Badge'
import { Button, Modal, themeSpacing } from 'eezy-components'
import cookie from 'js-cookie'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Challenge } from 'types'

type Props = {
  challenge: Challenge
}

const ChallengeModal = ({ challenge }: Props): JSX.Element => {
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(
    !cookie.get(`challenge-modal-${challenge.id}`)
  )

  const onClose = () => {
    cookie.set(`challenge-modal-${challenge.id}`, 'true', {
      expires: 3650,
    })
    setOpen(false)
  }

  return (
    <StyledModal modalOpen={isOpen} setModalOpen={onClose}>
      <Modal.Header>{t('challenges.popup.congratulations')}</Modal.Header>

      <Modal.Body>
        <div>
          <Typography variant="largeText" sx={{ color: 'secondary.light' }}>
            {t('challenges.popup.completed')}
          </Typography>
        </div>

        <div>
          <Typography variant="largeBoldText" sx={{ color: 'secondary.light' }}>
            {challenge.name}
          </Typography>
        </div>

        <StyledBadge challenge={challenge} animate />
      </Modal.Body>

      <Modal.Actions>
        <Button color="primary" onClick={onClose}>
          {t('close_window')}
        </Button>
      </Modal.Actions>
    </StyledModal>
  )
}

export default ChallengeModal

const StyledModal = styled(Modal)`
  & .MuiPaper-root {
    text-align: center;
  }
`

const StyledBadge = styled(Badge)`
  margin: ${themeSpacing(6)} auto;
`
