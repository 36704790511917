import React from 'react'
import * as S from './styled'

type Props = {
  values: string[]
  onSelect: (value: string) => void
  selectedValue: string
}

const ButtonToggle = ({
  values,
  selectedValue,
  onSelect,
}: Props): JSX.Element => {
  return (
    <S.ToggleButtonContainer>
      {values.map(val => (
        <S.ToggleButton
          key={val}
          active={val === selectedValue}
          onClick={() => onSelect(val)}
        >
          {val}
        </S.ToggleButton>
      ))}
    </S.ToggleButtonContainer>
  )
}

export default ButtonToggle
