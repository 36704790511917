import { Typography } from 'eezy-components'
import { FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

const Fallback = ({ error }: FallbackProps) => {
  const { t } = useTranslation()

  const handleHardReload = async () => {
    await fetch(window.location.href, {
      cache: 'no-cache',
    }).then(response => {
      if (response.ok) {
        window.location.reload()
      }
    })
  }

  return (
    <S.Container>
      <Typography variant="h5">{t('error')}</Typography>
      <Typography variant="body1">{error.message}</Typography>
      <S.ResetButton color="primary" onClick={handleHardReload}>
        {t('util.try_again')}
      </S.ResetButton>
    </S.Container>
  )
}

export default Fallback
