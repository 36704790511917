import { GetApiV1FinancePayoutsQueryParams } from 'api'
import { Routes } from 'constants/index'
import { initFilters } from 'helpers/filters'
import { removeEmpty } from 'helpers/objects'
import { action, makeObservable, observable } from 'mobx'
import { BatchStatus } from 'pages/earnings/payouts/constants'
import qs from 'qs'

export default class EarningsPayoutsStore {
  defaultFilters: GetApiV1FinancePayoutsQueryParams = {
    batch_id: [],
    payment_method: [],
    state: [BatchStatus.Success, BatchStatus.Processing, BatchStatus.Failed],
    amount_cents: [],
    fees_cents: [],
    created_at: [],
    total_invoice_count: [],
    paid_invoice_count: [],
    unpaid_invoice_count: [],
    error_count: [],
  }

  @observable filters: GetApiV1FinancePayoutsQueryParams =
    initFilters<GetApiV1FinancePayoutsQueryParams>(this.defaultFilters)

  constructor() {
    makeObservable(this)
  }

  @action setFilters = (
    filters: GetApiV1FinancePayoutsQueryParams,
    syncUrl = false
  ): void => {
    this.filters = filters

    if (syncUrl) {
      const url =
        Routes.EarningsPayouts +
        qs.stringify(removeEmpty(this.filters), {
          encode: false,
          arrayFormat: 'comma',
          addQueryPrefix: true,
        })
      history.pushState({}, '', url)
    }
  }

  @action resetFilters = (): void => {
    this.setFilters(this.defaultFilters, true)
  }
}
