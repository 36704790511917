import React from 'react'
import { themeColor, themeSpacing } from '../../../helpers'
import { styled } from '@mui/system'
import Tooltip from '@mui/material/Tooltip'
import _InfoIcon from 'ez-styles/assets/icons/circle/circle-question.svg'

export const StyledTooltip = styled(props => (
  <Tooltip
    classes={{ popper: props.className, tooltip: 'tooltip', arrow: 'arrow' }}
    {...props}
  />
))`
  & .tooltip {
    display: ${({ disabled }) => (disabled ? 'none' : '')};
    background-color: ${props =>
      props.type == 'error'
        ? themeColor('error', 'main')
        : themeColor('secondary', 'main')};
    color: ${props =>
      props.type == 'error' ? '#fff' : themeColor('secondary', 'lighter')};
    padding: ${themeSpacing(1.5)} ${themeSpacing(2)};
    max-width: ${themeSpacing(50)};

    &.MuiTooltip-tooltipPlacementLeft {
      & .arrow {
        margin-top: 2px;
      }
    }
  }

  && .arrow {
    &::before {
      background-color: ${props =>
        props.type == 'error'
          ? themeColor('error', 'main')
          : themeColor('secondary', 'main')};
    }
  }
`

export const TooltipInner = styled('div')`
  font-weight: 300;
`

export const TooltipContent = styled('div')<{
  $isModal?: boolean
}>`
  padding: ${themeSpacing(2)};
  background-color: ${props =>
    props.$isModal ? '#fff' : themeColor('secondary', 'main')};
`

export const TriggerContainer = styled('span')<{
  $fullWidth: boolean
}>`
  display: inline-flex;
  width: ${props => (props.$fullWidth ? '100%' : 'auto')};
`

export const InfoIcon = styled(_InfoIcon)`
  height: ${themeSpacing(2)};
  fill: ${themeColor('secondary', 'light')};
  flex-shrink: 0;
  width: ${themeSpacing(2)};

  &:hover {
    fill: ${themeColor('secondary', 'main')};
  }
`
