import { styled } from '@mui/system'

import {
  themeColor,
  themeSpacing,
  themeTypography,
} from '../../../helpers/themeHelpers'

export const NavLinkRoot = styled('div')`
  align-items: center;
  display: flex;
  height: 100%;
`

export const StyledNavLink = styled('a')`
  &,
  &:link,
  &:visited {
    align-items: center;
    color: ${themeColor('secondary', 'main')};
    display: flex;
    font-size: ${themeTypography('navLink', 'fontSize')};
    font-weight: ${themeTypography('navLink', 'fontWeight')};
    height: 100%;
    padding-left: ${themeSpacing(2)};
    padding-right: ${themeSpacing(2)};
    position: relative;
    z-index: 1;

    svg {
      fill: ${themeColor('secondary', 'main')};
    }
  }

  &::after {
    background-color: ${themeColor('secondary', 'lighter')};
    bottom: -1px;
    content: '';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    visibility: hidden;
    width: 100%;
    z-index: 1;
  }

  &:hover {
    color: ${themeColor('secondary', 'light')};

    svg {
      fill: ${themeColor('secondary', 'light')};
    }
  }

  &.active {
    color: ${themeColor('secondary', 'main')};

    svg {
      fill: ${themeColor('secondary', 'main')};
    }
  }

  &:hover::after,
  &.active::after {
    visibility: visible;
  }

  &.active {
    &::after {
      background-color: ${themeColor('primary', 'main')};
    }
  }

  svg {
    fill: ${themeColor('secondary', 'light')};
    height: 5px;
    margin-left: ${themeSpacing(1)};
    width: ${themeSpacing(1)};
  }
`
