import React, { ReactNode } from 'react'
import InputWrapper, { InputWrapperProps } from '../InputWrapper'
import RadioInput from '../RadioInput'
import { RadioGroup as MuiRadioGroup } from '@mui/material'
import { Grid } from '../../layouts'

type RadioOption = {
  label: string
  value: string
}

type Props = {
  buttons?: boolean
  className?: string
  fullWidth?: boolean
  helperText?: string
  label?: string
  labelAsPlaceholder?: boolean
  labelCaption?: string
  labelShrink?: boolean
  onChange?: (value: string) => void
  options: RadioOption[]
  placeholder?: string | boolean
  required?: boolean
  selectedValue: string
  tooltipContent?: ReactNode
  disabled?: boolean
}

const RadioGroup = ({
  buttons,
  className,
  fullWidth,
  label,
  labelCaption,
  labelShrink,
  helperText,
  selectedValue,
  options,
  onChange,
  required,
  tooltipContent,
  disabled,
}: Props): JSX.Element => {
  const inputWrapperProps: InputWrapperProps = {
    fullWidth,
    label,
    labelCaption,
    labelShrink,
    helperText,
    tooltipContent,
    required,
  }

  const handleChange = value => {
    if (onChange) onChange(value)
  }

  return (
    <InputWrapper className={className} {...inputWrapperProps}>
      <MuiRadioGroup data-testid="radio-group">
        <Grid min={200}>
          {options.map(({ value, label }) => (
            <RadioInput
              key={value}
              buttonStyle={buttons}
              value={value}
              checked={value === selectedValue}
              onChange={() => handleChange(value)}
              label={label}
              disabled={disabled}
            />
          ))}
        </Grid>
      </MuiRadioGroup>
    </InputWrapper>
  )
}

export default RadioGroup
