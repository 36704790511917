import {
  themeBorderRadius,
  themeBoxShadow,
  themeColor,
  themeSpacing,
  themeTypography,
  themeVar,
} from './themeHelpers'
import widthOfLongestTextInArray from './widthOfLongestTextInArray'

export {
  themeBorderRadius,
  themeBoxShadow,
  themeColor,
  themeSpacing,
  themeTypography,
  themeVar,
  widthOfLongestTextInArray,
}

export const genRandom = (len: number): string => {
  return Math.random()
    .toString(36)
    .substring(2, 2 + len)
}
