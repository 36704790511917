import { styled } from '@mui/material/styles'
import { MenuItem } from '@mui/material'
import { Button } from '../index'
import MenuPopper from '../util/MenuPopper'
import _ArrowSmallDown from 'ez-styles/assets/icons/arrows/arrow-small-down.svg'
import { themeColor, themeSpacing, themeTypography } from '../../helpers'

export const StyledButton = styled(Button)<{ $outlined: boolean }>`
  border: ${props =>
    props.$outlined
      ? `1px solid ${themeColor('secondary', 'veryLight')(props)}`
      : 'none'};
  font-size: ${themeTypography('mediumText', 'fontSize')};
  font-weight: ${themeTypography('mediumText', 'fontWeight')};
  min-width: unset;
  justify-content: flex-start;
  text-align: left;
  flex-wrap: nowrap;

  &.open {
    background: #fff;
  }
`

export const StyledLabel = styled('span')`
  white-space: nowrap;
  font-weight: ${themeTypography('mediumBoldText', 'fontWeight')};
`

export const ArrowSmallDown = styled(_ArrowSmallDown)`
  && {
    height: 5px;
    flex-basis: auto;
    flex-shrink: 0;
    margin-left: ${themeSpacing(2)};
    margin-right: 0;
    width: ${themeSpacing(1)};
  }
`

export const StyledMenuPopper = styled(MenuPopper)`
  & > .MuiPaper-root {
    min-width: 0;
    width: auto;
  }
`

export const StyledMenuItem = styled(MenuItem)`
  color: ${themeColor('secondary', 'main')};
  display: flex;
  font-size: ${themeTypography('menuItem', 'fontSize')};
  font-weight: ${themeTypography('menuItem', 'fontWeight')};
  justify-content: space-between;
  height: ${themeSpacing(6)};
  width: 100%;
`
