import { Resource } from 'api'

export function filenameFromUrl(url?: string): string {
  return url?.split('/')?.pop() || ''
}

export function fileFormatFromUrl(url?: string): string {
  return url?.split('.')?.pop() || ''
}

export const bytesToGigabytes = (bytes: number, decimals = 2): number => {
  if (bytes === 0) return 0
  return parseFloat((bytes / Math.pow(1024, 3)).toFixed(decimals))
}

export const getFileType = (source: Resource['source_file']): string => {
  if (!source) {
    return ''
  }

  if (source.content_type === 'application/postscript') {
    if (source.url?.endsWith('.eps')) {
      return 'EPS'
    } else if (source.url?.endsWith('.ai')) {
      return 'AI'
    }
  }

  return source.content_type ?? ''
}
