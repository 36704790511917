import { styled } from '@mui/system'
import IconContainer from '../../IconContainer'
import _HideIcon from 'ez-styles/assets/icons/eye-crossed.svg'
import _ShowIcon from 'ez-styles/assets/icons/eye.svg'
import { themeColor } from '../../../helpers'

export const HideShowControl = styled(IconContainer)`
  position: position;
  z-index: 1;

  & svg {
    fill: ${themeColor('secondary', 'light')};
  }
`

export const HideIcon = styled(_HideIcon)`
  height: 12px;
  width: 15px;
`

export const ShowIcon = styled(_ShowIcon)`
  height: 12px;
  width: 15px;
`
