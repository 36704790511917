import { styled } from '@mui/system'
import { themeColor, themeSpacing } from '../../helpers/themeHelpers'
import { Theme } from '@mui/material'

export const Container = styled('span')<{ theme: Theme; $outlined: boolean }>`
  display: block;
  width: ${themeSpacing(1)};
  height: ${themeSpacing(1)};
  background-color: ${themeColor('warning', 'light')};
  border-radius: 50%;

  /* stylelint-disable declaration-empty-line-before, value-keyword-case */
  ${props => (props.$outlined ? 'border: 1px solid rgba(0,0,0,0.2);' : '')}
`
