'use client'

import { captureError } from 'helpers/error'
import { ReactNode } from 'react'
import { ErrorBoundary as ErrorHandler } from 'react-error-boundary'
import Fallback from './Fallback'

type Props = {
  children: ReactNode
}

const ErrorBoundary = ({ children }: Props) => {
  return (
    <ErrorHandler
      FallbackComponent={Fallback}
      onError={error => {
        captureError(error, 'Error Boundary Trigger - ErrorBoundary/index.tsx')
      }}
    >
      {children}
    </ErrorHandler>
  )
}

export default ErrorBoundary
