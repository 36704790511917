import {
  Badge,
  CircularProgress,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  Button,
  Modal,
  themeBoxShadow,
  themeColor,
  themeSpacing,
} from 'eezy-components'
import { MobileProps } from 'helpers/hooks'
import cookie from 'js-cookie'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ContributorNotification } from 'api'

import { gevent } from 'helpers/gtag'

const NOTIFICATION_COOKIE_STRING = 'cm_notification'

type Props = {
  notifications: ContributorNotification[] | null
  loading: boolean
  isMobile?: boolean
}

const Notifications = ({
  notifications,
  loading,
  isMobile,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const [notificationsOpen, setNotificationsOpen] = useState(false)
  const [notificationsModalOpen, setNotificationsModalOpen] = useState(false)
  const [unreadNotificationCount, setUnreadCount] = useState(0)
  const anchorRef = useRef(null)

  useEffect(() => {
    if (notifications?.length) {
      const unreadNotificationsCount = notifications?.filter(
        notification =>
          cookie.get(`${NOTIFICATION_COOKIE_STRING}${notification.id}`) ===
          undefined
      ).length
      setUnreadCount(unreadNotificationsCount || 0)
    }
  }, [notifications])

  const openNotifications = () => {
    notifications?.forEach(notification =>
      cookie.set(`${NOTIFICATION_COOKIE_STRING}${notification.id}`, 'true', {
        expires: 3650,
      })
    )
    setUnreadCount(0)

    gevent('notifications', 'Header', { link_label: 'Notifications' })

    if (isMobile) {
      setNotificationsModalOpen(true)
    } else {
      setNotificationsOpen(true)
    }
  }

  const openNotification = (notification: ContributorNotification) => {
    gevent('notifications', 'Header', {
      notification_title: notification.title,
    })
    window.open(
      notification.url,
      notification.new_tab ? '_blank' : undefined,
      notification.new_tab ? 'noreferrer, nofollow' : undefined
    )
  }

  const renderNotificationsList = () => {
    if (loading) {
      return (
        <NoData>
          <CircularProgress />
        </NoData>
      )
    } else if (notifications?.length) {
      return (
        <NotificationList $isMobile={isMobile}>
          {notifications.map((notification, i) => (
            <Notification key={`${notification.id}-${i}`}>
              {notification?.image_url ? (
                <Image src={notification?.image_url} />
              ) : null}

              <Typography variant="largeBoldText">
                {notification?.title}
              </Typography>

              {notification?.url && notification?.button_text ? (
                <>
                  <NotificationButton
                    color="primary"
                    onClick={() => openNotification(notification)}
                  >
                    {notification?.button_text}
                  </NotificationButton>
                  <ContainerLink
                    href={notification?.url}
                    aria-hidden
                    {...(notification?.new_tab
                      ? {
                          target: '_blank',
                          rel: 'noreferrer nofollow',
                        }
                      : {})}
                  >
                    <FillParent />
                  </ContainerLink>
                </>
              ) : null}
            </Notification>
          ))}
        </NotificationList>
      )
    } else {
      return (
        <NoData>
          <Typography variant="h6">{t('notifications.empty')}</Typography>
        </NoData>
      )
    }
  }

  return (
    <>
      <StyledNotificationButton
        aria-label="notifications"
        ref={anchorRef}
        onClick={openNotifications}
        testId="notification-button"
      >
        <NotificationBadge color="error" badgeContent={unreadNotificationCount}>
          <svg
            width="20"
            height="24"
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.00011 18C2.20011 18 1.70011 17 2.20011 16.4L2.60011 15.9C4.00011 14.2 5.00012 12.2 5.00012 10.1V9C5.00012 6.2 7.20012 4 10.0001 4C12.8001 4 15.0001 6.2 15.0001 9V10.1C15.0001 12.2 16.0001 14.2 17.4001 15.9L17.8001 16.4C18.3001 17 17.8001 18 17.0001 18H3.00011ZM8.00012 20C8.00012 21.1 8.90012 22 10.0001 22C11.1001 22 12.0001 21.1 12.0001 20H8.00012ZM10.0001 0C10.6001 0 11.0001 0.4 11.0001 1V2.1C14.4001 2.6 17.0001 5.5 17.0001 9V10.1C17.0001 11.7 17.9001 13.3 18.9001 14.6L19.3001 15.1C20.9001 17.1 19.5001 20 17.0001 20H14.0001C14.0001 22.2 12.2001 24 10.0001 24C7.80012 24 6.00012 22.2 6.00012 20H3.00011C0.500114 20 -0.899886 17 0.700114 15.1L1.10011 14.6C2.10011 13.3 3.00012 11.8 3.00012 10.1V9C3.00012 5.5 5.60012 2.6 9.00012 2.1V1C9.10012 0.4 9.40012 0 10.0001 0Z"
              fill="#0E2332"
            />
          </svg>
        </NotificationBadge>
      </StyledNotificationButton>
      {isMobile ? (
        <Modal
          size="large"
          modalOpen={notificationsModalOpen}
          setModalOpen={setNotificationsModalOpen}
          data-testid="notifications-modal"
        >
          <Modal.Header>{t('notifications.title')}</Modal.Header>
          {renderNotificationsList()}
        </Modal>
      ) : (
        <Popper
          open={notificationsOpen}
          anchorEl={anchorRef.current}
          placement="bottom-end"
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 999 }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'right top',
              }}
            >
              <NotificationWindow data-testid="notification-window">
                <ClickAwayListener
                  onClickAway={() => setNotificationsOpen(false)}
                >
                  {renderNotificationsList()}
                </ClickAwayListener>
              </NotificationWindow>
            </Grow>
          )}
        </Popper>
      )}
    </>
  )
}

export default Notifications

const NoData = styled('div')`
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 ${themeSpacing(2)};
`

const StyledNotificationButton = styled(Button)`
  min-width: 0;
  padding: ${themeSpacing(1)} ${themeSpacing(2)};
  &.default {
    background: #fff;
  }
`

const NotificationBadge = styled(Badge)`
  & .MuiBadge-badge {
    font-size: ${themeSpacing(1.25)};
    font-weight: bold;
    border: 2px solid #fff;
  }
`

const NotificationWindow = styled(Paper)`
  border: 1px solid ${themeColor('grey', '100')};
  box-shadow: ${themeBoxShadow('popper')};
  margin-top: ${themeSpacing(1)};
  width: 310px;
  position: relative;
`

const NotificationList = styled('div')<MobileProps>`
  overflow-y: auto;
  max-height: ${(props: MobileProps) =>
    props.$isMobile ? '100%' : themeSpacing(84)}
  &:after {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    content: '';
    position: fixed;
    display: block;
    z-index: 1;
    height: ${themeSpacing(3)};
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const Notification = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing(2)};
  padding: ${themeSpacing(3)};
  position: relative;

  &:not(:first-child) {
    border-top: 1px solid ${themeColor('grey', '100')};
  }
`

const Image = styled('img')`
  width: 100%;
  height: auto;
`

const FillParent = styled('span')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const ContainerLink = styled('a')`
  opacity: 0;
`

const NotificationButton = styled(Button)`
  overflow: hidden;
`
