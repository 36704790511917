import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import { themeBorderRadius, themeColor, themeSpacing } from '../../../helpers'
import _EditIcon from 'ez-styles/assets/icons/edit/edit-light.svg'
import LoadingSpinner from '../../LoadingSpinner'

export const UploaderButton = styled('div')`
  align-items: center;
  background-color: ${themeColor('secondary', 'pale')};
  border-radius: ${themeBorderRadius('small')};
  cursor: pointer;
  display: flex;
  height: ${themeSpacing(5)};
  padding: ${themeSpacing(1.5)} ${themeSpacing(1.75)};
`

export const UploaderValue = styled(Typography)`
  color: ${themeColor('secondary', 'main')};
  line-height: 1;
  margin-right: ${themeSpacing(2)};
  max-width: calc(100% - 28px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const EditIcon = styled(_EditIcon)`
  fill: ${themeColor('secondary', 'main')};
  height: ${themeSpacing(1.5)};
  margin-left: auto;
  width: ${themeSpacing(1.5)};
`

export const CustomIcon = styled('div')`
  margin-left: auto;

  svg {
    fill: ${themeColor('secondary', 'main')};
    height: ${themeSpacing(1.5)};
    width: ${themeSpacing(1.5)};
  }
`

export const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-left: auto;
`
