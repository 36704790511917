import { styled } from '@mui/material/styles'

import {
  Avatar,
  Button,
  NavLink,
  NavMenuItem,
  themeColor,
  themeSpacing,
  VecteezyLogo,
} from 'eezy-components'

import { Box, Paper } from '@mui/material'

import UserSearch from '../components/UserSearch'

export const StyledNavRoot = styled(Paper)`
  align-items: center;
  border-bottom: 1px solid ${themeColor('secondary', 'veryLight')};
  display: flex;
  height: ${themeSpacing(10)};
  justify-content: space-between;
  padding-left: ${themeSpacing(4)};
  padding-right: ${themeSpacing(4)};
  position: relative;
  flex: 1;
`

export const StyledNavRow = styled(Box)`
  height: 100%;

  & > div {
    min-width: 0;
  }
`

export const StyledUploadButton = styled(Button)`
  margin-left: ${themeSpacing(2)};
  margin-right: ${themeSpacing(4)};
`

export const StyledUserSearch = styled(UserSearch)`
  margin-right: ${themeSpacing(2)};
`

export const StyledVecteezyLogo = styled(VecteezyLogo)`
  color: ${themeColor('primary', 'main')};
  fill: ${themeColor('primary', 'main')};
  flex-shrink: 0;
  height: ${themeSpacing(5)};
  margin-right: ${themeSpacing(4)};
  width: ${themeSpacing(21)};
`

export const StyledNavLink = styled(NavLink)`
  margin-left: ${themeSpacing(1)};
  margin-right: ${themeSpacing(1)};
`

export const StyledNavMenuItem = styled(NavMenuItem)`
  &.with-chip {
    display: flex;
    justify-content: space-between;
  }
`

export const HiddenInput = styled('input')`
  display: none;
  width: 0px;
  height: 0px;
  border: none;
`
export const StyledAvatar = styled(Avatar)`
  &:hover {
    cursor: pointer;
  }
`

export const ReviewActionsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${themeSpacing(1)};
  margin-right: ${themeSpacing(1)};

  & > * {
    flex-shrink: 0; /* Prevent child items from shrinking */
  }
`
