import * as S from './styled'

type Props = {
  label: string
  marginLeft?: number
  marginRight?: number
}

const PillLabel = ({ label, marginLeft, marginRight }: Props) => {
  return (
    <S.Container $marginLeft={marginLeft} $marginRight={marginRight}>
      {label}
    </S.Container>
  )
}

export default PillLabel
