import React, { useState, CSSProperties } from 'react'
import * as S from './styled'

type Props = {
  tag?: string
  index: number
  removeTag?: (word: string) => void
  className?: string
  hasError?: boolean
  style?: CSSProperties
  isRequired?: boolean
}

const Tag = ({
  index,
  className,
  tag,
  removeTag,
  hasError,
  style,
  isRequired,
}: Props): JSX.Element => {
  const [isHovered, setHovered] = useState(false)

  const onMouseOver = () => {
    if (isRequired) {
      return
    }

    setHovered(true)
  }

  const onMouseOut = () => {
    setHovered(false)
  }

  return (
    <S.StyledTag
      className={className}
      data-testid="tag"
      $hasError={hasError}
      $isHovered={isHovered}
      $index={index}
      style={style}
    >
      {index <= 4 ? <S.TagIndex>{index + 1}</S.TagIndex> : null}
      <S.TagText variant="body1">{tag}</S.TagText>
      {removeTag && (
        <S.CloseIcon
          data-testid="tag-remove"
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClick={removeTag}
        />
      )}
    </S.StyledTag>
  )
}

export default Tag
