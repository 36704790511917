import { styled } from '@mui/system'
import { Checkbox, FormControlLabel } from '@mui/material'
import { themeBorderRadius, themeColor, themeSpacing } from '../../../helpers'
import { css } from 'styled-components'
import _CheckmarkIcon from 'ez-styles/assets/icons/checkmarks/checkmark-small.svg'

export const Icon = styled('span')`
  border-radius: ${themeBorderRadius('tiny')};
  width: ${themeSpacing(2.5)};
  height: ${themeSpacing(2.5)};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themeColor('secondary', 'veryLight')};
`

export const CheckedIcon = styled(Icon)`
  background-color: ${themeColor('primary', 'main')};
`

export const CheckmarkIcon = styled(_CheckmarkIcon)`
  fill: #fff;
  height: 7px;
  position: relative;
  top: 1px;
  width: 10px;
`

export const StyledCheckbox = styled(Checkbox)<{
  $shouldPreventClick?: boolean
}>`
  &&.MuiButtonBase-root {
    padding: 0;
  }

  &.Mui-disabled {
    .checkbox-checked,
    .checkbox-unchecked {
      background-color: ${themeColor('secondary', 'veryLight')};
      color: ${themeColor('secondary', 'veryLight')};
      cursor: not-allowed;

      svg {
        fill: ${themeColor('grey', '300')};
      }
    }
  }

  ${props =>
    props.$shouldPreventClick
      ? css`
          &&.MuiButtonBase-root {
            cursor: not-allowed;
          }
        `
      : ''}
`

export const StyledFormControlLabel = styled(FormControlLabel)`
  && .MuiFormControlLabel-label {
    margin-left: ${themeSpacing(1.5)};
  }
`
