import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import Tag from '../Tag'

type Props = {
  tag: string
  index: number
  removeTag?: (tag: string) => void
  className?: string
  hasError?: boolean
}

const SortableTag = ({ tag, ...rest }: Props): JSX.Element => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: tag })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <li ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Tag tag={tag} {...rest} />
    </li>
  )
}

export default SortableTag
