import React from 'react'
import * as S from './styled'

type ChecklistOpts = {
  value?: string
  checked?: boolean
  onCheck?: (checked: boolean) => void
  greyOnChecked?: boolean
}

type Props = {
  className?: string
  onClick?: (event) => void
  checklistOpts?: ChecklistOpts
  children?: React.ReactNode | React.ReactNode[]
}

const ListItemBlock = ({
  children,
  className,
  onClick,
  checklistOpts,
}: Props): JSX.Element => {
  const checkboxClick = () => {
    if (checklistOpts?.onCheck) {
      checklistOpts.onCheck(!checklistOpts?.checked)
    }
  }

  return (
    <S.ListItem
      className={className}
      data-testid="list-item-block"
      onClick={checklistOpts ? checkboxClick : onClick}
    >
      {checklistOpts && (
        <S.StyledCheckboxInput
          value={checklistOpts.value}
          checked={Boolean(checklistOpts?.checked)}
          $greyOnChecked={checklistOpts?.greyOnChecked}
        />
      )}

      {children}
    </S.ListItem>
  )
}

export default ListItemBlock
