import React from 'react'
import { css } from 'styled-components'
import { styled } from '@mui/system'
import { Theme } from '@mui/material'

type Props = {
  children: React.ReactNode
  recursive?: boolean
  splitAfter?: number | null
  space?: number
  testId?: string
}

const Stack = ({
  space = 1.5,
  splitAfter = null,
  recursive,
  children,
  testId,
}: Props): JSX.Element => {
  return (
    <Wrapper
      space={space}
      splitAfter={splitAfter}
      recursive={!!recursive}
      data-testid={testId ? testId : 'eezy-layout-stack'}
    >
      {children}
    </Wrapper>
  )
}

export default Stack

const Wrapper = styled('div')<{
  theme: Theme
  recursive: boolean
  space: number
  splitAfter?: number
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${({ recursive, space, theme }) => {
    const spaceText = theme.spacing(space)
    const text = recursive
      ? `* + * { margin-top: ${spaceText}; }`
      : `> * + * { margin-top: ${spaceText}; }`
    return text
  }}

  ${({ splitAfter }) =>
    splitAfter
      ? css`
          .stack-l > :nth-child(${splitAfter}) {
            margin-bottom: auto;
          }
        `
      : ''}
`
