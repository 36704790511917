export enum AccountStatus {
  Approved = 'approved',
  Rejected = 'rejected',
  Unapproved = 'unapproved',
  Closed = 'closed',
  Locked = 'locked',
}

export enum LockedReason {
  ContentAuthenticityRequest = 'content_authenticity_request',
  Banned = 'banned_bad_actor',
  TerminatedEmployee = 'terminated_employee',
  DuplicateAccount = 'duplicate_account',
  Other = 'other',
  None = 'none',
}

export enum PaymentMethod {
  PayPal = 'paypal',
  Payoneer = 'payoneer',
  WesternUnion = 'western_union',
}

export enum PaymentAccountState {
  Unique = 'unique',
  Duplicate = 'duplicate',
  AllowedDuplicate = 'allowed_duplicate',
}
