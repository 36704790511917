import { styled } from '@mui/material/styles'
import { useGetApiV1UiMessagesKey, User } from 'api'
import { IdVerificationStatus } from 'constants/id-verification'
import { Modal } from 'eezy-components'
import cookie from 'js-cookie'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import _HeroImage from './images/id-hero2x.png'
import _ReviewingHeroImage from './images/pending.png'
import _ApprovedHeroImage from './images/success.png'

import { Session } from 'types'
import PersonaCard from './PersonaCard'

type Props = {
  user: User
  session: Session
}

export const PersonaModal = ({ user, session }: Props): JSX.Element => {
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(
    !cookie.get(`persona-modal-${user.id_inquiry_status}`)
  )

  const onClose = () => {
    cookie.set(`persona-modal-${user.id_inquiry_status}`, 'true', {
      expires: 3650,
    })
    setOpen(false)
  }

  const { data: modalReviewing, loading: modalReviewingLoading } =
    useGetApiV1UiMessagesKey({
      key: 'id-verification-reviewing',
    })

  const { data: modalApproved, loading: modalApprovedLoading } =
    useGetApiV1UiMessagesKey({
      key: 'id-verification-approved',
    })

  let message = t('persona.modal.reviewing')
  let image = _HeroImage

  if (
    session.enabledFeatures.includes('id_verification_v2_messaging') &&
    !modalReviewingLoading &&
    modalReviewing
  ) {
    message = modalReviewing?.copy || ''
    image = _ReviewingHeroImage
  }

  if (
    [IdVerificationStatus.Overridden, IdVerificationStatus.Approved].includes(
      user.id_inquiry_status as IdVerificationStatus
    )
  ) {
    message = t('persona.modal.approved')

    if (
      session.enabledFeatures.includes('id_verification_v2_messaging') &&
      !modalApprovedLoading &&
      modalApproved
    ) {
      message = modalApproved?.copy || ''
      image = _ApprovedHeroImage
    }
  }

  return (
    <StyledModal size="small" modalOpen={isOpen} setModalOpen={onClose}>
      <PersonaCard
        type="modal"
        message={message}
        buttonText={t('util.close')}
        onClick={onClose}
        heroImage={image}
      />
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  & .MuiPaper-root {
    padding: 0;
  }
`
