import React, {
  ClipboardEventHandler,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import InputWrapper, { InputWrapperProps } from '../InputWrapper'
import { Typography } from '@mui/material'
import { ThemedTooltip } from '../../util'
import * as S from './styled'

type Props = {
  className?: string
  label?: string
  addTagCallback?: (word: string) => void
  removeTagCallback?: (word: string) => void
  dragTagCallback?: (from: number, to: number) => void
  labelAsPlaceholder?: boolean
  labelShrink?: boolean
  labelCaption?: string
  placeholder?: string
  required?: boolean
  tooltipContent?: ReactNode
  helperText?: string
  tags?: string[]
  value?: string
  setValue?: (string) => void
  hasError?: (tag: string, idx: number) => boolean
  draggable?: boolean
  errorMessage?: string
  disabled?: boolean
}

const Tagger = ({
  className,
  label,
  labelCaption,
  labelShrink,
  helperText,
  labelAsPlaceholder,
  placeholder,
  required,
  tooltipContent,
  addTagCallback,
  removeTagCallback,
  dragTagCallback,
  draggable,
  tags,
  hasError,
  errorMessage,
  value: passedValue,
  setValue: setPassedValue,
  disabled,
  ...taggerProps
}: Props): JSX.Element => {
  const inputWrapperProps: InputWrapperProps = {
    label,
    labelCaption,
    labelShrink,
    labelAsPlaceholder,
    helperText,
    tooltipContent,
    required,
  }

  const inputRef = React.createRef()
  const [value, setValue] = useState('')
  const [tagsArr, setTagsArray] = useState(tags || [])

  useEffect(() => {
    setTagsArray(tags ? [...tags] : [])
  }, [tags])

  useEffect(() => {
    setValue(passedValue)
  }, [passedValue])

  const updateValue = value => {
    setValue(value)
    setPassedValue && setPassedValue(value)
  }

  const onChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = event => {
    const word = (event.target as HTMLInputElement).value

    if (word === ',') {
      return updateValue('')
    }

    updateValue(word)
  }

  const defaultAddTag = (word: string) => {
    setTagsArray(tagsArr => [...tagsArr, word])
  }

  const defaultRemoveTag = (word: string) => {
    const tempTagsArr = tagsArr
    const idx = tempTagsArr.indexOf(word)

    if (idx > -1) {
      tempTagsArr.splice(idx, 1)
    }

    setTagsArray(() => [...tempTagsArr])
  }

  const defaultDragTag = (from: number, to: number) => {
    const arr = tagsArr.slice()
    const el = arr[from]
    arr.splice(from, 1)
    arr.splice(to, 0, el)
    setTagsArray(arr)
  }

  const addTag = (word: string) => {
    addTagCallback ? addTagCallback(word) : defaultAddTag(word)
  }

  const removeTag = (word: string) => {
    removeTagCallback ? removeTagCallback(word) : defaultRemoveTag(word)
  }

  const dragTag = (from: number, to: number) => {
    dragTagCallback ? dragTagCallback(from, to) : defaultDragTag(from, to)
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key

    if (value && (key === 'Enter' || key === ',')) {
      const word = value.indexOf(',') > -1 ? value.slice(0, -1) : value
      addTag(word)
      updateValue('')
    } else if (!value && key === 'Backspace') {
      const resourceTags = addTagCallback && tags?.length ? tags : tagsArr

      if (!resourceTags || resourceTags.length <= 0) {
        return
      }

      const tagToRemove = resourceTags[resourceTags.length - 1]

      if (tagToRemove) {
        removeTag(tagToRemove)
      }
    }
  }

  const onPaste: ClipboardEventHandler = event => {
    event.preventDefault()
    event.stopPropagation()
    event.clipboardData
      .getData('Text')
      .trim()
      .split(',')
      .filter(x => !!x)
      .forEach(word => addTag(word))
  }

  const tagsErrorStatus: boolean =
    hasError && tagsArr.some((tag, idx) => hasError(tag, idx))
  const tagsErrorMessage: string = errorMessage || 'No Special Characters'

  return (
    <InputWrapper
      className={className}
      error={tagsErrorStatus}
      {...inputWrapperProps}
    >
      <ThemedTooltip
        type="error"
        placement="top"
        tooltipContent={tagsErrorMessage}
        disabled={!tagsErrorStatus}
      >
        <S.TagsField
          ref={inputRef}
          startAdornment={
            <S.StyledTagsList
              tags={tagsArr}
              onRemove={removeTag}
              hasError={hasError}
              draggable={draggable}
              onDrag={dragTag}
            />
          }
          value={value || ''}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          placeholder={placeholder}
          required={required}
          data-testid="tagger-input"
          spellCheck
          disabled={disabled}
          {...taggerProps}
        />
      </ThemedTooltip>

      {tagsErrorStatus ? (
        <Typography variant="caption" color="error.main" sx={{ pt: 0.5 }}>
          {tagsErrorMessage}
        </Typography>
      ) : null}
    </InputWrapper>
  )
}

export default Tagger
