import { styled } from '@mui/material/styles'
import { themeBorderRadius, themeColor, themeSpacing } from 'eezy-components'

export const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${themeSpacing(1)} ${themeSpacing(1.5)};
  background: #fff;
  border: 1px solid ${themeColor('secondary', 'pale')};
  border-radius: ${themeBorderRadius('medium')};
  height: ${themeSpacing(6.25)};

  .count {
    color: ${themeColor('secondary', 'light')};
  }

  &:hover {
    cursor: pointer;
    border: 1px solid ${themeColor('secondary', 'veryLight')};

    .count {
      color: ${themeColor('secondary', 'main')};
    }
  }
`
