import { ButtonProps } from '@mui/material/Button'
import React, { forwardRef } from 'react'
import * as S from './styled'

type Props = Pick<ButtonProps, Exclude<keyof ButtonProps, keyof { size }>> & {
  loading?: boolean
  testId?: string
  size?: ButtonProps['size'] | 'tiny'
  rel?: string
  target?: string
}

const Button: React.FC<Props> = forwardRef((props, ref) => {
  const { testId, loading, ...buttonProps } = props

  const {
    children,
    color = 'default',
    disabled = false,
    size = 'medium',
    className = '',
  } = buttonProps

  // need to convert 'tiny' to 'small'
  const sizeOverride = size === 'tiny' ? 'small' : size

  return (
    <S.StyledButton
      {...{ variant: 'contained', ...buttonProps, size: sizeOverride }}
      ref={ref}
      data-testid={testId || 'button'}
      disabled={disabled}
      className={`${className} ${color} ${size} ${
        loading ? 'has-spinner' : ''
      }`}
    >
      {loading && (
        <S.StyledLoadingSpinner
          color="inherit"
          size={size === 'small' ? 15 : 20}
        />
      )}

      {loading ? (
        <S.ButtonTextWrap>{children}</S.ButtonTextWrap>
      ) : (
        <>{children}</>
      )}
    </S.StyledButton>
  )
})

Button.displayName = 'Button'

export default Button
