import { styled } from '@mui/system'
import { Button } from '../index'
import TextInput from '../input/TextInput'
import { themeColor, themeSpacing } from '../../helpers'

export const StyledPagination = styled('nav')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${themeSpacing(2)};
`

export const PaginationList = styled('div')`
  align-items: center;
  display: flex;
`

export const PageInput = styled(TextInput)`
  height: ${themeSpacing(5)};
  margin-left: ${themeSpacing(1)};
  margin-right: ${themeSpacing(1)};
  min-width: ${themeSpacing(5)};
  width: auto;

  & .MuiInputBase-root,
  & .MuiInputBase-input {
    box-sizing: border-box;
    height: ${themeSpacing(5)};
  }

  & .MuiInputBase-input {
    line-height: ${themeSpacing(5)};
    min-width: ${themeSpacing(5)};
    padding: 0 ${themeSpacing(1)};
    text-align: center;
  }
`

export const PaginationButton = styled(Button)`
  && {
    display: flex;
    height: ${themeSpacing(5)};
    min-width: ${themeSpacing(5)};
    width: auto;
  }

  svg {
    fill: ${themeColor('secondary', 'main')};
    height: ${themeSpacing(1)};
    width: ${themeSpacing(1)};
  }
`
