import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  Button,
  themeBorderRadius,
  themeBoxShadow,
  themeColor,
  themeSpacing,
} from 'eezy-components'
import _CheckmarkIcon from 'ez-styles/assets/icons/checkmarks/checkmark-small.svg'
import _CloseIcon from 'ez-styles/assets/icons/x/x-small.svg'
import { css } from 'styled-components'

type Props = {
  type: 'modal' | 'toast'
  message: string
  buttonText: string
  onClick: () => void
  href?: string
  bullets?: string[]
  heroImage: string
}

const PersonaCard = ({
  type,
  message,
  buttonText,
  onClick,
  href,
  bullets,
  heroImage,
}: Props): JSX.Element => (
  <Container type={type} data-testid={`persona-${type}`}>
    <CloseIcon onClick={onClick} />
    <Image type={type} src={heroImage} />

    <Body>
      <Typography variant="h6">{message}</Typography>

      {bullets?.length ? (
        <List>
          {bullets.map((bullet: string, idx: number) => (
            <li key={`bullet-${idx}`}>
              <IconContainer>
                <CheckmarkIcon />
              </IconContainer>

              <Typography variant="h6">{bullet}</Typography>
            </li>
          ))}
        </List>
      ) : null}

      {type === 'modal' ? (
        <ActionButton color="primary" onClick={onClick}>
          {buttonText}
        </ActionButton>
      ) : (
        <ActionButton
          color="primary"
          onClick={onClick}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {buttonText}
        </ActionButton>
      )}
    </Body>
  </Container>
)

export default PersonaCard

const Container = styled('div')<{
  type: Props['type']
}>`
  background-color: #fff;
  z-index: 99;

  ${props =>
    props.type === 'toast' &&
    css`
      position: fixed;
      right: ${themeSpacing(2)(props)};
      bottom: ${themeSpacing(4)(props)};
      width: ${themeSpacing(40)(props)};
      border-radius: ${themeBorderRadius('small')};
      box-shadow: ${themeBoxShadow('popper')(props)};
      overflow: hidden;
    `}
`

const List = styled('ul')`
  align-items: center;
  list-style: none;
  margin: ${themeSpacing(2)} 0 0 0;
  padding: 0;

  & > li {
    display: flex;
    align-items: center;
  }
`

const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${themeSpacing(2)};
  height: ${themeSpacing(2)};
  border-radius: 50%;
  background-color: ${themeColor('success', 'main')};
  margin-right: ${themeSpacing(1)};
`

const CheckmarkIcon = styled(_CheckmarkIcon)`
  width: ${themeSpacing(1)};
  height: ${themeSpacing(1)};
  fill: #fff;
`

const Body = styled('div')`
  padding: ${themeSpacing(3)};
`

const ActionButton = styled(Button)`
  margin-top: ${themeSpacing(3)};
`

export const Image = styled('div')<{
  type?: Props['type']
  src?: Props['heroImage']
}>`
  width: 100%;
  height: ${props => themeSpacing(props.type === 'toast' ? 22 : 30)(props)};
  background-image: url('${props => props.src}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

export const CloseIcon = styled(_CloseIcon)`
  position: absolute;
  top: ${themeSpacing(2)};
  right: ${themeSpacing(2)};
  fill: ${themeColor('secondary', 'light')};
  cursor: pointer;
  width: ${themeSpacing(1.5)};
  height: ${themeSpacing(1.5)};

  &:hover {
    fill: ${themeColor('secondary', 'main')};
  }
`
