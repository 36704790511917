// eslint-disable-next-line
type Instance = any
//eslint-disable-next-line
type Store<T, U extends object = object> = new (container: DIContainer<T>) => U

type StoreMap<T> = {
  [key: string]: T
}

type Params<T> = {
  instances: T
  stores: StoreMap<Store<T>>
}

export default class DIContainer<T> {
  instances: T

  constructor(params: Params<T>) {
    this.instances = params.instances
    this.initStores(params.stores)
  }

  initStores(stores: StoreMap<Store<T>>): void {
    Object.keys(stores).forEach(key => {
      if (!(this.instances as StoreMap<Instance>)[key]) {
        try {
          const Store = stores[key]
          ;(this.instances as StoreMap<Instance>)[key] = new Store(this)
        } catch (err) {
          console.error(err)
          throw new Error(`[DI] Could not initialize Store: ${key} - ${err}`)
        }
      }
    })
  }

  find<T = Instance>(key: string): T {
    const instance = (this.instances as StoreMap<Instance>)[key]

    if (!instance) {
      throw new Error(`[DI] Could not find instance: ${key}`)
    }

    return instance
  }

  replace(key: string, instance: Instance) {
    if ((this.instances as StoreMap<Instance>)[key] !== undefined) {
      // eslint-disable-next-line
      ;(this.instances as StoreMap<Instance>)[key] = instance
    }
  }
}
