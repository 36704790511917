// Values given from marketing for Google Analytics
type DataLayer = {
  free_upload_count?: number
  pro_upload_count?: number
  editorial_upload_count?: number
  total_upload_count?: number
  eps_upload_count?: number
  png_upload_count?: number
  jpg_upload_count?: number
  psd_upload_count?: number
  video_upload_count?: number
  referral_count?: number
  referral_link_clicks?: number
  last_upload_format?: string
  last_upload_guid?: string
  last_submission_license?: string
  contributor_status_start?: string
  contribtuor_status_updated?: string
  email_address?: string
  first_name?: string
  last_name?: string
  country?: string
  snowplow_collector?: string
  snowplow_app_id?: string
  cloudflare_country?: string
  tracking_id?: string
  page_type?: string
  contributor_guid?: string
  environment?: string
  page_path?: string
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
}

// Google Tag Manager Event
export const gevent = (name: string, category: string, data?: object) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: name,
      event_category: category,
      ...data,
    })
  }
}

// General GA Datalayer update
export const updateDatalayer = (data: DataLayer) => {
  if (window.dataLayer) {
    window.dataLayer.push(data)
  }
}
