import { styled } from '@mui/material/styles'
import { Routes } from 'constants/index'
import { useTranslation } from 'react-i18next'
import { Session } from '../../../types'
import MenuItem from '../menu/menuItem'

import PillLabel from 'components/PillLabel'
import DashboardIcon from 'ez-styles/assets/icons/dashboard.svg'
import EarningsIcon from 'ez-styles/assets/icons/earnings.svg'
import InsightsIcon from 'ez-styles/assets/icons/insights.svg'
import PortfolioIcon from 'ez-styles/assets/icons/portfolio.svg'
import ProfileIcon from 'ez-styles/assets/icons/profile.svg'
import ReferralsIcon from 'ez-styles/assets/icons/referrals.svg'
import SupportIcon from 'ez-styles/assets/icons/support.svg'
import DistributionIcon from 'ez-styles/assets/icons/user/person-share-arrow.svg'

type Props = {
  navigate: (route: string) => void
  pathname: string
  session: Session
}

const ContributorLinks = ({ navigate, pathname, session }: Props) => {
  const { t } = useTranslation()
  const hasDistributionSettings =
    session?.hasFeature('content_distribution') && !session.wo
  const hasDistributionBetaLabel =
    session?.hasFeature('content_distribution_beta_label') && !session.wo

  const renderInsightsNav = (): JSX.Element => {
    if (!session) {
      return <></>
    }

    const yourInsights = !session.wo

    return (
      <MenuItem
        label={t('nav.insights.base')}
        icon={<InsightsIcon />}
        testId="insights-menu-item"
      >
        <MenuItem
          dest={Routes.InsightsVecteezy}
          navigate={navigate}
          label={t('nav.insights.vecteezy')}
          pathname={pathname}
          testId="vecteezy-insights-menu-item"
        />

        {yourInsights ? (
          <MenuItem
            dest={Routes.InsightsYour}
            navigate={navigate}
            label={t('nav.insights.your')}
            pathname={pathname}
            testId="your-insights-menu-item"
          />
        ) : (
          <></>
        )}

        <MenuItem
          dest={Routes.InsightsCommunity}
          navigate={navigate}
          label={t('nav.insights.community')}
          pathname={pathname}
          testId="community-insights-menu-item"
        />
      </MenuItem>
    )
  }

  const renderEarningsNav = (): JSX.Element | null => {
    if (
      !session?.enabledFeatures.includes('contrib_earnings') ||
      session.isAdmin ||
      session.wo
    ) {
      return null
    }

    return (
      <MenuItem
        label={t('nav.admin.earnings.title')}
        icon={<EarningsIcon />}
        testId="main-earnings-menu-item"
      >
        <MenuItem
          dest={Routes.Earnings}
          navigate={navigate}
          label={t('nav.admin.earnings.title')}
          pathname={pathname}
          testId="earnings-menu-item"
        />
        <MenuItem
          dest={Routes.Payments}
          navigate={navigate}
          label={t('nav.admin.payments.title')}
          pathname={pathname}
          testId="payments-menu-item"
        />
      </MenuItem>
    )
  }

  return (
    <MenuWrapper data-testid="menu-wrapper">
      <MenuItem
        dest={'/'}
        navigate={navigate}
        label={t('nav.dashboard')}
        pathname={pathname}
        icon={<DashboardIcon />}
        testId="dashboard-menu-item"
      />
      <MenuItem
        label={t('nav.portfolio')}
        icon={<PortfolioIcon />}
        testId="my-portfolio-menu-item"
      >
        <MenuItem
          dest={Routes.PortfolioUpload}
          navigate={navigate}
          label={t('nav.upload')}
          pathname={pathname}
          testId="upload-menu-item"
        />
        <MenuItem
          dest={Routes.PortfolioAddData}
          navigate={navigate}
          label={t('nav.add_data')}
          pathname={pathname}
          count={['started']}
          testId="add-data-menu-item"
        />
        <MenuItem
          dest={Routes.PortfolioPendingReview}
          navigate={navigate}
          label={t('nav.pending_review')}
          pathname={pathname}
          count={['submitted']}
          testId="pending-review-menu-item"
        />
        <MenuItem
          dest={Routes.PortfolioApproved}
          navigate={navigate}
          label={t('nav.approved')}
          pathname={pathname}
          count={['active', 'approved']}
          testId="approved-menu-item"
        />
        <MenuItem
          dest={Routes.PortfolioNotApproved}
          navigate={navigate}
          label={t('nav.not_approved')}
          pathname={pathname}
          count={['rejected', 'needs_corrections', 'inactive']}
          testId="not-approved-menu-item"
        />
      </MenuItem>

      {hasDistributionSettings ? (
        <MenuItem
          label={t('nav.distribution.title')}
          icon={<DistributionIcon />}
          testId="distribution-menu-item"
          customComponent={
            hasDistributionBetaLabel ? (
              <PillLabel
                label={t('util.beta')}
                marginLeft={-0.5}
                marginRight={0.5}
              />
            ) : undefined
          }
        >
          <MenuItem
            dest={Routes.DistributionManager}
            navigate={navigate}
            label={t('nav.distribution.file')}
            pathname={pathname}
            testId="distribution-manager-menu-item"
          />
          <MenuItem
            dest={Routes.DistributionSettings}
            navigate={navigate}
            label={t('nav.distribution.settings')}
            pathname={pathname}
            testId="distribution-settings-menu-item"
          />
        </MenuItem>
      ) : null}

      {renderInsightsNav()}

      <MenuItem
        dest={Routes.Referrals}
        navigate={navigate}
        label={t('nav.referrals')}
        pathname={pathname}
        icon={<ReferralsIcon />}
        testId="referrals-menu-item"
      />

      {renderEarningsNav()}

      <MenuItem
        dest="http://eezycontributors.zendesk.com/hc/en-us"
        navigate={navigate}
        label={t('nav.support.base')}
        pathname={pathname}
        icon={<SupportIcon />}
        testId="support-menu-item"
      />
      <MenuItem
        dest="/profile"
        navigate={navigate}
        label={t('nav.profile')}
        pathname={pathname}
        icon={<ProfileIcon />}
        testId="profile-menu-item"
      />
    </MenuWrapper>
  )
}

export default ContributorLinks

const MenuWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`
