import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/system'
import { themeSpacing } from '../../../helpers'
import { Theme } from '@mui/material'

type Props = {
  children: React.ReactNode
  space?: number
  threshold?: number
  noCenter?: boolean
  spaceBetween?: boolean
}

const Switcher = ({
  space = 1.5,
  threshold = 768,
  noCenter,
  spaceBetween,
  children,
}: Props): JSX.Element => {
  const switcherRef = useRef<HTMLDivElement>(null)
  const [elementWidth, setElementWidth] = useState(null)

  const handleElementResized = () => {
    if (
      switcherRef &&
      switcherRef.current &&
      switcherRef.current.offsetWidth &&
      switcherRef.current.offsetWidth !== elementWidth
    ) {
      setTimeout(() => {
        setElementWidth(switcherRef.current.offsetWidth)
      }, 0)
    }
  }

  const resizeObserver = new ResizeObserver(() => {
    window.requestAnimationFrame(() => {
      handleElementResized()
    })
  })

  useEffect(() => {
    resizeObserver.observe(switcherRef.current)

    return function cleanup() {
      resizeObserver.disconnect()
    }
  })

  return (
    <Wrapper
      space={space}
      shouldSwitch={elementWidth < threshold}
      noCenter={!!noCenter}
      spaceBetween={!!spaceBetween}
      ref={switcherRef}
      data-testid="eezy-layout-switcher"
    >
      {children}
    </Wrapper>
  )
}

export default Switcher

const Wrapper = styled('div')<{
  theme: Theme
  space: number
  shouldSwitch: boolean
  noCenter: boolean
  spaceBetween: boolean
}>`
  display: flex;
  flex-direction: ${({ shouldSwitch }) => (shouldSwitch ? 'column' : 'row')};
  align-items: ${({ noCenter }) => (noCenter ? 'normal' : 'center')};
  justify-content: ${({ spaceBetween }) =>
    spaceBetween ? 'space-between' : 'start'};
  gap: ${({ space }) => themeSpacing(space)};
`
