import React from 'react'
import * as S from './styled'

type Props = {
  checked: boolean
  onChange: (active: boolean) => void
  className?: string
  disabled?: boolean
  label?: string
  labels?: string[]
}

const Toggle = ({
  checked,
  disabled,
  onChange,
  className,
  label,
  labels,
}: Props): JSX.Element => {
  const prefix = labels?.length ? labels[0] : null
  const suffix = labels?.length ? labels[1] : label ? label : null
  const onClick = () => {
    if (!disabled) {
      onChange(!checked)
    }
  }

  return (
    <S.Container className={className} $disabled={disabled}>
      {prefix ? <S.Prefix $active={!checked}>{prefix}</S.Prefix> : null}

      <S.InputControl $active={checked} onClick={onClick} />

      {suffix ? <S.Suffix $active={!!checked}>{suffix}</S.Suffix> : null}
    </S.Container>
  )
}

export default Toggle
