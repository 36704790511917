import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInstance } from 'util/di'
import MapsService, { Prediction } from 'util/maps'

import Suggestions from './Suggestions'

type Props = {
  onSelect: (option: Prediction) => void
  onClear?: () => void
  defaultValue?: string
}

const LocationLookup = ({
  onSelect,
  onClear,
  defaultValue,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { fetchPredictionsDebounced } = useInstance<MapsService>('maps')
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<Prediction[]>([])

  const onInputChange = (input: string) => {
    if (input) {
      setOptions([])
      setLoading(true)

      fetchPredictionsDebounced(
        input,
        (results: Prediction[] | null) => {
          setOptions(results || [])
          setLoading(false)
        },
        () => {
          setLoading(false)
        }
      )
    } else {
      setOptions([])
      setLoading(false)
    }
  }

  const onInputClear = () => {
    setOptions([])
    onClear && onClear()
  }

  return (
    <Suggestions<Prediction>
      loading={loading}
      options={options || []}
      onSelect={onSelect}
      defaultValue={defaultValue}
      onInputClear={onInputClear}
      onInputChange={onInputChange}
      label={t('location')}
      placeholder={t('select_location')}
    />
  )
}

export default LocationLookup
