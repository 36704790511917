import React from 'react'
import { styled } from '@mui/system'

import { themeColor, themeSpacing } from '../../helpers'
import { Theme } from '@mui/material'
import { useViewport } from '../../hooks'

type Props = {
  fullWidth?: boolean
  children?: React.ReactNode | React.ReactNode[]
}

const ModalActions = ({ children, fullWidth = false }: Props): JSX.Element => {
  const { isMobile } = useViewport()

  return (
    <Wrapper
      data-testid="modal-actions"
      $fullWidth={fullWidth}
      $isMobile={isMobile}
    >
      {children}
    </Wrapper>
  )
}

const Wrapper = styled('div')<{
  theme: Theme
  $fullWidth: boolean
  $isMobile: boolean
}>`
  align-items: center;
  display: flex;
  justify-content: ${props =>
    props.$fullWidth || props.$isMobile ? 'space-between' : 'center'};
  gap: ${themeSpacing(2)};
  padding: ${props => (props.$isMobile ? themeSpacing(3) : themeSpacing(5))};
  border: 1px solid
    ${props =>
      props.$isMobile ? themeColor('secondary', 'veryLight') : 'transparent'};

  & > button {
    width: ${props => (props.$fullWidth || props.$isMobile ? '100%' : 'auto')};
  }
`

export default ModalActions
