import React from 'react'
import { css } from 'styled-components'
import { styled } from '@mui/system'
import { themeSpacing } from '../../helpers'
import { useViewport } from '../../hooks'
import { Theme } from '@mui/material'

type Props = {
  className?: string
  children?: React.ReactNode | React.ReactNode[]
}

const ModalBody = ({ className, children, ...rest }: Props): JSX.Element => {
  const { isMobile } = useViewport()

  return (
    <Wrapper
      data-testid="modal-body"
      className={className}
      $isMobile={isMobile}
      {...rest}
    >
      {children}
    </Wrapper>
  )
}

const Wrapper = styled('div')<{
  theme: Theme
  $isMobile: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing(2)};

  ${props =>
    props.$isMobile
      ? css`
          height: 100%;
          padding: ${themeSpacing(3)};
          overflow-y: scroll;
        `
      : css`
          padding: 0 ${themeSpacing(5)};
        `}
`

export default ModalBody
