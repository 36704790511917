import { themeSpacing, themeBorderRadius, themeColor } from '../../../helpers'
import { styled } from '@mui/material/styles'

type StyleProps = {
  $isSelected?: boolean
}

export const Container = styled('div')<StyleProps>`
  padding: ${themeSpacing(3)} ${themeSpacing(2)};
  background-color: ${props =>
    props.$isSelected ? 'fff' : themeColor('secondary', 'pale')};
  border-radius: ${themeBorderRadius('small')};
  border-color: ${props =>
    props.$isSelected ? themeColor('primary', 'main') : 'transparent'};
  border-width: ${themeSpacing(0.25)};
  border-style: solid;

  &:hover {
    cursor: pointer;
    background-color: ${props =>
      props.$isSelected ? 'transparent' : themeColor('secondary', 'veryLight')};
  }
`

export const InputActionWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const ContentContainer = styled('div')`
  width: 100%;
  margin-left: ${themeSpacing(4)};
`

export const MessageContainer = styled('div')`
  margin-top: ${themeSpacing(2)};
`
