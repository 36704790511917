type Event = (typeof Events)[keyof typeof Events]
type Callback = (data: Payload) => void
type Disposer = () => void
type Payload = {
  msg: string
  status?: number
}

export const Events = {
  NetworkError: 'network-error',
  RequestError: 'request-error',
}

export default class Emitter {
  subscriptions: Record<Event, Callback[]>

  constructor() {
    this.subscriptions = {}
  }

  subscribe(event: Event, cb: Callback): Disposer {
    if (!this.subscriptions[event]) {
      this.subscriptions[event] = []
    }

    this.subscriptions[event].push(cb)
    return () => this.unsubscribe(event)
  }

  unsubscribe(event: Event): void {
    delete this.subscriptions[event]
  }

  emit(event: Event, data?: Payload): void {
    if (this.subscriptions[event]) {
      this.subscriptions[event].forEach(cb =>
        cb(data || { msg: 'util.something_went_wrong' })
      )
    }
  }
}
