import { styled } from '@mui/system'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import _ArrowSmallDown from 'ez-styles/assets/icons/arrows/arrow-small-down.svg'
import _Checkmark from 'ez-styles/assets/icons/checkmarks/checkmark-small.svg'
import { themeSpacing, themeColor, themeTypography } from '../../../helpers'
import { Theme } from '@mui/material'

export const StyledMultiSelect = styled(Select)`
  && {
    align-items: center;
  }
`
export const StyledListItemText = styled(ListItemText)<{
  $selected: boolean
  theme: Theme
}>`
  color: ${props =>
    props.$selected ? themeColor('primary') : themeColor('grey')};
`

export const TextInputWrapper = styled('div')`
  margin: ${themeSpacing(2)};
`

export const ArrowSmallDown = styled(_ArrowSmallDown)`
  && {
    height: 5px;
    flex-basis: auto;
    flex-shrink: 0;
    margin-left: auto;
    margin-right: 0;
    right: ${themeSpacing(2)};
    top: auto;
    width: ${themeSpacing(1)};
  }
`

export const Checkmark = styled(_Checkmark)`
  && {
    height: 14px;
    flex-basis: auto;
    flex-shrink: 0;
    margin-left: auto;
    margin-right: 0;
    right: ${themeSpacing(2)};
    top: auto;
    width: ${themeSpacing(1.25)};
  }
`

export const ErrorMessage = styled('div')`
  color: ${themeColor('error')};
  font-size: ${themeTypography('caption', 'fontSize')};
  height: ${themeSpacing(1)};
  margin-top: ${themeSpacing(0.25)};
`
