import { ConfirmDialog, Modal } from 'eezy-components'
import { useTranslation } from 'react-i18next'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  confirmText?: string
  submitResources: () => void
  loading: boolean
}

const DuplicateResourceModal = ({
  isOpen,
  setOpen,
  submitResources,
  loading,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Modal
      modalOpen={isOpen}
      setModalOpen={setOpen}
      data-testid="duplicate-resource-modal"
    >
      <ConfirmDialog
        headerText={t('review.duplicate.modal.title')}
        bodyText={t('review.duplicate.modal.body')}
        confirmButtonAction={submitResources}
        confirmButtonText={t('util.submit')}
        confirmButtonStyle="primary"
        denyButtonAction={() => setOpen(false)}
        confirmLoading={loading}
      />
    </Modal>
  )
}

export default DuplicateResourceModal
