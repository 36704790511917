import { CircularProgress, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { styled } from '@mui/material/styles'
import {
  Button,
  themeBorderRadius,
  themeColor,
  themeSpacing,
  themeTypography,
} from 'eezy-components'

import _SearchIcon from 'ez-styles/assets/icons/magnifier.svg'
import _ClearIcon from 'ez-styles/assets/icons/x/x-big.svg'

export const StyledUserSearch = styled(Autocomplete)<{
  onChange?: $FixMe
  renderOption?: $FixMe
  getOptionLabel?: $FixMe
  isOptionEqualToValue?: $FixMe
}>`
  overflow: hidden;
  min-width: ${themeSpacing(38)};
`

export const SearchField = styled(TextField)`
  & input {
    color: ${themeColor('secondary', 'main')};
    height: ${themeSpacing(4)};
    padding-left: ${themeSpacing(1)};
  }

  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${themeColor('secondary', 'lighter')};
      border-width: 1px;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${themeColor('secondary', 'veryLight')};
  }

  .Mui-disabled {
    background-color: ${themeColor('secondary', 'pale')};
  }
`

export const UserSearchLoading = styled(CircularProgress)`
  position: absolute;
  right: ${themeSpacing(2)};
`

export const Wrapper = styled('div')`
  border: 1px solid ${themeColor('secondary', 'veryLight')};
  border-radius: 6px; // TODO: use themeBorderRadius() when available
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07); // TODO: use themeBoxShadow('popper') when available
  font-size: ${themeTypography('smallText', 'fontSize')};
  font-weight: ${themeTypography('smallText', 'fontWeight')};

  & > ul {
    max-height: 45vh;

    & > li {
      color: ${themeColor('secondary', 'main')};
      padding: ${themeSpacing(2)};
    }
  }
`

export const SearchIcon = styled(_SearchIcon)`
  position: absolute;
  right: ${themeSpacing(2)};
  width: ${themeSpacing(2)};
  height: ${themeSpacing(2)};
  fill: ${themeColor('secondary', 'light')};
`

export const ClearIcon = styled(_ClearIcon)`
  position: absolute;
  right: ${themeSpacing(2)};
  width: ${themeSpacing(2)};
  height: ${themeSpacing(1.5)};
  cursor: pointer;

  fill: ${themeColor('secondary', 'light')};

  &:hover {
    fill: ${themeColor('secondary', 'main')};
  }
`

export const SearchOption = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${themeColor('secondary', 'main')};
  padding: ${themeSpacing(1)} ${themeSpacing(2)};

  &:hover {
    cursor: pointer;
    background-color: ${themeColor('secondary', 'pale')};
  }
`

export const LoginAsButton = styled(Button)`
  color: #fff;
  padding: ${themeSpacing(0.5)} ${themeSpacing(1.5)};
  border-radius: ${themeBorderRadius('small')};
  background-color: ${themeColor('primary', 'main')};
`
