import { styled } from '@mui/material/styles'
import { themeSpacing } from 'eezy-components'

export const Container = styled('div')<{
  animate?: boolean
}>`
  width: ${themeSpacing(21)};
  height: ${themeSpacing(21)};
  margin: ${themeSpacing(2)} auto;

  transform: scale(${props => (props.animate ? 0.3 : 1)});
  transition: transform 400ms linear;

  &.animated {
    transform: scale(1);
  }

  & > svg {
    width: 100%;
    height: 100%;
  }
`

export const Image = styled('div')<{
  src?: string
}>`
  width: 100%;
  height: 100%;
  background-image: url('${props => props.src}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`
