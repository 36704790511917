import React from 'react'
import Dialog from '@mui/material/Dialog'
import { css } from 'styled-components'
import { styled } from '@mui/system'
import {
  themeBorderRadius,
  themeBoxShadow,
  themeColor,
  themeSpacing,
} from '../../helpers/themeHelpers'
import { spacingFunc } from '../../theme'
import { useViewport } from '../../hooks'
import CloseIcon from 'ez-styles/assets/icons/x/x-small.svg'
import { Theme } from '@mui/material'

import ModalBody from './ModalBody'
import ModalHeader from './ModalHeader'
import ModalActions from './ModalActions'

type ModalSize = 'small' | 'medium' | 'large' | 'larger' | 'largest' | 'dialog'

type Props = {
  className?: string
  modalOpen: boolean
  setModalOpen: (boolean) => void
  handleClose?: () => void
  size?: ModalSize
  testId?: string
  hideCloseButton?: boolean
  disableClose?: boolean
  disableEnforceFocus?: boolean
  children?: React.ReactNode | React.ReactNode[]
}

const Modal = ({
  className,
  modalOpen,
  setModalOpen,
  handleClose,
  children,
  size = 'medium',
  testId = 'modal',
  hideCloseButton = false,
  disableClose = false,
  ...rest
}: Props): JSX.Element => {
  const onClose = () => {
    if (disableClose) {
      return
    }
    if (handleClose) {
      handleClose()
    } else {
      setModalOpen(false)
    }
  }
  const { isMobile } = useViewport()
  const isDialog = size === 'dialog'
  const modalWidth: string = isMobile
    ? isDialog
      ? '85%'
      : '100%'
    : getWidthFromSize(size)

  return (
    <StyledModal
      className={className}
      data-testid={testId}
      open={modalOpen}
      onClose={onClose}
      $width={modalWidth}
      $isMobile={isMobile && !isDialog}
      aria-labelledby="modal-header"
      {...rest}
    >
      <>
        {hideCloseButton || disableClose ? null : (
          <CloseButton onClick={onClose} aria-label="Close button">
            <CloseIcon />
          </CloseButton>
        )}

        {children}
      </>
    </StyledModal>
  )
}

const getWidthFromSize = (size: ModalSize): string => {
  switch (size) {
    case 'small':
      return spacingFunc(50)
    case 'large':
      return spacingFunc(75)
    case 'larger':
      return spacingFunc(90)
    case 'largest':
      return spacingFunc(140)
    case 'medium':
    default:
      return spacingFunc(62)
  }
}

const StyledModal = styled(Dialog)<{
  theme: Theme
  $width: string
  $isMobile: boolean
}>`
  & .MuiBackdrop-root {
    background-color: ${themeColor('overlay', 'dark')};

    ${props =>
      !props.$isMobile
        ? css`
            display: flex;
            align-items: center;
            justify-content: center;
          `
        : ''}
  }

  & .MuiPaper-root {
    width: ${props => props.$width};
    max-width: none;
    height: auto;
    margin: 0;
    padding: 0;

    border: 1px solid ${themeColor('secondary', 'pale')};
    border-radius: ${themeBorderRadius('large')};
    box-shadow: ${themeBoxShadow('popper')};
    background-color: #fff;
    &:focus {
      outline: none;
    }

    ${props =>
      props.$isMobile
        ? css`
            display: flex;
            flex-direction: column;
            position: fixed;
            right: 0px;
            bottom: 0px;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            border-radius: 0;
          `
        : ``}
  }
`

const CloseButton = styled('button')`
  align-items: center;
  background: none;
  border: 0;
  display: flex;
  height: ${themeSpacing(3)};
  justify-content: flex-end;
  position: absolute;
  right: ${themeSpacing(2.5)};
  top: ${themeSpacing(2.5)};
  width: ${themeSpacing(8)};
  cursor: pointer;

  svg {
    fill: ${themeColor('secondary', 'light')};
    height: ${themeSpacing(1.5)};
    width: ${themeSpacing(1.5)};
  }

  &:hover {
    svg {
      fill: ${themeColor('secondary', 'main')};
    }
  }
`

Modal.Body = ModalBody
Modal.Header = ModalHeader
Modal.Actions = ModalActions

export default Modal
