import { FinanceBulkOperation, GetApiV1FinanceInvoicesQueryParams } from 'api'
import { BoolType, Routes } from 'constants/index'
import { initFilters } from 'helpers/filters'
import { removeEmpty } from 'helpers/objects'
import { action, makeObservable, observable } from 'mobx'
import { AccountStatus } from 'pages/earnings/accounts/constants'
import qs from 'qs'

export default class EarningsInvoicesStore {
  defaultFilters: GetApiV1FinanceInvoicesQueryParams = {
    user_email: [],
    code: [],
    state: [],
    earning_cents: [],
    tax_withheld_cents: [],
    deposit_cents: [],
    created_at: [],
    approver_email: [],
    approved_at: [],
    completed_at: [],
    payment_method: [],
    payoneer_payee_id: [],
    payment_account_id: [],
    payout_batch_id: [],
    transaction_id: [],
    user_state: [
      AccountStatus.Approved,
      AccountStatus.Rejected,
      AccountStatus.Unapproved,
      AccountStatus.Closed,
    ],
    failure_count: [],
    notes: undefined,
    void: [BoolType.False],
  }

  @observable filters: GetApiV1FinanceInvoicesQueryParams =
    initFilters<GetApiV1FinanceInvoicesQueryParams>(this.defaultFilters)
  @observable bulkOperations: FinanceBulkOperation[] = []
  @observable bulkType: string | undefined = undefined

  constructor() {
    makeObservable(this)
  }

  @action setFilters = (
    filters: GetApiV1FinanceInvoicesQueryParams,
    syncUrl = false
  ): void => {
    this.filters = filters

    if (syncUrl) {
      const url =
        Routes.EarningsInvoices +
        qs.stringify(removeEmpty(this.filters), {
          encode: false,
          arrayFormat: 'comma',
          addQueryPrefix: true,
        })
      history.pushState({}, '', url)
    }
  }

  @action resetFilters = (): void => {
    this.setFilters(this.defaultFilters, true)
  }

  @action addBulkOperation = (operation: FinanceBulkOperation) => {
    this.bulkOperations = [...this.bulkOperations, operation]
  }

  @action removeBulkOperation = (operation: FinanceBulkOperation) => {
    this.bulkOperations = [...this.bulkOperations].filter(
      op => op.id !== operation.id
    )
  }

  @action updateBulkOperation = (operation: FinanceBulkOperation) => {
    const operations = [...this.bulkOperations]

    operations.splice(
      operations.findIndex(op => op.id === operation.id),
      1,
      operation
    )

    this.bulkOperations = operations
  }

  @action setBulkType = (type: string | undefined) => {
    this.bulkType = type
  }
}
