import { LoadingSpinner } from 'eezy-components'
import FullWidthLayoutWrapper from 'layouts/full-width/FullWidthLayoutWrapper'
import { ReactNode, Suspense } from 'react'

type Props = {
  children: ReactNode
}

const SuspensePageLoader = ({ children }: Props) => {
  return (
    <Suspense
      fallback={
        <FullWidthLayoutWrapper>
          <LoadingSpinner />
        </FullWidthLayoutWrapper>
      }
    >
      {children}
    </Suspense>
  )
}

export default SuspensePageLoader
