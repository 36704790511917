import { styled } from '@mui/system'
import { themeColor, themeSpacing } from '../../helpers'
import { Theme } from '@mui/material'
import { Palette, Color } from '../../helpers/themeHelpers'

const getListStyle = (depth: number) => {
  switch (depth) {
    case 0:
      return 'disc'
    case 1:
      return 'lower-alpha'
    case 2:
      return 'circle'
    default:
      return 'disc'
  }
}

export const List = styled('ul')<{
  $depth: number
  theme: Theme
}>`
  list-style: ${props => getListStyle(props.$depth)};
  margin-bottom: ${themeSpacing(3)};
  margin-left: ${themeSpacing(2.25)};
  padding-inline-start: 0;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ListItem = styled('li')<{
  $markerColor: [keyof Palette, keyof Color]
  theme: Theme
}>`
  color: ${themeColor('secondary', 'light')};
  line-height: ${themeSpacing(3)};
  margin-bottom: ${themeSpacing(1)};

  &:last-child {
    margin-bottom: 0;
  }

  &::marker {
    color: ${props =>
      themeColor(props.$markerColor[0], props.$markerColor[1])(props)};
  }
`
