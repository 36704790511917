import { styled } from '@mui/material/styles'
import { themeSpacing } from 'eezy-components'
import { PropsWithChildren, ReactNode } from 'react'

import * as S from './styled'

type Props = PropsWithChildren<{
  className?: string
  children: ReactNode
}>

const Section = ({ className, children }: Props): JSX.Element => (
  <S.Container className={className}>{children}</S.Container>
)

export const Split = styled('div')<{
  gap?: number
}>`
  display: flex;
  gap: ${({ gap }) => (gap ? themeSpacing(gap) : themeSpacing(2))};

  & > * {
    flex: 1;
  }
`

export default Section
