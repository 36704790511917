import { useState, useEffect } from 'react'

export default function useViewport(): { isMobile: boolean } {
  const [isMobile, setIsMobile] = useState(false)
  const [isMobileSize, setIsMobileSize] = useState(window.innerWidth <= 1080)

  useEffect(() => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : window.navigator.userAgent
    const mobileRegex =
      /Mobile|Android|webOS|iPhone|iPad|iPod|huawei|BlackBerry|IEMobile|Opera Mini/i
    setIsMobile(mobileRegex.test(userAgent))
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsMobileSize(window.innerWidth <= 1080)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { isMobile: isMobile || isMobileSize }
}
