import { styled } from '@mui/system'
import {
  themeBorderRadius,
  themeColor,
  themeSpacing,
} from '../../helpers/themeHelpers'

export const CommentContainer = styled('div')`
  display: flex;
  flex-direction: row;
  margin-top: ${themeSpacing(2)};
`

export const CommentBox = styled('div')`
  flex: 1;
  border: 1px solid ${themeColor('grey', '200')};
  border-radius: ${themeBorderRadius('small')};
  min-height: ${themeSpacing(10)};
  margin-left: ${themeSpacing(3)};
  margin-bottom: ${themeSpacing(2)};
  padding: ${themeSpacing(1)};
  position: relative;
  color: ${themeColor('grey', '600')};
  max-width: 80ch;

  ::after {
    content: '';
    position: absolute;
    left: 0;
    top: 16px;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-right-color: ${themeColor('grey', '200')};
    border-left: 0;
    margin-left: -10px;
    z-index: 0;
  }

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 16px;
    border: 9px solid transparent;
    border-right-color: #fff;
    display: block;
    width: 0;
    margin-left: -18px;
    z-index: 1;
  }
`
