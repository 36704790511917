import { Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import { themeColor, themeSpacing, themeTypography } from 'eezy-components'
import { NavLink } from 'react-router-dom'

export const Container = styled(Paper)`
  display: flex;
  position: relative;
  align-content: center;
  overflow-y: scroll;
  width: 100%;
  height: ${themeSpacing(7)};
  padding: 0 ${themeSpacing(3)};
  border-bottom: 1px solid ${themeColor('secondary', 'veryLight')};

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledNavLink = styled(NavLink)`
  &,
  &:link,
  &:visited {
    align-items: center;
    color: ${themeColor('secondary', 'light')};
    display: flex;
    flex-shrink: 0;
    font-size: ${themeTypography('navLink', 'fontSize')};
    font-weight: ${themeTypography('navLink', 'fontWeight')};
    height: 100%;
    margin-left: ${themeSpacing(1)};
    margin-right: ${themeSpacing(1)};
    padding-left: ${themeSpacing(2)};
    padding-right: ${themeSpacing(2)};
    position: relative;
    z-index: 1;
  }

  &::after {
    background-color: ${themeColor('secondary', 'lighter')};
    bottom: -1px;
    content: '';
    display: block;
    height: 3px;
    left: 0;
    position: absolute;
    visibility: hidden;
    width: 100%;
    z-index: 1;
  }

  &:hover,
  &.active {
    color: ${themeColor('secondary', 'main')};
  }

  &:hover::after,
  &.active::after {
    visibility: visible;
  }

  &.active {
    &::after {
      background-color: ${themeColor('primary', 'main')};
    }
  }
`

export const Count = styled('span')`
  font-weight: ${themeTypography('mediumText', 'fontWeight')};
  padding-left: ${themeSpacing(1)};
`
