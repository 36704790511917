import { initFilters } from 'helpers/filters'
import { action, computed, makeObservable, observable, when } from 'mobx'
import qs from 'qs'
import { ItemFilter, ResourceState } from 'types'
import { DIContainer } from 'util/di'
import { GetApiV1ResourcesQueryParams } from '../api'

import AuthStore from './AuthStore'

export default class PendingReviewStore {
  AuthStore: AuthStore
  state: ResourceState[]
  defaultState: ResourceState[] = ['submitted', 'submitting']

  defaultFilter: ItemFilter = {
    file_type: [],
    category_ids: [],
    content_type: 'all',
    program_type: 'all',
    license: 'all',
    sort: 'newest',
  }

  constructor(container: DIContainer<Record<string, unknown>>) {
    makeObservable(this)
    this.AuthStore = container.find('AuthStore')
    this.state = this.defaultState

    when(
      () => Boolean(this.AuthStore.session),
      () => {
        if (this.AuthStore.session?.hasFeature('file_type_filter')) {
          delete this.defaultFilter.content_type
          delete this.filter.content_type
        }
      }
    )
  }

  @observable filter: ItemFilter = initFilters(this.defaultFilter)

  @action setFilter = (filter: ItemFilter, syncUrl = false): void => {
    this.filter = filter

    if (syncUrl) {
      const url = qs.stringify(this.filter, {
        encode: false,
        arrayFormat: 'comma',
        addQueryPrefix: true,
      })
      history.pushState({}, '', url)
    }
  }

  @action filterFromUrl(search: string, { isWO }: { isWO: boolean }): void {
    const filterParams = new URLSearchParams(search)

    if (Object.keys(filterParams).length > 0) {
      const newFilter = Object.keys(this.filter).reduce(
        (filter: ItemFilter, current: string): ItemFilter => {
          if (current !== 'nonce' && isWO && current !== 'program_type')
            filter[current] =
              filterParams.get(current) || this.defaultFilter[current]
          return filter
        },
        {} as ItemFilter
      )

      this.setFilter(newFilter)
    }
  }

  @computed get queryParams(): GetApiV1ResourcesQueryParams {
    const params: GetApiV1ResourcesQueryParams = { state: this.state }

    if (this.filter.file_type?.length) {
      params.file_type = this.filter.file_type
    }

    if (this.filter.category_ids?.length) {
      params.category_ids = this.filter.category_ids
    }

    if (this.filter.content_type !== 'all' && this.filter.content_type) {
      params.content_type = [this.filter.content_type]
    }

    if (this.filter.program_type !== 'all' && this.filter.program_type) {
      params.program_type = this.filter
        .program_type as GetApiV1ResourcesQueryParams['program_type']
    }

    if (this.filter.license !== 'all' && this.filter.license) {
      params.license = this.filter
        .license as GetApiV1ResourcesQueryParams['license']
    }

    if (this.filter.sort === 'newest') {
      params.sort_field = 'created_at'
      params.sort_direction = 'desc'
    }

    if (this.filter.sort === 'oldest') {
      params.sort_field = 'created_at'
      params.sort_direction = 'asc'
    }

    if (this.filter.sort === 'downloads') {
      params.sort_field = 'lifetime_downloads'
      params.sort_direction = 'desc'
    }

    return params
  }
}
