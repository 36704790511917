import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { themeSpacing } from 'eezy-components'

type Props = {
  size?: 'default' | 'full' | 'small'
  sx?: $FixMe
}

export const StyledWrapper = styled(Box)<{
  size?: Props['size']
  $isMobile?: boolean
}>`
  margin: 0 auto;
  padding: ${themeSpacing(4)};
  width: 100%;
  max-width: ${props =>
    props.size === 'full'
      ? 'unset'
      : props.size === 'small'
      ? themeSpacing(136)
      : themeSpacing(200)};
  min-height: calc(100vh - 40px);
`
