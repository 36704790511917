import React, { useMemo, useRef, useState } from 'react'
import { MenuPopper } from '../index'
import _ArrowSmallDown from 'ez-styles/assets/icons/arrows/arrow-small-down.svg'
import { useMinWidth } from '../../hooks'
import * as S from './styled'

type FilterSelectOption = {
  label: string
  value: string
}

type Props = {
  className?: string
  label: string
  selectOptions: FilterSelectOption[]
  value?: string
  emptyLabel?: string
  onChange(value: FilterSelectOption)
  outlinedButton?: boolean
}

const FilterSelect = ({
  value,
  selectOptions,
  label,
  emptyLabel,
  onChange,
  outlinedButton,
  className = '',
}: Props): JSX.Element => {
  const selected = selectOptions.find(opt => opt.value === value)
  const [anchorEl, setAnchorEl] = useState(null)
  const buttonRef = useRef()
  const minWidth = useMinWidth(
    buttonRef,
    selectOptions.map(x => x.label)
  )
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChildClick = (option: FilterSelectOption) => {
    onChange(option)
    handleClose()
  }

  const menuItems = useMemo(() => {
    const items = []
    let idx = 0
    if (emptyLabel) {
      items.push(
        <S.StyledSelectMenuItem
          key={idx}
          onClick={() => handleChildClick(undefined)}
        >
          {emptyLabel}
        </S.StyledSelectMenuItem>
      )
      idx += 1
    }

    for (const opt of selectOptions) {
      items.push(
        <S.StyledSelectMenuItem key={idx} onClick={() => handleChildClick(opt)}>
          {opt.label}
        </S.StyledSelectMenuItem>
      )
      idx += 1
    }

    return items
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions])

  return (
    <>
      <S.StyledButton
        onClick={handleClick}
        testId={'select-button'}
        $minwidth={minWidth}
        $outlined={outlinedButton}
        ref={buttonRef}
        className={open ? `open ${className}` : className}
      >
        <S.StyledLabel>
          {selected ? (
            <>
              <S.StyledSelectKey>{label}:</S.StyledSelectKey>
              <S.StyledValue>{selected.label}</S.StyledValue>
            </>
          ) : (
            <S.StyledValue>{label}</S.StyledValue>
          )}
        </S.StyledLabel>

        <S.ArrowSmallDown />
      </S.StyledButton>

      <MenuPopper
        open={open}
        wrapButton={true}
        handleClose={handleClose}
        anchorEl={anchorEl}
      >
        {menuItems}
      </MenuPopper>
    </>
  )
}

export default FilterSelect
